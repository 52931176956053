// ဗုဒ္ဓံ ဓမ္မံ သံဃံ 

import React, { useContext, useEffect, useState } from "react";
import {
  fetchUser,
  channelLog,
  getToken,
  fetchUserWithHE,
  sendPhone,
  getAuthorizationCode
} from "../../redux/Actions/userAction";

import { connect } from "react-redux";
import { UserPhoneContext } from "../../useContext/useContext";
import { useLocation, useNavigate } from "react-router";
import Loading from "../Loading/Loading";
import ReactGA from "react-ga";
import axios from "axios";
import { BASE_URL_HTTP, BASE_URL } from "../../Common/constant";

const Index = (props) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [HECode, setHECode] = useState(false);
  const { userPhone, setUserPhone } = useContext(UserPhoneContext);
  const { user, fetchUser, channelLog ,fetchUserWithHE,getToken,sendPhone,getAuthorizationCode } = props;

  // google analytic and channl log
  useEffect(() => {
    // localStorage.clear();
    var data = encodeURIComponent(params.get("data")).replace(/%20/g, "%2b"); //phone
    localStorage.setItem("data",params.get("data"));
    const product_code = params.get("product_code"); //product
    if(data !== 'null'){ // if phone no is from data parm
      var HEData = new FormData();
      HEData.append('HE',data);
      localStorage.setItem('HECODE',data);
      axios.post(`${BASE_URL}/decrypte_HECode`,HEData).then(res => {
        // console.log(res);
        if(res.data.data !== 0 && res.data.data[0].status === 'subscribe'){ // if subscribe
          getAuthorizationCode(res.data.encrypte,"dataParam");
          getToken(res.data.encrypte,"dataParam", ()=>{
            fetchUser(res.data.encrypte,"dataParam");
          })
        }else{
          navigate('/startfree')
        }
      });
    }
    else{ // check HECode
      const urlType = encodeURI(params.get("url_type"));

      axios.get(`${BASE_URL_HTTP}/req_header`)
        .then(({ data }) => {
          const heCodeData = data.data;
          if (heCodeData === null || heCodeData === "null") {
            navigate(urlType == 7 ? '/user/phone' : '/startfree');
            return;
          }

          setHECode(heCodeData);
          localStorage.setItem('HECode', heCodeData);

          if (urlType == 7) {
            if (localStorage.getItem('HECode') === null) {
              navigate('/user/phone');
              return;
            }

            var phoneNumber = localStorage.getItem('HECode');
            //need to change phone number to code request
            
            getAuthorizationCode(phoneNumber,'fromAtom');
            axios.get(`${BASE_URL}/check-user/${phoneNumber}/fromAtom`).then( res => {
              setIsLoading(true);
              if(res.data.token){
                localStorage.setItem('token', res.data.token);
                var data = new FormData();
                data.append("phone", localStorage.getItem('HECode'));
                data.append("status", "subscribe");
                data.append("sender", "fromAtom");
                sendPhone(data, () => {
                  fetchUserWithHE(localStorage.getItem('HECode'), 'null', "fromAtom", () => {
                    getToken(localStorage.getItem('HECode'),'fromAtom');
                  });
                });  
              }
            });

            // setIsLoading(true);
            // const data = new FormData();
            // data.append("phone", localStorage.getItem('HECode'));
            // data.append("sender", "fromAtom");
            // data.append("status", "subscribe");

            // sendPhone(data, () => {
            //   getToken(localStorage.getItem('HECode'), () => {
            //     fetchUserWithHE(localStorage.getItem('HECode'), 'null', "fromAtom", () => {
            //       getToken(localStorage.getItem('HECode'));
            //     });
            //   });
            // }); 
          } else {
            getAuthorizationCode(heCodeData,'fromAtom');
            getToken(heCodeData,'fromAtom', () => {
              fetchUserWithHE(heCodeData, 'null', "fromAtom");
            });
          }
        })
        .catch(() => {
          navigate('/startfree');
        });

    }
    // Google Analytic
    // ReactGA.pageview(window.location.pathname + window.location.search);
      window.gtag('event', 'page_view', {
        page_path: window.location.pathname + window.location.search,
      });
    // Google Analytic

    // Channel Log 
    const url_type = encodeURI(params.get("url_type"));
    const url_data = localStorage.getItem('data');
    channelLog(url_type);
    localStorage.setItem("count", 1); // for Ads 
    // Channel Log 
  }, []);
  // google analytic and channl log

  // Loading
  if (isLoading === false) {
    setIsLoading(true);
  }
  // Loading

  //  Redirect function
  useEffect(() => {
    console.log(location.state);
    // console.log(user);

    if (user && user.user === 0 && user.from === "fromUser") {  //if not in DB from User
      // console.log("here");
      navigate("/startfree");
    } else if (user && user.user === 0 && user.from === "fromAtom") {//if not in DB from Atom
      // console.log("here");
      navigate("/startfree", {
        replace: true,
        state: {
          phone: user.phone,
          HECode: HECode,
        },
      });
    } else if (user && user.user !== 0) { //if user is in DB
      localStorage.setItem('phone','95'+user.user[0].phone);
      if (user.user[0].trial_check === "none") {// if trial is never used
        // console.log("here");
        navigate("/startfree");
      } else if (user.user[0].trial_check === "trial") {    // if trial mode active
        console.log(location.state);
        // console.log("here");
        navigate("/home",{
          replace: true,
        });
      } else if (user.user[0].trial_check === "passed") {// if trial mode passes
        if (user.user[0].status === "unsubscribe") { //if  unsubscribed
          // console.log("here");
          navigate("/renewscreen");
        } else {
          if (location.state === "unsubscribe") { //if  unsubscribed
            // console.log("here");
            navigate("/startfree");
            return;
          } else { // subscribe
            // console.log(location.state);
            // console.log("here");
            navigate("/home",{
              replace: true,
            });
          }
        }
      }
    }
  }, [user, navigate]);
  //  Redirect function

  return (
    <>
      <div
        style={{
          marginTop: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.phone.userData,
  };
};

export default connect(mapStateToProps, {
  fetchUser,
  getToken,
  channelLog,
  fetchUserWithHE,
  sendPhone,
  getAuthorizationCode
})(Index);
