import React,{useState,useEffect} from 'react'
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import {Arrow90degLeft, ArrowBarLeft, ArrowLeft, ArrowsAngleContract, Border, Check, NodeMinus, StarFill} from "react-bootstrap-icons";
import Image from "react-bootstrap/Image";
import UserImage from "../../assets/Avatar/user.png";
import BackBtn from "../../assets/slider-arrow/whiteback.png"
import CourseImage from "../../assets/quiz-image/img6.png";

import CodeModal from "../../components/CodeModal/CodeModal";
import "./CourseStyle.css";
import { IMAGE_BASE_URL } from "../../Common/constant";
import { connect } from "react-redux";
import { fetchUserPhoto } from "../../redux/Actions/userAction";
import { storeCode,checkCode } from '../../redux/Actions/postAction';
import swal from "sweetalert";
import { Markup } from 'interweave';
import { myanmarNumToWord } from 'myanmar-num-to-word';



const CourseDetail = (props) => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const myanmarNumToWord = require('myanmar-num-to-word');
    
    const {user, fetchUserPhoto, ClaimData, storeCode, CheckCode, checkCode } = props;

    const [modalShow, setModalShow] = useState(false);
    const [claimCode, setClaimCode] = useState({
        phone: "",
        point: "",
        course_id: "",
    });

    const [codeNumber, setCodeNumber ] = useState('');
    const [clearCode, setClearCode ] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    let ph_no = localStorage.getItem("phone");
    const data = state.course;
    
    const handleChange = React.useCallback((newValue) => {
        setClearCode(true);
        setCodeNumber('');
     }, []);

    useEffect(() => {
        fetchUserPhoto(ph_no, "fromUser");
        checkCode(ph_no, data.id);
    }, [clearCode]);

    useEffect(() => {
        if(Object.keys(CheckCode).length > 0 && CheckCode.status !== false){
            const claim_date = CheckCode.data[0].code_generate_date.substring(0,10);
            let start = new Date(claim_date);
            let day = start.getDate();
            let month = start.getMonth()
            let year = start.getFullYear();
            let start_date = day +`/` + (month+1) + `/` + year;
            let end = day + 3;
            let end_date = end + `/` + (month+1) + `/` + year;
            setStartDate(start_date);
            setEndDate(end_date);
        }
    },[CheckCode]);


    useEffect(() =>{
        Object.keys(ClaimData).length > 0 && setCodeNumber(ClaimData.data.code);
    },[ClaimData]);


    const onClickHandler = () =>{
        claimCode.phone = ph_no;
        claimCode.point = data.point;
        claimCode.course_id = data.id;

        if(data.status == 1){
            if(CheckCode.status == true){
                if(clearCode === false ){
                    setCodeNumber(CheckCode.data[0].code);
                }
                else{
                    setCodeNumber('');
                }
                setModalShow(true);
            }
            else{
                if(CheckCode.point_status == true){
                    swal({
                        title: `အစီအစဥ်ကို ရယူရန် သေချာပါပြီလား?`,
                        icon: 'info',
                        buttons: {
                            confirm: {
                                text: 'Confirm', 
                                value: true, 
                                visible: true, 
                                className: "btn btn-primary confirm-btn", 
                                closeModal: true, 
                            },
                            cancel: {
                                text: 'Cancel', 
                                value: false, 
                                visible: true, 
                                className: "btn btn-secondary cancel-btn mt-1 fw-bold", 
                                closeModal: true, 
                            }, 

                        }
                    }).then((result) => {
                        if (result) {
                            setCodeNumber('');
                            storeCode(claimCode, () => {setModalShow(true);},() => { checkCode(ph_no, data.id); });      
                        }
                    });
                    
                }else{
                    swal({
                        text: `အစီအစဥ်ကို ရယူနိုင်ရန် ရမှတ် မပြည့်မီသေးပါ`,
                        button: "OK",
                    });
                }
            }
        }else{
            swal({
                text: "အစီအစဥ်ကို ရယူရန် သတ်မှတ်ထားသော အရေအတွက် ပြည့်သွားပါပြီ။",
                button: "OK",
            });
        }



    }

    return (
        <>
            <CodeModal show={modalShow} code={codeNumber} changeCode={handleChange} id={data.id}  onHide={() => setModalShow(false)} />   
            <div className='course_container'>
                <div className='course_detail_container'>
                    <div className='card'>
                        <div className='card-header img_container'>
                            <Image
                                src={BackBtn}
                                className="back_btn"
                                onClick={() => {navigate("/course")}}
                            />
                            <div className='sch_container'>
                                <Image
                                    src={IMAGE_BASE_URL+'school_logo/' + data.schools.school_logo}
                                    className="sch_img"
                                    onError={(e) => {
                                        if (e.target.src !== "image_path_here") {
                                            e.target.onerror = null;
                                            e.target.src = CourseImage;
                                        }
                                    }}
                                />
                                <p className='sch_name'>{data.schools.name}</p>
                            </div>
                            <Image
                                src={IMAGE_BASE_URL+'course_detail/' + data.detail_photo}
                                className="course_img"
                                onError={(e) => {
                                    if (e.target.src !== "image_path_here") {
                                        e.target.onerror = null;
                                        e.target.src = CourseImage;
                                    }
                                }}
                            />
                        </div>
                        <div className='card-body des_container'>
                            <p className='course_title'>{data.name}</p>
                            <Row>
                                <Col className='col-5 left_border'>
                                    <p className='label'>Points to Deal</p>
                                    <p className='point'>{data.point} Points</p>
                                </Col>
                                <Col className='col-7'>
                                    <p className='label'>Valid Date</p>
                                    <p className='valid_date'>{CheckCode.status != false ? `${startDate} to ${endDate}` : `-`}</p>
                                </Col>
                            </Row>

                            <hr></hr>
                            <div >
                                <p className='des_title'>Terms & Conditions</p>
                                <ul className='terms' style={data.description? {marginBottom:0}:{marginBottom:100}}>
                                    {/* <li className='mb-3'>အစီအစဥ်အတွက် ပေး‌ပို့ထားသော ကုဒ်နံပါတ် ခြောက်လုံးကို တစ်ကြိမ်သာ အသုံးပြုခွင့် ရရှိမည် ဖြစ်ပြီး တစ်ကြိမ်လျှင် ဝန်ဆောင်မှု တစ်ခုစာ အတွက်သာ ရယူနိုင်ပါသည်။ </li>
                                    <li className='mb-3'>ကုဒ်နံပါတ် ရယူပြီး ရက် သုံးရက် အတွင်း သက်ဆိုင်ရာ ‌အဖွဲ့အစည်းသို့ သွားရောက်ကာ သင်တန်း အပ်နှံ နိုင်ပါသည်။ သတ်မှတ်ရက် ကျော်လွန်သွားပါက အစီအစဥ်သည် အကြုံးမဝင်ပါ။ </li>
                                    <li className='mb-3'>ဝန်ဆောင်မှု တစ်ခုချင်းစီသည် အရင်ဆုံး လာရောက်သည့် ငါးဦးကိုသာ အခွင့်အရေး ပေးသွားပါမည်။ </li>
                                    <li className='mb-3'>လျှော့စျေး အစီအစဥ် ရယူပြီးဖြစ်ကြောင်း သက်ဆိုင်ရာ အဖွဲ့အစည်းက အတည်ပြုပြီးမှသာ လဲလှယ်ရမည့် point များကို နှုတ်ယူသွားပါမည်။</li>
                                    <li className='mb-3'>အစီအစဥ်နှင့် သက်ဆိုင်သော မည်သည့်အရာကိုမျှ လွှဲပြောင်း ပေးအပ်ခြင်း၊ လဲလှယ်ခြင်း၊ တန်ဖိုးငွေ ပြန်အမ်းခြင်း၊ ငွေသား (သို့) အခြားအကျိုးခံစားခွင့်များနှင့် အစားထိုးလဲလှယ်ခြင်းများ မပြုလုပ်ရပါ။ </li>
                                    <li className='mb-3'>ကုဒ်နံပါတ် ပေးပို့လုပ်ဆောင် ပြီးနောက် အသုံးပြုသူကြောင့် ဖြစ်ပေါ်လာသော နောက်ဆက်တွဲ ကိစ္စရပ်များ နှင့် ပတ်သတ်ပြီး lifeX Portal ဝန်ဆောင်မှု (Parabyte Media Limited) မှတာဝန်ယူဖြေရှင်းပေးမည် မဟုတ်ပါ။  </li>
                                    <li className='mb-3'>အစီအစဉ်နှင့် ပတ်သက်၍ အငြင်းပွားမှု တစ်စုံတစ်ရာ ပေါ်ပေါက်လာပါက lifeX Portal ဝန်ဆောင်မှု (Parabyte Media Limited) ၏ ဆုံးဖြတ်ချက်သာလျှင် အတည်ဖြစ်ပါသည်။ </li>
                                    <li className='mb-3'>အစီအစဉ်တွင် ပါဝင်သူများ အားလုံးသည် အစီအစဉ်နှင့် ပတ်သက်သည့် စည်းမျဉ်းစည်းကမ်းများနှင့် lifeX Portal ဝန်ဆောင်မှု (Parabyte Media Limited) ၏ ဆုံးဖြတ်ချက်များကို လက်ခံသဘောတူပြီးဖြစ်ပါသည်။</li> */}
                                    <li className='mb-3'>ကုဒ်နံပါတ်ကို ရယူပြီး ၃ ရက် အတွင်း သက်ဆိုင်ရာ ‌အဖွဲ့အစည်းသို့ သွားရောက်ကာ အသုံးပြုနိုင်ပါသည်။ </li>
                                    <li className='mb-3'> အစီအစဥ် တစ်ခုချင်းစီသည် အရင်ဆုံး လာရောက် အပ်နှံသည့် {myanmarNumToWord.convertToBurmeseNumber(data.student_count)} ဦး ကိုသာ အခွင့်အရေး ပေးသွားမည် ဖြစ်ပါသည်။ </li>
                                    <li className='mb-3'>အစီအစဥ် ရယူပြီးကြောင်း သက်ဆိုင်ရာ အဖွဲ့အစည်းက အတည်ပြုပြီးမှသာ လဲလှယ်ရမည့် point များကို နှုတ်ယူသွားပါမည်။</li>
                                    <div className='d-flex'>
                                        <p>အသေးစိတ် ဖတ်ရှုရန်</p><a className='term mx-1' onClick={() => navigate('/course/detail/T&C')}>Terms & Conditions</a>
                                    </div>
                                </ul>
                            </div>
                            <hr></hr>
                            {data.description ? 
                                (<div style={{marginBottom:100}}>
                                    <p className='des_title'>Description</p>
                                    {/* <p className='descriptions mb-3'>{data.description}</p> */}
                                    <Markup content={data.description}/>
                                </div>):(
                                    <></>
                                )
                            }
                        </div>
                    </div>
                    <span className='claim_container'>
                        <button className='claim_btn' 
                                onClick={() => onClickHandler()} 
                                style={CheckCode.status == true ?{
                                    background: '#aaa',
                                    color: '#fff',
                                    border: 'none'
                                }:{
                                    border: '2px solid #00a8ff',
                                    color: '#00a8ff'
                                }}
                        >
                            Claim
                        </button>
                    </span>
                </div> 
            </div>

        </>
    )
}

const mapStateToProps = (state) => {
    return {
      user: state.phone.userPhoto,
      ClaimData: state.post.claim_code,
      CheckCode: state.post.check_code
    };
  };
  
  export default connect(mapStateToProps, {
    fetchUserPhoto,
    storeCode,
    checkCode
  })(CourseDetail);