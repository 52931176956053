import React, { useContext, useState } from "react";
import YesNoQuiz from "../../components/YesNoQuiz/YesNoQuiz";
import {  useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { QuestContext } from "../../useContext/useContext";
import { useEffect } from "react";
import { set } from "react-ga";

const YesNoQuizScreen = (props) => {
  const value = useContext(QuestContext);
  const yesNoQuest = value.filter(quest => quest.question_type === "2" );
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const [background, setBackground] = useState("yesno-answer-hide");
  const [right, setRight] = useState("yes-icon-hide");
  const [wrong, setWrong] = useState("no-icon-hide");
  const [yesButton, setYesButton] = useState("yes-btn");
  const [noButton, setNoButton] = useState("no-btn");

  const yesHandler = (isCorrect) => {
    setNoButton("no-btn-disabled");
    if (isCorrect === 0) {
      props.setPoint(yesNoQuest[0].answers[0].point);
      setRight("yes-icon-show");
      setBackground("yesno-answer-show");
    } else {
      props.setPoint(0);
      setWrong("no-icon-show");
      setBackground("yesno-answer-show");
    }
  };
  const noHandler = (isCorrect) => {
    setYesButton("yes-btn-disabled");
    if (isCorrect === 1) {
      props.setPoint(yesNoQuest[0].answers[0].point);
      setRight("yes-icon-show");
      setBackground("yesno-answer-show");
    } else {
      props.setPoint(0);
      setWrong("no-icon-show");
      setBackground("yesno-answer-show");
    }
  };
  return (
    <div
      style={{
        height: "80vh",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Helmet bodyAttributes={{ style: "background-color : #00b4d8" }} />
      <YesNoQuiz
        nextQuestion={props.next}
        right={right}
        wrong={wrong}
        background={background}
        yesHandler={yesHandler}
        noHandler={noHandler}
        question={yesNoQuest}
        slideCount={props.slideCount}
        index={props.index}
        yesBtn={yesButton}
        noBtn={noButton}
      />
    </div>
  );
};

export default YesNoQuizScreen;
