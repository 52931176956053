// export const BASE_URL = "http://192.168.100.113:8000/api";
// export const IMAGE_BASE_URL = "http://192.168.100.113:8000/images/";
// export const VIDEO_BASE_URL = "http://192.168.100.113:8000/videos/";

// export const BASE_URL = "http://127.0.0.1:8000/api";
// export const BASE_URL_HTTP = "http://backend.parabyte.media/api";
// export const IMAGE_BASE_URL = "http://127.0.0.1:8000/images/";
// export const VIDEO_BASE_URL = "http://127.0.0.1:8000/videos/";
// export const Audio_BASE_URL = "http://127.0.0.1:8000/audios_data/";

// export const BASE_URL = "https://backend.parabyte.media/api";
// export const BASE_URL_HTTP = "http://backend.parabyte.media/api";
// export const IMAGE_BASE_URL = "https://backend.parabyte.media/images/";
// export const VIDEO_BASE_URL = "https://backend.parabyte.media/videos/";
// export const Audio_BASE_URL = "http://backend.parabyte.media/audios_data/";

// export const BASE_URL = "http://localhost/lifeXBackend/public/api";
// export const BASE_URL_HTTP = "http://localhost/lifeXBackend/public/api";
// export const IMAGE_BASE_URL = "http://localhost/lifeXBackend/public/images/";
// export const VIDEO_BASE_URL = "http://localhost/lifeXBackend/public/videos/";
// export const Audio_BASE_URL = "http://localhost/lifeXBackend/public/audios_data/";

export const BASE_URL = "https://backend.parabyte.media/api";
export const BASE_URL_HTTP = "https://backend.parabyte.media/api";
export const BASE_URL_HTTPs = "https://backend.parabyte.media/api";
export const IMAGE_BASE_URL = "https://backend.parabyte.media/images/";
export const VIDEO_BASE_URL = "https://video.parabyte.media/videos/";
export const Audio_BASE_URL = "https://backend.parabyte.media/audios_data/";

// export const BASE_URL = "https://backenddev.parabyte.media/api";
// export const BASE_URL_HTTP = "http://backenddev.parabyte.media/api";
// export const IMAGE_BASE_URL = "https://backenddev.parabyte.media/images/";
// export const VIDEO_BASE_URL = "https://backenddev.parabyte.media/videos/";  
