import { SEND_PHONE , FETCH_USER, FETCH_USER_PHOTO , SAVE_POINT , FETCH_USER_RENEW , CHANNEL_LOG , AuthorizationCode, } from "../Actions/userAction";

const initialState = {
    phone : null,
    userData : null,
    auth_code : null,
    userPhoto : null,
    points : null,
    channel_log : null,
    userDataRenew : null,
    authorization_code : null
}


const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case SEND_PHONE:
        return { ...state, phone: action.payload };
      case FETCH_USER:
        return {...state, userData: action.payload };
      case FETCH_USER_RENEW:
        return {...state, userDataRenew: action.payload };
      case FETCH_USER_PHOTO:
        return {...state, userPhoto: action.payload };
      case SAVE_POINT:
        return {...state, points: action.payload };
      case CHANNEL_LOG:
        return {...state, channel_log: action.payload };
      case AuthorizationCode:
        return {...state, authorization_code: action.payload };
      default:
        return state;
    }
  };
  
  export default userReducer;
  