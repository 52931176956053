import React, { useContext, useEffect, useState, useRef } from "react";
import Slider from "../../components/Slider/Slider";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import VideoSlider from "../../components/VideoSlider/VideoSlider";
import Loading from "../../components/Loading/Loading";

import { useNavigate } from "react-router-dom";
//Redux Action
import { connect } from "react-redux";
import { fetchDetailPost, increaseCount } from "../../redux/Actions/postAction";
import { useLocation } from 'react-router-dom';
import AudioScreen from "../../components/AudioScreen/AudioScreen";

const BookSlider = (props) => {
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const { id } = useParams();

  var clearTimerRef = useRef();

  const { SliderData } = props;
  const [sildeId] = useState(SliderData.length > 0 && SliderData !== undefined ? SliderData[0].id : null);

  var word = SliderData.length > 0 && JSON.parse(SliderData[0].post_description);

  useEffect(() => {
    props.fetchDetailPost(id);
    clearTimerRef.current = setTimeout(() => {
      props.increaseCount(id);
    }, 60000);

    return () => {
      clearTimeout(clearTimerRef.current);
    };
  },[]);

  const goBack = () => {
    navigate(-1);
    clearTimeout(clearTimerRef.current);
  };

  useEffect(() => {
    if(SliderData !== undefined){
      if (SliderData.length > 0 && SliderData !== undefined && sildeId !== SliderData[0].id) {
      //  setPosts([...posts, ...data]);
       setIsLoading(false);
     } else {
       setIsLoading(true);
     }}
   }, [SliderData]);

  return (
    <>
      <div style={{overflow:'hidden'}}>
        {isLoading ? (
          <div
            style={{
              marginTop: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading color="#00A8FF" />
          </div>
        ) : (
          <div>
            <Helmet
              bodyAttributes={
                SliderData.length > 0 && SliderData[0].post_type === "book"
                  ? { style: "background-color : #5cafe1" }
                  : { style: "background-color : #fff" }
              }
            />
            {SliderData.length > 0 ? (
              SliderData[0].post_type === "video" ? (
                <VideoSlider SliderData={SliderData} goBack={goBack} word={word} />
              ) : SliderData[0].post_type === "audio" 
              ?
                <AudioScreen SliderData={SliderData} goBack={goBack} word={word} />
              : (
                <Slider book={SliderData} word={word} goBack={goBack} />
              )
            ) : null}
          </div>
        )}

      </div>      
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    SliderData: state.post.post_detail,
  };
};
export default connect(mapStateToProps, {
  fetchDetailPost,
  increaseCount
})(BookSlider);
