import React, { useState } from "react";
import "./SavedBookStyle.css";
import { Row, Col, Container, Image } from "react-bootstrap";
import { BookmarkFill, BookHalf, PlayBtnFill, MicFill } from "react-bootstrap-icons";
import { increaseCount } from "../../redux/Actions/postAction";
import { connect } from "react-redux";
import moment from "moment/moment";
import UserImage from "../../assets/book-images/book6.png";
import { useNavigate } from "react-router";
import swal from "sweetalert";

const SaveBook = (props) => {
  const navigate = useNavigate();
  const { user } = props;

  const handleRedirectByUser = () => {
    if (user !== null && user.user[0].lifecycle === "G") {
      swal({
        text: "၀န်ဆောင်မှုကို အပြည့်အ၀ သုံးစွဲရန် လက်ကျန်ငွေ မလုံလောက်ပါသဖြင့် ပြန်လည်ဖြည့်တင်းပြီးမှသာ အသေးစိတ် ၀င်ရောက်ဖတ်ရှုနိုင်ပါမည်။",

        button: "OK",
      });
    } else {
      navigate("/home/book/" + props.bookID);
      localStorage.setItem('post_id',props.bookID);
    }
  };

  return (
    <>
      <div style={{ backgroundColor: props.secondaryColor }}>
        <div
          className="saved-section-container p-2"
          style={{ backgroundColor: props.primaryColor }}
        >
          <a
            onClick={handleRedirectByUser}
            style={{ textDecoration: "none", color: "black" }}
          >
            <Row className="p-0 m-0">
              <Col className="col-12 bottom-container">
                <p className="date p-0 m-0">
                  {moment(props.date).startOf("second").fromNow()}
                </p>
                {props.postType === "book" ? (
                  <BookHalf className="post_icon text-white" size={12} />
                ) : (props.postType == "video" ? (
                  <PlayBtnFill className="post_icon text-white" size={12} />
                ) : (
                  <MicFill className="post_icon text-white" size={12} />
                ))}
              </Col>
            </Row>
            <Row className="p-0 m-0">
              <Col className="col-8">
                <h5 className="book-title p-0 m-0"> {props.title}</h5>
              </Col>
              <Col className="col-4  d-flex justify-content-center align-items-center">
                <Image
                  src={props.img}
                  className="book-img"
                  onError={(e) => {
                    if (e.target.src !== "image_path_here") {
                      e.target.onerror = null;
                      e.target.src = UserImage;
                    }
                  }}
                />
              </Col>
            </Row>
          </a>
          <Row>
            <Col className="col-12 bottom-container">
              <BookmarkFill className="saved_icon_2" size={23} />
              <p className="sub-title">{props.subTitle}</p>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    BookData: state.post.posts,
    user: state.phone.userData,
  };
};

export default connect(mapStateToProps, {
  increaseCount,
})(SaveBook);
