import React , {createContext , useState} from "react";
import "./sliderStyle.css";
import Carousel from "react-bootstrap/Carousel";
import MultipleChoiceScreen from "../../views/MultipleChoiceScreen/MultipleChoiceScreen";
import YesNoQuizScreen from "../../views/YesNoQuizScreen/YesNoQuizScreen";
import ImageQuizScreen from "../../views/ImageQuizScreen/ImageQuizScreen";
import { QuestContext } from "../../useContext/useContext";
import { useRef } from "react";
import { useNavigate } from "react-router";

const QuestionSlider = (props) => {
  const [multipoint , setMultiPoint] = useState(0);
  const [yesNoPoint , setYesNoPoint] = useState(0);
  const [imagePoint , setImagePoint] = useState(0);
  const navigate = useNavigate();
  const [questionClick , setQuestionClick] = useState(1);
  const questions = props.questionArray;
  const ref = useRef(null);
  const onNextClick = () => {
    setQuestionClick(prev => prev + 1);
    if(questionClick === questions.length){
      navigate('/live/book/points',{ replace: true, state: {
        multipoint : multipoint,
        yesNoPoint : yesNoPoint,
        imagePoint : imagePoint,
        postData : props.post_detail,
      }});
    }
    ref.current.next();
  };
  return (
    <>
      <div className="slider-main-container">
        <QuestContext.Provider value={questions}>
          <Carousel interval={null} data-wrap={null} controls={false} wrap={null} ref={ref} touch={null}>
            {questions.length > 0 && questions.map((q , i) => (
              <Carousel.Item key={i}>
                {q.question_type === "1" ? 
                <MultipleChoiceScreen point={multipoint} setPoint={setMultiPoint} next={onNextClick} slideCount={questions.length} index={i}/> 
                : q.question_type === "2" ? <YesNoQuizScreen next={onNextClick} point={yesNoPoint} setPoint={setYesNoPoint} slideCount={questions.length} index={i}/> 
                :  <ImageQuizScreen next={onNextClick} point={imagePoint} setPoint={setImagePoint} slideCount={questions.length} index={i}/>}
              </Carousel.Item>
            ))}
          </Carousel>
        </QuestContext.Provider>
      </div>
    </>
  );
};

export default QuestionSlider;
