import React, { useContext, useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useNavigate } from "react-router";
import StartFreeTrial from "../../components/StartFreeTrial/StartFreeTrial";
import landingpagebg from "../../assets/StartFreeTrial/Landing.jpg";
import { UserPhoneContext } from "../../useContext/useContext";
import swal from "sweetalert";
import { connect } from "react-redux";
import { sendPhone, fetchUserRenew } from "../../redux/Actions/userAction";
import { toast } from "react-toastify";
import Loading from "../../components/Loading/Loading";
import ReactGA from 'react-ga';

const RenewScreen = (props) => {
  // useEffect(()=>{
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // })
  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_path: window.location.pathname + window.location.search,
    });
  }, [window.location.pathname, window.location.search]);
  
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [navigateStatus, setNavigateStatus] = useState(false);
  const { userPhone } = useContext(UserPhoneContext);
  const { phone, fetchUserRenew, userData } = props;
  const handleSubscribe = () => {
    swal({
      text: "တပတ်လျှင် ၄၉၉ကျပ် (အခွန်ပါပြီး) ကောက်ခံသွားပါမည်။",

      button: "သဘောတူပါသည်",
    }).then((value) => {
      // return console.log(value);
      if (value === true) {
        setIsLoading(true);
        setNavigateStatus(true);
        var data = new FormData();
        data.append("phone", localStorage.getItem('phone'));
        data.append("status", "subscribe");
        data.append("HECode", localStorage.getItem('HECode'));
        props.sendPhone(data, () => {
          fetchUserRenew(localStorage.getItem('phone'),"fromUser");
        });
      }
    });
  };
  useEffect(() => {
    if (userData && userData.user !== 0 && userData.user[0].status === "subscribe") {
      toast.success("‌အောင်မြင်စွာ စာရင်းသွင်းပြီးပါပြီ", {
        position: toast.POSITION.TOP_CENTER,
        draggablePercent: 60,
      });
      navigate("/startfree/about_lifex");
    } else if (
      userData &&
      userData.user !== 0 &&
      userData.user[0].status === "unsubscribe"
    ) {
      if (navigateStatus === false) {
        navigate("/renewscreen");
      } else {
        setIsLoading(false);
        toast.error("အင်တာနက် စစ်ဆေးပီး ပြန်လည်စာရင်းသွင်းပါ", {
          position: toast.POSITION.TOP_CENTER,
          draggablePercent: 60,
          onClose: () => navigate("/user/phone")
        });
       
      }
    }
  }, [ userData, navigate, navigateStatus]);

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            marginTop: "80px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading color="#00A8FF" />
        </div>
      ) : (
        <div
          className="page-main-container"
          style={{
            padding: 0,
            display: "flex",
            position: "relative",
            height: "100vh",
            width: "100vw",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundSize: "cover",
            backgroundImage: `url(${landingpagebg})`,
          }}
        >
          {/* all the css can find in components/StartFreeTrial/StartFreeStyle.css */}
          <div className="page-container">
            <div className="landing-carousel-container">
              <Carousel controls={false} className="p-0 m-0">
                {/* <Carousel.Item>
                  <img
                    className="d-block w-100 website-width"
                    src={require("../../assets/landing/0 luckydraw A.png")}
                    alt="First slide"
                  />
                </Carousel.Item> */}
                <Carousel.Item>
                  <img
                    className="d-block w-100 website-width"
                    src={require("../../assets/landing/Gold-Campaign-Landing.png")}
                    alt="second slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100 website-width"
                    src={require("../../assets/landing/1 landing B.png")}
                    alt="third slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100 website-width"
                    src={require("../../assets/about-lifex/1a.png")}
                    alt="third slide"
                  />
                </Carousel.Item>
              </Carousel>
              <button className="free-trial-btn" onClick={handleSubscribe}>
                ပြန်လည်သုံးစွဲဖို့ စာရင်းသွင်းရန်
              </button>
              <span className="text-danger my-1" style={{fontSize:'13px'}}>စမ်းသပ်သုံးစွဲမှု ကာလကုန်ဆုံးပါပြီ</span>

              <StartFreeTrial />
              <ul className="landing-content-ul">

                <li>
                  ဆက်လက် အသုံးပြုလိုပါက တစ်ပတ်စာ အသုံးပြုခ ၄၉၉ ကျပ်ကို ပေးဆောင်ကာ
                  အသုံးပြုနိုင်ပါမည်ဖြစ်ပါသည်။
                </li>
                <li>
                  သက်ဆိုင်ရာ စည်းမျဥ်း သတ်မှတ်ချက်များကို အောက်တွင် ပြည့်စုံစွာ
                  ဖော်ပြထားပါသည်။
                </li>
                <li>
                  ဆက်လက်အသုံးပြုသည်နှင့် တစ်ပြိုင်နက် ဖော်ပြပါ စည်းမျဥ်း
                  သတ်မှတ်ချက်များကို သ‌ဘောတူပြီး ဖြစ်ပါသည်။
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    phone: state.phone.phone,
    userData: state.phone.userDataRenew,
  };
};

export default connect(mapStateToProps, {
  sendPhone,
  fetchUserRenew,
})(RenewScreen);
