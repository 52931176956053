import React,{useEffect,useState} from "react";
import Modal from "react-bootstrap/Modal";
import "./UserListModalStyle.css";
import ScrollLoading from "../../components/Loading/ScrollLoading";
import UserImage from "../../assets/Avatar/person.png";
import CourseImage from "../../assets/quiz-image/img6.png";
import { IMAGE_BASE_URL } from "../../Common/constant";
import Loading from "../../components/Loading/Loading";

const CodeListModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  let ph = localStorage.getItem("phone");
  const phone = ph.substring(2);
  

  return (
    <div>
      <Modal
        show={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-bcenter"
        className="user-list-modal"
      >
        <Modal.Header className="modal-header-1">
          <button onClick={props.onHide} className="modal-close-btn"></button>
          <h4 style={{fontSize: 20, marginTop: 30}}>Saved Claim Code</h4>
        </Modal.Header>
        <Modal.Body className="modal-body-1">
            <div>
            {isLoading ? (
                <div
                    style={{
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    }}
                >
                    <Loading color="#E6D153" />
                </div>
            ) : (                
                props.codes && props.codes != undefined && props.codes.map((code,index) => (
                    <div key={index}>
                        <div
                            className="p-2 d-flex"
                            style={{ width: '100%' }}
                        >
                          <span className="d-flex flex-column">
                            <img 
                              src={IMAGE_BASE_URL+'school_logo/' + code.courses[0].schools.school_logo}
                              alt="user" 
                              style={{width: 80, height: 80}}
                            />
                            <p className="mt-1 text-success">{code.status == 1 ? 'Approved' : ''}</p>
                          </span>

                            <div className="d-flex flex-column mx-3 text-start">
                                <h6>{code.courses[0].name}</h6>
                                <h6>Course Point- {code.point} points</h6>
                                <h6>Code - {code.code}</h6>
                            </div>

                        </div>
                        <hr></hr>
                    </div>


                ))
            )}
            {/* {isFetching &&  <ScrollLoading style={{color : 'black'}} />} */}
            </div>

        </Modal.Body>
        <Modal.Footer className="text-center m-0 p-0" style={{background: '#fff'}}>
        <hr></hr>
            <div
              className="p-3 d-flex"
              style={
                  { backgroundColor: "rgba(0, 168, 255,0.5)", width: '100%' }                      
              }
            >
                <img 
                      src={props.user && `${IMAGE_BASE_URL}${props.user.user[0].photo}`}
                      alt="user" 
                      style={{width: 50, height: 50, borderRadius: '50%'}}
                      onError={(e) => {
                        if (e.target.src !== "image_path_here") {
                          e.target.onerror = null;
                          e.target.src = UserImage;
                        }
                      }}/>
                <div className="d-flex flex-column mx-3 text-start">
                    <h6>Current Points - {props.user && props.user.user[0].point} points</h6>
                    <h6>Points After Claim - {props.user && props.user.user[0].temp_point} points</h6>
                </div>
            </div>
            <p style={{fontSize: '14px', color: '#555'}} className="mx-2">The Points will be reduced after admin approved and the course is registered</p>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CodeListModal;

// const mapStateToProps = (state) => {
//   return {
//     Codes: state.post.codes,
//   };
// };

// export default connect(mapStateToProps, {
//     getSavedCodes
// })(CodeListModal);

