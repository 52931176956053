import React from "react";
import UserPoint from "../../components/UserPoint/UserPoint";

const UserPointScreen = () => {
  return (
    <div
      style={{
        marginTop: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <UserPoint />
    </div>
  );
};

export default UserPointScreen;
