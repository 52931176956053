import Modal from "react-bootstrap/Modal";
import React from "react";
import { BookmarkFill } from "react-bootstrap-icons";
import "./SavedModalStyle.css";

const SavedModal = (props) => {
  return (
    <div className="save_modal_container">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="save-modal"
      >
        <Modal.Header
          className="save-modal-header"
          style={
            props.text == "Unsaved"
              ? { backgroundColor: "#1a3d50" }
              : { backgroundColor: "#00a8ff" }
          }
        >
          <Modal.Title className="save-modal-title">
            <BookmarkFill size={23} />
            <span className="save-title">{props.text}</span>
          </Modal.Title>
        </Modal.Header>
      </Modal>
    </div>
  );
};

export default SavedModal;
