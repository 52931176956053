import React, {useState, useRef} from "react";
import './AudioStyle.css';
import { Markup } from "interweave";
import ControlPanel from "./AudioControl";
import AudioProgressBar from "./AudioProgessBar";
import { ChevronLeft } from "react-bootstrap-icons";
import 'animate.css';
import { Audio_BASE_URL, IMAGE_BASE_URL } from "../../Common/constant";

const AudioScreen = (props) => {
    const [percentage, setPercentage] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)
    const [duration, setDuration] = useState(0)
    const [currentTime, setCurrentTime] = useState(0)
    const audioRef = useRef()
    const {SliderData , goBack , word } = props;
    // const scrollContainerRef = useRef(null);
    // const [isPaused, setIsPaused] = useState(false);

    const onChange = (e) => {
      const audio = audioRef.current
      audio.currentTime = (audio.duration / 100) * e.target.value
      setPercentage(e.target.value)
    }

    const play = () => {
      const audio = audioRef.current
      audio.volume = 1

      if (!isPlaying) {
        setIsPlaying(true)
        audio.play()
      }

      if (isPlaying) {
        setIsPlaying(false)
        audio.pause()
      }
    }

    const getCurrDuration = (e) => {
      const percent = ((e.currentTarget.currentTime / e.currentTarget.duration) * 100).toFixed(2)
      const time = e.currentTarget.currentTime
      setPercentage(+percent)
      setCurrentTime(time.toFixed(2))
    }

    const skip = (time) => {
      const audio = audioRef.current;
      if (time == 'back') {
        // changes
        audio.currentTime = audio.currentTime - 5;
      } else if (time == 'fwd') {
        // changes
        audio.currentTime = audio.currentTime + 5;
      }
    };

    // function handleScroll() {
    //   const scrollContainer = scrollContainerRef.current;
    //   if (scrollContainer.scrollTop !== 0) {
    //     scrollContainer.querySelector('.audio-description').style.animationPlayState = 'paused';
    //     setIsPaused(true);
    //   }
    // }
  
    // function handleMouseEnter() {
    //   scrollContainerRef.current.querySelector('.audio-description').style.animationPlayState = 'paused';
    //   setIsPaused(true);
    // }
  
    // function handleMouseLeave() {
    //   scrollContainerRef.current.querySelector('.audio-description').style.animationPlayState = 'running';
    //   setIsPaused(false);
    // }

    return (
        <>
            <div className="video-main-container" >
                <div className="back-container">
                  <ChevronLeft className="x-icon" onClick={goBack} />
                </div>
                <div className="audioContainer">
                <div className="audio_card shadow-lg">
                    <div className="audio_card_header">
                      <div className="audio-spectrum">
                          <div className="audio-thumbnail">
                            <center>
                              <img src={`${IMAGE_BASE_URL}${SliderData[0].book_cover}`} alt="cover" />
                            </center>
                          </div>
                          <div className="audio-header">
                              <div className="audio-title mt-5">
                                <h4>{SliderData[0].post_name}</h4>
                                <h5>{SliderData[0].subcategory.sub_category_name}</h5>
                              </div>
                          </div>
                          {/* <div className="audio-body" ref={scrollContainerRef} onScroll={handleScroll} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>*/}
                          <div className="audio-body">
                            <div className="audio-description">
                              {
                                word.map(w => {
                                  return (
                                    <p><Markup content={w} /></p>  
                                  )
                                })
                              }
                            </div>
                          </div>
                      </div> 
                    </div>
                      <div className="audio-control">
                          <AudioProgressBar percentage={percentage} onChange={onChange} />
                          <audio
                              preload="auto"
                              ref={audioRef}
                              onTimeUpdate={getCurrDuration}
                              onLoadedData={(e) => {
                              setDuration(e.currentTarget.duration.toFixed(2))
                              }}
                              src={`${Audio_BASE_URL}${SliderData[0].video_name}`}
                          ></audio>
                          <ControlPanel
                              skip={skip}
                              play={play}
                              isPlaying={isPlaying}
                              duration={duration}
                              currentTime={currentTime}
                          />
                      </div>
                </div>
                </div>
            </div>
        </>
  );
};

export default AudioScreen;
