import React from "react";
// import axios from "axios";
// import { BASE_URL } from "../../Common/constant";
import { Col, Row } from "react-bootstrap";
import { useState, useEffect, useContext } from "react";
import {
  Server,
  Bookmark,
  Sliders2Vertical,
  People,
  ArrowLeft,
  TicketDetailedFill,
  PersonBadge,
} from "react-bootstrap-icons";
import UserImage from "../../assets/Avatar/person.png";
import "./ProfileStyle.css";
import { fetchUser, getAuthorizationCode } from "../../redux/Actions/userAction";
import UserListModal from "../../components/UserListModal/UserListModal";
import { IMAGE_BASE_URL } from "../../Common/constant";
import { UserContext } from "../../useContext/useContext";
import { useNavigate } from "react-router";
import { fetchUserWithHE } from "../../redux/Actions/userAction";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import CodeListModal from "../../components/UserListModal/CodeListModal";
import TermsModal from "../../components/TermsModal/TermsModal";
import { getSavedCodes } from "../../redux/Actions/postAction";

const Profile = (props) => {
  const navigate = useNavigate();

  const { setUserPhoto, userPhoto } = useContext(UserContext);
  let data = localStorage.getItem("phone");
  const phone = data.substring(2);
  let HECode = localStorage.getItem("HECode");
  const auth_code = localStorage.getItem('authorization_code');
  const { user, fetchUser,fetchUserWithHE, Codes , getSavedCodes } = props;
  useEffect(() => {
    getSavedCodes(phone);
    if (HECode !== null && HECode !== "null") {
      fetchUserWithHE(HECode,'null', "fromAtom");
    } else {
    fetchUser(data, "fromUser",auth_code);
    }
  }, []);

  useEffect(() => {
    setUserPhoto(user && user.user[0].photo);
  }, [user]);
  const ClickHandler = () => {
    navigate("/profile/edit_profile", { state: user });
  };

  const [modalShow, setModalShow] = useState(false);
  const [codeModalShow, setCodeModalShow] = useState(false);
  const [termsModalShow, setTermsModalShow] = useState(false);


  return (
    <div className="profile-main-container" style={{ margin: "0px", padding: "0", zIndex: "-1" }}>
      <div className="profile-img-container">
        <Link to="/home">
          <ArrowLeft className="left-arrow" />
        </Link>

        <img
          // src={`${IMAGE_BASE_URL}${user && user.user[0].photo}`}
          src={UserImage}
          className="user-profile-img"
          alt="profile"
          onError={(e) => {
            if (e.target.src !== "image_path_here") {
              e.target.onerror = null;
              e.target.src = UserImage;
            }
          }}
        />
      </div>
      <div className="user-information-container">
        <UserListModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          userId={user && user.user[0].id}
          count={0}
        />
        <CodeListModal
          show={codeModalShow}
          onHide={() => setCodeModalShow(false)}
          user = {user && user}
          codes = {Codes && Codes.data}
        />
        <TermsModal
          show={termsModalShow}
          onHide={() => setTermsModalShow(false)}
        />
        <Row className="mt-2">
          <Col className="col-7">
            {/* <h4 className="user-name">{user && user.user[0].name}</h4> */}
            <p className="user-phone">+{user && user.user[0].phone}</p>
          </Col>
          {/*<Col className="col-5">
            <a onClick={ClickHandler}>
              <button className="edit-btn">Edit Profile</button>
            </a>
        </Col>*/}
        </Row>
        <Row className="plan-container mb-2">
          <Col className="col-7">
            <h6 className="user-plan-title mt-2">Plans and Payment</h6>
          </Col>
          <Col className="col-5">
            <button
              className="plan-btn"
              onClick={() =>
                navigate("/profile/manage_plan", {
                  state: user ?  user.user[0].start_date : "",
                })
              }
            >
              Manage Plan
            </button>
          </Col>
        </Row>

        <div className="details-container">
          <div className="left-container">
            <Server color="#fff" size={20} style={{ marginRight: "0px" }} />

            {/* <TicketDetailedFill color="#fff" size={20} style={{ marginRight: "0px" }} /> */}

            <div>
              <p
                style={{
                  fontSize: "14px",
                  color: "#fff",
                  padding: "0",
                  margin: "0",
                }}
                // onClick={() => setModalShow(true)}
                onClick={() => setTermsModalShow(true)}
              >
                <b className="mx-2">{user && user.user[0].ticket}</b>Ticket
                {/* <b className="mx-2">0</b>Ticket */}

                {/* <b className="mx-1">{user && user.user[0].point} </b>Points */}
              </p>
            </div>
          </div>
          <div className="right-container">
              <Server color="#fff" size={20} style={{ marginRight: "0px" }} />
              <p
                style={{
                  fontSize: "14px",
                  color: "#fff",
                  padding: "0",
                  margin: "0",
                }}
                // onClick={() => navigate('/course')}
                onClick={() => setModalShow(true)}
              >
                {/* <b className="mx-1">Course Deal Program</b> */}
                <b className="mx-2">{user && user.user[0].point} </b>Points
              </p>
          </div>
        </div>

        {/* bottom container */}

        <div className="profile-settins-tab-container">
          <Row className=" m-auto">
            <Col className="col-4" style={{ textAlign: "center" }}>
              <a
                // onClick={() => setCodeModalShow(true)}
                onClick={() => navigate('/saved_booklist')}
              >
                {/* <PersonBadge size={23} color="#9599B3" /> */}
                {/* <p className="icon-title">Saved Code</p> */}
                <Bookmark size={23} color="#9599B3"/>
                <p className="icon-title">Saved</p>
              </a>
            </Col>
            {/* <Col className="col-4" style={{ textAlign: "center" }}>
              <a href="/profile/notification">
                <Bell size={23} color="#9599B3" />
              </a>
              <p className="icon-title">Notifications</p>
            </Col> */}
            <Col className="col-4" style={{ textAlign: "center" }}>
              <a
                onClick={() => {
                  navigate("/profile/settings");
                }}
              >
                <Sliders2Vertical size={23} color="#9599B3" />
              </a>
              <p className="icon-title">Settings</p>
            </Col>
            <Col className="col-4" style={{ textAlign: "center" }}>
              <a
                onClick={() => {
                  navigate("/profile/help");
                }}
              >
                <People size={23} color="#9599B3" />
              </a>
              <p className="icon-title">Help</p>
            </Col>
          </Row>
          {/* <a onClick={() => setCodeModalShow(true)}>.</a> */}

        </div>

        {/* <a onClick={() => { navigate('/course')}} className="point_deal_btn">
            <p className="text-white fw-bold">Point Reward Program</p>
        </a> */}

      </div>


    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.phone.userData,
    Codes: state.post.codes,

  };
};

export default connect(mapStateToProps, {
  fetchUser,
  getSavedCodes,
  fetchUserWithHE
})(Profile);
