import React, { useContext, useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useLocation, useNavigate } from "react-router";
import StartFreeTrial from "../../components/StartFreeTrial/StartFreeTrial";
import { UserContext } from "../../useContext/useContext";
import landingpagebg from "../../assets/StartFreeTrial/Landing.jpg";
import { UserPhoneContext } from "../../useContext/useContext";
import swal from "sweetalert";
import { connect } from "react-redux";
import { sendPhone, fetchUser, getToken, getAuthorizationCode, fetchUserWithHE } from "../../redux/Actions/userAction";
import Loading from "../../components/Loading/Loading";
import { BASE_URL } from "../../Common/constant";
import '../../App.css';
import axios from "axios";
import { BASE_URL_HTTP } from "../../Common/constant";
import '../../components/StartFreeTrial/StartFreeStyle.css'
import 'animate.css';
// import { FacebookShareButton,FacebookIcon } from "react-share";
// import FacebookIcon from "react-share";

const StartFreeTrialScreen = (props) => {
  const { state } = useLocation();
  const [ isLoading , setIsLoading ] = useState(false);
  const navigate = useNavigate();
  const {userData,fetchUser,getToken, getAuthorizationCode, authorization_code ,fetchUserWithHE } = props;
  // After clicked subscribe 
  const handleSubscribe = () => {
      swal({
        text: "၇ ရက်အခမဲ့ကာလပြီးဆုံးပါက တပတ်လျှင် ၄၉၉ကျပ် (အခွန်ပါပြီး) ကောက်ခံသွားပါမည်။",

        button: "သဘောတူပါသည်",
      }).then((value) => {
        localStorage.setItem('option', 'new');
        if(localStorage.getItem('HECode') !== null){
          setIsLoading(true);
          var phoneNumber = localStorage.getItem('HECode');
          getAuthorizationCode(phoneNumber,'fromAtom');
          axios.get(`${BASE_URL}/check-user/${phoneNumber}/fromAtom`).then( res => {
            if(res.data.token){
              localStorage.setItem('token', res.data.token);
              var data = new FormData();
              data.append("phone", localStorage.getItem('HECode'));
              data.append("status", "subscribe");
              data.append("sender", "fromAtom");
              sendPhone(data, () => {
                fetchUserWithHE(localStorage.getItem('HECode'), 'null', "fromAtom", () => {
                  getToken(localStorage.getItem('HECode'),'fromAtom');
                });
              });  
            }
          }); 
        }else{
          navigate('/user/phone');
        }
      });
  };
  // After clicked subscribe 

  // Click Login 
  const redirectPhone = () => {
    localStorage.setItem('option', 'subscribed');
    navigate('/user/phone');
  }
  // Click Login 

  // Get Authorization Code 
  useEffect(() => {
    if(state !== null){
      getAuthorizationCode(state.phone,'fromUser');
      if(authorization_code !== null){
        localStorage.setItem('authorization_code',authorization_code);
      }
    }
  },[authorization_code]);
  // Get Authorization Code 

  // Redirect 
  useEffect(() => {
    if(userData !== null && userData.user !== 0 && userData.user[0].status === 'subscribe'){
      // console.log(userData);
      navigate('/home');
    }
  },[userData]);
  // Redirect 

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            marginTop: "80px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading color="#00A8FF" />
        </div>
      ) : (
        <div
          className="page-main-container"
          style={{
            padding: '0px',
            display: 'flex',
            position: 'relative',
            height: '100vh',
            fontFamily: 'Montserrat',
            width: '100vw',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundSize: "cover",
            backgroundImage: `url(${landingpagebg})`,
          }}
        >
          <div className="page-container">
            <div className="landing-carousel-container">
              <Carousel controls={false} className="p-0 m-0">
                <Carousel.Item>
                  <img
                    className="d-block w-100 website-width"
                    src={require("../../assets/landing/jbl_event.jpg")}
                    alt="first slide"
                  />
                </Carousel.Item>    
                <Carousel.Item>
                  <img
                    className="d-block w-100 website-width"
                    src={require("../../assets/landing/3 (1).png")}
                    alt="second slide"
                  />
                </Carousel.Item>            
                <Carousel.Item>
                  <img
                    className="d-block w-100 website-width"
                    src={require("../../assets/landing/1 (2).png")}
                    alt="third slide"
                  />
                </Carousel.Item>
              </Carousel>
              <button className="free-trial-btn" onClick={handleSubscribe}>
                (၇)ရက်အစမ်းသုံးရန် စာရင်းသွင်းမည်
              </button>
              <p className="login-text animate__animated animate__pulse animate__infinite animate__fast">စာရင်းသွင်းထားပြီးပီလား? ဤ <span className="link-text" onClick={redirectPhone}>နေရာ </span>ကို နှိပ်ပါ။</p>

              <StartFreeTrial />
              <ul className="landing-content-ul pb-5">
                <li>
                  စမ်းသပ်သုံးစွဲမှု ကာလအတ္ငင်း lifeX portal ရှိ content များကို
                  အကန့်အသတ်မရှိ ဝင်ရောက်ကြည့်ရှုနိုင်ပါမည်။
                </li>
                <li>
                  စမ်းသပ်သုံးစွဲမှု ကာလပြီးနောက် အသုံးပြုသူများသည် တစ်ပတ်စာ
                  အသုံးပြုခ ၄၉၉ ကျပ်ကို ပေးဆောင်ကာ ဆက်လက်အသုံးပြုနိုင်ပါမည်။
                </li>
                <li>
                  သက်ဆိုင်ရာ စည်းမျဥ်း သတ်မှတ်ချက်များကို အောက်တွင် ပြည့်စုံစွာ
                  ဖော်ပြထားပါသည်။
                </li>
                <li>
                  စတင်အသုံးပြုသည်နှင့် တစ်ပြိုင်နက် ဖော်ပြပါ စည်းမျဥ်း
                  သတ်မှတ်ချက်များကို သဘောတူပြီး ဖြစ်ပါသည်။
                </li>
              </ul>
            </div>
          </div>
          {/* all the css can find in components/StartFreeTrial/StartFreeStyle.css */}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    phone: state.phone.phone,
    userData: state.phone.userData,
    authorization_code: state.phone.authorization_code,
  };
};

export default connect(mapStateToProps, {
  sendPhone,
  fetchUser,
  getToken,
  fetchUserWithHE,
  getAuthorizationCode
})(StartFreeTrialScreen);
