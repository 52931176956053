import React, { useState } from "react";
import { ArrowLeft } from "react-bootstrap-icons";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./FAQStyle.css";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router";

const FAQ = () => {
  const navigate = useNavigate();
  const [key, setKey] = useState("Myanmar");
  return (
    <div className="faq-container" style={{ overflow: "hidden" }}>
      <div className="faq-main-container">
        <div className="mt-3 d-flex  align-items-center">
          <a
            onClick={() => {navigate("/profile/help")}}
            // href="/profile/help"
            style={{ textDecoration: "none", color: "#000" }}
          >
            <ArrowLeft size={24} className="mx-3" />
          </a>
          <h6
            className="my-0 p-0"
            style={{
              fontFamily: "Montserrat",
              marginLeft: "2%",
              fontSize: "14px",
              textAlign: "left",
            }}
          >
            FAQs of lifeX Lifestyle Digital Portal Service
          </h6>
        </div>

        <div className="px-3 mt-5">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="faq-tap-container"
            variant="pills"
          >
            <Tab eventKey="Myanmar" title="Myanmar" className="faq-tap">
              <Accordion className="accordion-container">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    1. lifeX Portal အကြောင်းကိုရှင်းပြပါ
                  </Accordion.Header>
                  <Accordion.Body className="accordion-body">
                    lifeX Lifestyle Portal ဆိုတာ မြင်ဖူးနေကြ lifestyle portal
                    မျိုးမဟုတ်ပဲ အသက် ၂၀ မှ ၃၀ ကျော်အတွင်းရှိ လူငယ်
                    ပရော်ဖက်ရှင်နယ်များအတွက် အသက်မွေးဝမ်းကြောင်း အောင်မြင်မှု၊
                    ကိုယ်စိတ်နှလုံး ကျန်းမာ ကြံ့ခိုင်မှု စတာတွေနဲ့ပတ်သက်တဲ့
                    လူမှုကိစ္စများကို မီးမောင်းထိုးပြပြီး အကြံပေးနိုင်ဖို့
                    ရည်ရွယ်ထားတဲ့ Portal တစ်ခုဖြစ်ပါတယ်။ လူငယ်
                    ပရော်ဖက်ရှင်နယ်များအနေနှင့် အကောင်းမြင်စိတ်၊ ပျော်ရွှင်မှု၊
                    ခွန်အား အပြည့်ဖြင့် မိမိရဲ့ အကောင်းဆုံးပုံစံကို
                    တည်ဆောက်နိုင်ဖို့ နည်းလမ်းသစ်များကို lifeX
                    တွင်ရှာဖွေနိုင်မှာဖြစ်ပါတယ်။
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    2. lifeX Portal ထဲကို ဘယ်လိုဝင်ရမလဲ
                  </Accordion.Header>
                  <Accordion.Body>
                    သင့်ရဲ့ Smartphone မှတဆင့် Wifi သို့မဟုတ်
                    မိုဘိုင်းအင်တာနက်ဖွင့်ထားချိန် ATOM Store App မှတဆင့်
                    ဝင်ရောက်နိုင်ပါတယ်။ အက်တမ်ဆင်းကတ် အသုံးပြုပြီး ATOM Store
                    App ကို Install ပြုလုပ် အသုံးပြုနေသူများအတွက်
                    အထူးသီးသန့်ဖြစ်ပါတယ်။
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    3. စာရင်းသွင်းခ ဘယ်လောက်ပေးရမလဲ၊ ဗီဒီယိုတွေ၊အကြောင်းအရာတွေ
                    ဘယ်လောက်ထိ လေ့လာကြည့်ရှု/ ဖတ်ရှုလို့ရမှာလဲ
                  </Accordion.Header>
                  <Accordion.Body>
                    စာရင်းသွင်းပြီးပါက တစ်ပတ်အခမဲ့အသုံးပြုနိုင်မှာဖြစ်ပြီး
                    အခမဲ့ကာလပြီးဆုံးပါက တစ်ပတ်လျှင် ၄၉၉
                    ကျပ်နှုန်းကျသင့်မှာဖြစ်ပြီး ကိုယ်ယူထားတဲ့ သက်တမ်းအတွင်းမှာ
                    ဗီဒီယိုတွေ၊အကြောင်းအရာတွေ အကန့်အသတ်မရှိ
                    လေ့လာကြည့်ရှု/ဖတ်ရှုနိုင်ပါမယ်
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    4. အခမဲ့ကာလမပြီးဆုံးခင် စားရင်းပယ်ဖျက်ပြီး
                    ပြန်လည်စားရင်းသွင်းရင်ကော အခမဲ့တစ်ပတ်ထပ် <br></br>
                    ရနိုင်ဦးမလား?
                  </Accordion.Header>
                  <Accordion.Body>
                    မရရှိနိုင်ပါ။ အခမဲ့ကာလသည် ပထမဆုံးအကြိမ်
                    စားရင်းပေးသွင်းသူများအတွက်သာ အကြုံးဝင်ပါသည်။
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    5. ဘယ်သူတွေ အသုံးပြုနိုင်မှာလဲ
                  </Accordion.Header>
                  <Accordion.Body>
                    နောက်ဆုံးထွက်ရှိထားတဲ့ ATOM STORE APP အသုံးပြုသည့် ATOM
                    ဆင်းကဒ်အသုံးပြုသူမှန်သမျှ lifeX Portal ကို
                    အသုံးပြုနိုင်မှာဖြစ်ပါတယ်
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    6. မသုံးချင်တော့ရင်/စာရင်းဖျက်ချင်ရင် ဘယ်လိုလုပ်ရမလဲ
                  </Accordion.Header>
                  <Accordion.Body>
                    lifeX Portal ဝန်ဆောင်မှုကို ဆက်လက်အသုံးမပြုလိုပါက *979*3*2#
                    ကိုခေါ်ဆို၍ဖြစ်စေ၊ ATOM STORE APP &#62; lifeX Portal &#62;
                    You &#62; Manage Plan &#62; Cancel your plan မှတဆင့်ဖြစ်စေ
                    ဝန်ဆောင်မှုကို ရပ်ဆိုင်းနိုင်သည်။
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    7. အခုသုံးနေတဲ့ဟန်းဆက်နဲ့ပဲ lifeX Portal ကို
                    အသုံးပြုလို့ရမှာလား
                  </Accordion.Header>
                  <Accordion.Body>
                    lifeX Portal ကိုအသုံးပြုရန် မိမိဟန်းဆက်အနေနဲ့
                    အင်တာနက်သုံးလို့ရတဲ့ ဗီဒီယိုကြည့်လို့ရတဲ့စမတ်ဖုန်း
                    ဖြစ်ရပါမယ်။ ဟန်းဆက်လက်စွဲစာအုပ်မှာ ပြန်စစ်ကြည့်နိုင်ပါတယ်
                    Mobile Internet (WAP/GPRS) ဆက်တင်အတွက်
                    ချိန်ထားပြီးသားဖြစ်ရပါမယ်
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>
                    8. မည်သည့်အချိန်အထိ lifeX Portal ကို
                    ဝင်ရောက်ကြည့်ရှုနိုင်မှာလဲ
                  </Accordion.Header>
                  <Accordion.Body>
                    မိမိစာရင်းသွင်းလိုက်တဲ့အပေါ်မူတည်ပြီး
                    စာရင်းသွင်းလိုက်တဲ့အချိန်ကစလို့ အခမဲ့ကာလပြီးဆုံး၍ အပတ်စဉ်
                    ဖုန်းငွေ ဖြတ်တောက်မှု အောင်မြင်ပြီး
                    တစ်ပတ်တာမှတ်ပုံတင်သက်တမ်းကျန်ရှိသည့်ကာလအတွင်း lifeX Portal
                    ကို မိမိစိတ်ကြိုက် အကန့်အသတ်မရှိဝင်ရောက်ကြည့်ရှုနိုင်ပါတယ်
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header>
                    9. စာရင်းသွင်းတဲ့အချိန်မှာ လက်ကျန်ငွေမလုံလောက်ရင်
                    ဘယ်လိုနေမလဲ
                  </Accordion.Header>
                  <Accordion.Body>
                    အခမဲ့ကာလကုန်ဆုံး၍လူကြီးမင်း၏လက်ကျန်ငွေမလုံလောက်လျှင်အဖိုးအခကောက်ခံနိုင်မည်မဟုတ်သဖြင့်
                    အဖွဲ့ဝင်သက်တမ်းကုန်ဆုံးမည်ဖြစ်ပြီး ဆက်လက်သက်တမ်းမတိုးပါက
                    ဝန်ဆောင်မှုအား ဆက်လက် အသုံးပြုနိုင်မည်မဟုတ်ပါ။
                    ဖုန်းလက်ကျန်ငွေလုံလောက်သည့်အချိန်မှသာ စာရင်းသွင်းခြင်းနှင့်
                    ဝန်ဆောင်မှုအသုံးပြုခြင်းအား ဆက်လက်လုပ်ဆောင်နိုင်ပါမယ်။
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                  <Accordion.Header>
                    10. ဗီဒီယိုကြည့်တဲ့အခါ၊ ဒေတာ အသုံးပြုခ ပေးရမှာလား
                  </Accordion.Header>
                  <Accordion.Body>
                    ပေးရမှာပါ၊ ဒေတာအတွက် ကျသင့်ငွေ ကောက်ခံသွားမှာပါ
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                  <Accordion.Header>
                    11. အကြာကြီးနေလို့မှ ဗွီဒီယိုတွေစာတွေ တက်မလာတဲ့အခါ
                    ဘာဆက်လုပ်ရမလဲ
                  </Accordion.Header>
                  <Accordion.Body>
                    လိုင်းကောင်းမကောင်းတစ်ချက်စစ်ပါ။ လိုင်းကောင်းမွန်ပါက
                    အပြင်ပြန်ထွက်လိုက်ပါ။ ဖုန်းကို ပါဝါအပိတ်အဖွင့်လုပ်ပြီးမှသာ
                    ပြန်ကြိုးစားကြည့်ပါ
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11">
                  <Accordion.Header>
                    12. ဝိုင်ဖိုင်သာမိတဲ့နေရာမျိုးမှာ lifeX Portal
                    မှာပါတဲ့ဗီဒီယိုတွေကို ကြည့်နိုင်ပါသလား
                  </Accordion.Header>
                  <Accordion.Body>
                    ဝိုင်ဖိုင်နဲ့လည်း ကြည့်ရှုနိုင်ပါတယ်
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Tab>
            <Tab eventKey="English" title="English" className="faq-tap">
              <Accordion className="accordion-container">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    1. What is the lifeX Lifestyle Digital Portal Service
                  </Accordion.Header>
                  <Accordion.Body className="accordion-body">
                    The lifeX is a lifestyle digital portal with a strong focus
                    on reliable, engaging and quality contents. We dig deep into
                    issues of men and women in their 20′s to 30’s face like
                    career success and personal wellness. At lifeX, we search
                    for new ways of bettering ourselves, as well as lead a life
                    on the path of happiness full of positivity and energy.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    2. How to access/subscribe lifeX Lifestyle Digital Portal?
                  </Accordion.Header>
                  <Accordion.Body>
                    The user can access/subscribe the service via ATOM Store
                    App. The service is only available for ATOM subscribers with
                    ATOM store application in their smartphones.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    3. How much for the subscription fees and how many times can
                    I access the contents?
                  </Accordion.Header>
                  <Accordion.Body>
                    There will be 7 days trials for all the new users. Once the
                    trial period is over, the customer will be charged 499 Kyats
                    per week. The subscriber can access unlimited times to the
                    contents during the validity of subscription.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    4. Can I get a 7-days free trial again if I unsubscribe
                    before the trial period is over?
                  </Accordion.Header>
                  <Accordion.Body>
                    No. The 7-days free trial period is only eligible for first
                    time subscriber with the first time subscription.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    5. Who can get the lifeX Lifestyle Digital Portal Service?
                  </Accordion.Header>
                  <Accordion.Body>
                    ATOM subscribers with latest version of ATOM STORE
                    Application can get the lifeX Lifestyle Digital Portal
                    Service.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    6. How to unsubscribe lifeX Lifestyle Digital Portal
                    Service?
                  </Accordion.Header>
                  <Accordion.Body>
                    The service can be unsubscribed by following below
                    instruction or by dialing *979*3*2# ATOM STORE APP &#62;
                    lifeX Portal &#62; You &#62; Manage Plan &#62; Cancel your
                    plan
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    7. Can I use the lifeX Lifestyle Digital Portal Service with
                    my current handset?
                  </Accordion.Header>
                  <Accordion.Body>
                    To use lifeX Lifestyle Digital Portal Service, the handset
                    must be a smartphone with an access to internet connection.
                    The Mobile Internet (WAP/GPRS) setting must be configured.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>
                    8. Till when can I access to lifeX Lifestyle Digital Portal?
                  </Accordion.Header>
                  <Accordion.Body>
                    You can access to lifeX Lifestyle Digital Portal unlimited
                    times during the 7-days free trial period and last date of
                    subscription validity.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header>
                    9. What will happen if the remaining balance is insufficient
                    to subscribe?
                  </Accordion.Header>
                  <Accordion.Body>
                    The subscription can only be made if the balance is
                    sufficient to subscribe. If the remaining balance is
                    insufficient to subscribe after 7-days free trial period is
                    over or cannot deduct after current subscription is over,
                    the user will not be able to access to the portal anymore.
                    Once the balance is sufficient for the subscription fees,
                    the service will be available to subscribe again.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                  <Accordion.Header>
                    10. Will data charges apply for reading and watching
                    contents?
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes, Data charges will apply for both reading and watching
                    contents.{" "}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                  <Accordion.Header>
                    11. How to fix unable to play videos and open contents?
                  </Accordion.Header>
                  <Accordion.Body>
                    We suggest you check the internet connection first. If the
                    connection is good, then try again after restarting the
                    handset.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11">
                  <Accordion.Header>
                    12. Can I use the lifeX Lifestyle Digital Portal on Wi-Fi?
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes, you can use the portal on Wi-Fi connection as long as
                    the validity is there.{" "}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
