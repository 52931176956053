import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  X,
  ChevronRight,
  CheckCircleFill,
  XCircleFill,
} from "react-bootstrap-icons";
import "./YesNoStyle.css";

import { useNavigate } from "react-router-dom";

const YesNoQuiz = (props) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className="yesno-main-container">
      <Container className="yesno-container">
        <Row className="d-flex align-items-center">
          <Col className="col-12">
            <X className="yesno-cancel-btn" onClick={goBack} />
          </Col>
          <Col className="col-12">
            <h3 className="multiple-choice-title">Quiz</h3>
          </Col>
        </Row>
        <Row>
          <Col className="col-12 yesno-card-container">
            <div className={props.background}>
              <CheckCircleFill className={props.right} />

              <XCircleFill className={props.wrong} />
            </div>
            <div className="yesno-card">
              <p className="yesno-question">{props.question[0].question}</p>
            </div>
          </Col>
          <div className="yesno-btn-container">
            <button
              className={props.yesBtn}
              onClick={() =>
                props.yesHandler(props.question[0].answers[0].isCorrect)
              }
            >
              YES
            </button>
            <button
              className={props.noBtn}
              onClick={() =>
                props.noHandler(props.question[0].answers[0].isCorrect)
              }
            >
              NO
            </button>
          </div>
        </Row>
        <Row>
          <Col className="col-12 d-flex justify-content-center align-items-center">
            <h5
              className="quiz-sub-title m-0 px-1"
              onClick={props.nextQuestion}
            >
              {props.slideCount === props.index + 1
                ? "Point ရယူရန်"
                : "ဤမေးခွန်းအားကျော်ရန်"}
            </h5>
            <ChevronRight className="next-multiple-choice-icon" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default YesNoQuiz;
