import { React , useState , useContext} from "react";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { connect } from "react-redux";
import { UserPhoneContext } from "../../useContext/useContext";
import { useNavigate } from "react-router";
import { Button } from "react-bootstrap";
import { sendPhone } from "../../redux/Actions/userAction";


const ConfirmScreen = (props) => {
  const navigate = useNavigate();
  const {userPhone} = useContext(UserPhoneContext);
  const { phone } = props;
  if(phone && phone.trial_check ==='trial'){
    // navigate('/home');
  }
  const handleConfirm = () => {
    var data = new FormData();
    data.append('phone' , userPhone);
    data.append('status' , 'unsubscribe');
    props.sendPhone(data);
  };

  return (
        <>
            <h1>Confirm</h1>
            <center><Button variant="outline-primary" onClick={handleConfirm}>Confirm</Button></center>
        </>
  );
};

const mapStateToProps = (state) => {
    return {
      phone: state.phone.phone,
    };
  };

export default connect(mapStateToProps, {
    sendPhone
  })(ConfirmScreen);
