import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import { BASE_URL } from "../../Common/constant";
import Loading from '../Loading/Loading';
import { fetchUser, getAuthorizationCode } from '../../redux/Actions/userAction';
import { sendPhone } from '../../redux/Actions/userAction';
import { Navigate, useNavigate } from 'react-router';
import { useLocation } from 'react-router';


const CGCallback = (props) => {
    const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const currentLocation = useLocation();
  const queryParams = new URLSearchParams(currentLocation.search);

  const TID = queryParams.get('TID');
  const status = queryParams.get('status');

  useEffect(() => {
    if(status == 0) {
        var phoneNumber = localStorage.getItem("phone");
        var data = new FormData();
        data.append('phone', phoneNumber);
    
        axios.post(`${BASE_URL}/req-token`,data).then(response => {
            console.log(response);
            if(response.data.token){
                getAuthorizationCode(localStorage.getItem('phone'),"fromUser");
                localStorage.setItem('token', response.data.token);
                var data = new FormData();
                data.append("phone", localStorage.getItem("phone"));
                data.append("status", "subscribe");
                data.append("sender", "fromUser");
                props.sendPhone(data, () => {
                    fetchUser(localStorage.getItem("phone"), "fromUser");
                });  
                setIsLoading(false);
                navigate('/home');
              }
        });
    } else {
        navigate('/startfree');
    }

  }, []);

  return (
    <div>
      {isLoading ? (
            <div
              style={{
                marginTop: "130px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <Loading color="#00A8FF" />
            </div>
          )
          :
          <p>Redirecting</p>
        }
    </div>
  )
}

const mapStateToProps = (state) => {
    return {
      userData: state.phone.userData,
    };
  };

export default connect(mapStateToProps, {
    sendPhone
  })(CGCallback);
