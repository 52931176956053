import {
  FETCH_POSTS,
  FETCH_DETAIL_POST,
  FETCH_SAVED_POSTS,
  GET_SAVED_POSTS,
  LIMITED_POSTS,
  SUBMIT_FEEDBACK,
  FETCH_TRENDING_POSTS,
  FETCH_LIVE_POSTS,
  FETCH_LEARN_POSTS,
  FETCH_EARN_POSTS,
  FETCH_SPECIAL_POSTS,
  CHECK_QUIZ,
  LEADERBOARD_LIST,
  USER_POSITION,
  USER_START_DATE,
  GET_IMAGE_SLIDER,
  RESET_POSTS,
  RESET_SPECIAL_POSTS,
  LIVE_SUB_CATEOGORIES,
  LEARN_SUB_CATEOGORIES,
  EARN_SUB_CATEOGORIES,
  SPECIAL_SUB_CATEOGORIES,
  FETCH_COURSES,
  STORE_CODE,
  CHECK_CODE,
  GET_CODES,
  CANCEL_CODE,
  GET_TERMS
} from "../Actions/postAction";

const initialState = {
  limited_posts: [],
  posts: [],
  post_detail: [],
  saved_post: [],
  saved_posts: [],
  feedBack : [],
  trending_posts : [],
  live_posts : [],
  learn_posts : [],
  earn_posts : [],
  special_posts : [],
  quiz_save: [],
  leaderboard_list: [],
  user_position: [],
  start_date: [],
  image_slider: [],
  live_sub : [],
  learn_sub : [],
  earn_sub : [],
  special_sub: [],
  courses: [],
  claim_code: {},
  check_code:[],
  codes: [],
  cancel_code: [],
  get_terms: []
};

const postReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_POSTS:
      return { ...state, posts: action.payload };
    case FETCH_DETAIL_POST:
      return { ...state, post_detail: action.payload };
    case FETCH_SAVED_POSTS:
      return { ...state, saved_post: action.payload };
    case GET_SAVED_POSTS:
      return { ...state, saved_posts: action.payload };
    case LIMITED_POSTS:
      return { ...state, limited_posts: action.payload };
    case SUBMIT_FEEDBACK:
      return { ...state, feedBack: action.payload };
    case FETCH_TRENDING_POSTS:
      return { ...state, trending_posts: action.payload };
    case FETCH_LIVE_POSTS:
      return { ...state, live_posts: action.payload };
    case FETCH_LEARN_POSTS:
      return { ...state, learn_posts: action.payload };
    case FETCH_EARN_POSTS:
      return { ...state, earn_posts: action.payload };
    case FETCH_SPECIAL_POSTS:
      return { ...state, special_posts: action.payload };
    case CHECK_QUIZ:
      return { ...state, quiz_save: action.payload };
    case LEADERBOARD_LIST:
      return { ...state, leaderboard_list: action.payload };
    case USER_POSITION:
      return { ...state, user_position: action.payload};
    case USER_START_DATE:
      return { ...state, start_date: action.payload};
    case GET_IMAGE_SLIDER:
      return { ...state, image_slider: action.payload};
    case RESET_SPECIAL_POSTS:
      return { ...state, special_posts: action.payload};
    case LIVE_SUB_CATEOGORIES:
      return { ...state, live_sub: action.payload};
    case LEARN_SUB_CATEOGORIES:
      return { ...state, learn_sub: action.payload};
    case EARN_SUB_CATEOGORIES:
      return { ...state, earn_sub: action.payload};      
    case SPECIAL_SUB_CATEOGORIES:
      return { ...state, special_sub: action.payload};   
    case FETCH_COURSES:
      return { ...state, courses: action.payload};
    case STORE_CODE:
      return { ...state, claim_code: action.payload};
    case CHECK_CODE:
      return { ...state,  check_code : action.payload};
    case GET_CODES:
      return { ...state,  codes : action.payload};
    case CANCEL_CODE:
      return { ...state, cancel_code: action.payload};
    case GET_TERMS:
      return { ...state, get_terms: action.payload};
    default:
      return state;
  }
};

export default postReducer;
