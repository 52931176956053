import React, { useState,useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { fetchDetailPost } from "../../redux/Actions/postAction";
import QuestionSlider from "../../components/Slider/QuestionSlider";

const PostQuestion = (props) => {
    const {id} = useParams();
    useEffect(() => {
        props.fetchDetailPost(id);
      }, []);
    let questionArray = props.question.length > 0 && props.question[0] && props.question[0].quizzes.sort((a,b) => {
        return a.question_type - b.question_type;
    });
        return (
        <div>
            <QuestionSlider questionArray={questionArray} post_detail={props.question}/>
        </div>
        );
    };
    const mapStateToProps = (state) => {
        return {
            question : state.post.post_detail
        }

    }
    export default connect(mapStateToProps,{
        fetchDetailPost,
    })(PostQuestion);