import React from 'react'
import { Col, Row } from "react-bootstrap";
import './CourseStyle.css';
import Image from "react-bootstrap/Image";
import UserImage from "../../assets/Avatar/right.png";
import CourseImage from "../../assets/quiz-image/img6.png";
import { IMAGE_BASE_URL } from "../../Common/constant";
import swal from "sweetalert";
import {ArrowLeft} from "react-bootstrap-icons";



import { useNavigate } from "react-router";
const Course = (props) => {
    const navigate = useNavigate();
    const courses = props.courses;
    // let primaryColor = [ "#5cafe1", "#019267","#ed8141","#5a5387"];
    // let secondaryColor = ["#019267", "#ed8141", "#5a5387",'#5cafe1'];

    // let primaryColor = ["#52aad6", "#4778af","#605299","#59406c"];
    // let secondaryColor = ["#4778af", "#605299", "#59406c","#52aad6"];

    let primaryColor = ["#5cafe1", "#4973a5","#5a5387", "#534465", ];
    let secondaryColor = ["#4973a5", "#5a5387", "#534465",'#5cafe1'];
    // const checkStatus = (status,course) => {

    // }
 
    return (
        
        <>
            <div className='terms_container'>
                <a onClick={() => {navigate("/profile")}} className="back">
                    <ArrowLeft
                        style={{ fontSize: "22px", color: "#999" }}
                    />
                </a>
                <h4  style={{fontWeight: 550, marginTop: '60px'}}>lifeX Point Reward Program</h4>
                <p className='terms_text'>lifeX portal quiz ကဏ္ဍမှ ရရှိလာသော ရမှတ်များကို အသုံးပြုပြီး မိတ်ဖက်အဖွဲ့အစည်းများ၏ ဝန်ဆောင်မှုများကို လျှော့စျေးဖြင့် ရယူနိုင်မည့် အစီအစဥ် ဖြစ်ပါသည်။</p>
            </div>
            {
                courses && courses.map((course,index)=> (
                    <a key={index} onClick={() => {navigate('/course/detail', { state : {course}});}}>
                        <div  className='scroll-container' style={{backgroundColor: secondaryColor[index % 4]}}>
                            <div className='section-container py-4' style={{backgroundColor: primaryColor[index % 4]}}>
                                <div className='discount' style={{backgroundColor:secondaryColor[index % 4]}}>
                                    <div className='discount_amount'>{course.discount}</div>
                                    <div className='discount_amount-two'>OFF</div>
                                </div>
                                <Row className="m-0 p-0">
                                    <Col className="col-8 description-container mt-3">
                                        <h4 className='course_main_title mt-3'>{course.name}</h4>

                                        <button type="button" className="detail_btn" style={{backgroundColor: secondaryColor[index % 4]}} onClick={() => {navigate('/course/detail', { state : {course}});}}><span>Detail</span></button>

                                    </Col>
                                    <Col className="col-4">
                                        <div className="point_container  d-flex justify-content-center align-items-center flex-column">
                                            <Image
                                                src={IMAGE_BASE_URL+'school_logo/' + course.schools.school_logo}
                                                className="school_img"
                                            />
                                            <div className='require_point_container d-flex justify-content-center align-items-center text-center'>
                                                <p className='require_point mt-3'>{course.point} points</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                            </div>
                        </div>
                    </a>
                ))
            }

        </>
    )
}

export default Course