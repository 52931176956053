import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import { Col, Container, Row } from "react-bootstrap";
import './earnstyle.css'
import Image from "react-bootstrap/Image";
import { BookHalf, PlayBtnFill, HeartFill, MicFill } from "react-bootstrap-icons";
import moment from "moment";
import { connect } from "react-redux";
import { fetchSavedPost,increaseCount } from "../../redux/Actions/postAction";
import SavedModal from "../SavedModal/SavedModal";
import UserImage from "../../assets/book-images/book6.png";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import { StarFill } from "react-bootstrap-icons";

const EarnBook = (props) => {
  const { user } = props;
  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState("Saved");
  const [styles] = useState('star-color');

  const navigate = useNavigate();
  const location = useLocation();


  let phone = localStorage.getItem("phone");

  const savePost = (bookID, phone, e) => {
    setModalShow(true);
    setTimeout(() => {
      setModalShow(false);
    }, 1000);

    if (e.currentTarget.classList.contains("save_icon")) {
      setModalText("Saved");
      e.currentTarget.classList.remove("save_icon");
      e.currentTarget.classList.add("saved_icon_1");
    } else {
      setModalText("Unsaved");
      e.currentTarget.classList.remove("saved_icon_1");
      e.currentTarget.classList.add("save_icon");
    }

    if (bookID) {
      props.fetchSavedPost(bookID, phone);
    } 
  };

  const handleRedirectByUser = () => {
    if (user !== null && user.user[0].lifecycle === "G") {
      swal({
        text: "၀န်ဆောင်မှုကို အပြည့်အ၀ သုံးစွဲရန် လက်ကျန်ငွေ မလုံလောက်ပါသဖြင့် ပြန်လည်ဖြည့်တင်းပြီးမှသာ အသေးစိတ် ၀င်ရောက်ဖတ်ရှုနိုင်ပါမည်။",

        button: "OK",
      });
    } else {
      navigate("/earn/book/" + props.bookID);
      localStorage.setItem('post_id',props.bookID);
    }
  };
  return (
    <>
      <SavedModal show={modalShow} text={modalText} />

      <div
        style={{ backgroundColor: props.secondaryColor }}
        className="scroll-container"
      >
        <div
          className="section-container"
          style={{ backgroundColor: props.primaryColor }}
        >
          <a
            onClick={handleRedirectByUser}
            style={{ textDecoration: "none", color: "black" }}
          >
            <Row className="p-0 m-0">
              <Col className="col-12 bottom-container">
                <p className="date p-0 m-0">
                  {moment(props.date).startOf("second").fromNow()}
                </p>
                {props.postType === "book" ? (
                  <BookHalf className="post_icon text-white" size={12} />
                ) : (props.postType == "video" ? (
                  <PlayBtnFill className="post_icon text-white" size={12} />
                ) : (
                  <MicFill className="post_icon text-white" size={12} />
                ))}         
              </Col>
            </Row>
            <Row className="p-0 m-0">
              <Col className="col-8">
                <h5 className="book_title"> {props.title}</h5>
                <p className="rating p-0 m-0">
                  <StarFill className={`${props.rate === 1 || props.rate >= 1.5 ? styles : 'star-color'} star-color-light`} />
                  <StarFill className={`${props.rate > 1.5 || props.rate === 2 ? styles : 'star-color'} star-color-light` } />
                  <StarFill className={`${props.rate > 2.5 || props.rate === 3 ? styles : 'star-color'} star-color-light` } />
                  <StarFill className={`${props.rate > 3.5 || props.rate === 4 ? styles : ''} star-color-light` } />
                  <StarFill className={`${props.rate > 4.5 ? styles : ''} star-color-light` } />
                </p>
              </Col>
              <Col className="col-4  d-flex justify-content-center align-items-center">
                <Image
                  src={props.img}
                  className="book_img"
                  onError={(e) => {
                    if (e.target.src !== "image_path_here") {
                      e.target.onerror = null;
                      e.target.src = UserImage;
                    }
                  }}
                />
              </Col>
            </Row>
          </a>
          <Row>
            <Col className="col-12 bottom-container">
              <HeartFill
                className={
                  props.postSave === "save" ? "saved_icon_1" : "save_icon"
                }
                size={23}
                onClick={(e) => savePost(props.bookID, phone, e)}
              />
              <p className="sub_title p-0 m-0">{props.subTitle}</p>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    BookData: state.post.posts,
    user: state.phone.userData,
  };
};

export default connect(mapStateToProps, {
  fetchSavedPost,
  increaseCount
})(EarnBook);
