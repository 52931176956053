import React, { useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useLocation } from "react-router-dom";
import ImageQuiz from "../../components/ImageQuiz/ImageQuiz";
import { QuestContext } from "../../useContext/useContext";

const ImageQuizScreen = (props) => {
  const value = useContext(QuestContext);
  const imageQuest = value.filter((quest) => quest.question_type === "3");
  const [count, setCount] = useState(1);
  const navigate = useNavigate();
  const initialState = {
    m1: "true-false-hide",
    m2: "true-false-hide",
    m3: "true-false-hide",
    m4: "true-false-hide",
  };

  const [isCorrect, setIsCorrect] = useState(initialState);

  const clearState = () => {
    setIsCorrect({ ...initialState });
  };

  const isCorrectHandler = (e, value, total_point) => {
    if(total_point == null ){
      props.setPoint(0);
    }else{
      props.setPoint(total_point);
    }

    setIsCorrect({
      m1: "true-false-show",
      m2: "true-false-show",
      m3: "true-false-show",
      m4: "true-false-show",      
    });
  };
  return (
    <div
      style={{
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Helmet bodyAttributes={{ style: "background-color : #00b4d8" }} />
      <ImageQuiz
        nextQuizHandler={props.next}
        isCorrect={isCorrect}
        isCorrectHandler={isCorrectHandler}
        imageQuest={imageQuest}
        slideCount={props.slideCount}
        index={props.index}
      />
    </div>
  );
};

export default ImageQuizScreen;
