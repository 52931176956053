import React from "react";
import { Container } from "react-bootstrap";
import "./AboutLifexSlideStyle.css";
import Carousel from "react-bootstrap/Carousel";
import { ArrowRight } from "react-bootstrap-icons";

const AboutLifexSlide = (props) => {
  return (
    <>
      <Container className="about-lifex-main-container">
        <div className="carousel-wrapper">
          <Carousel className="about-lifex-carousel" controls={false}>
            <Carousel.Item>
              <img
                className=" w-100"
                src={require("../../assets/about-lifex/1 welcome.png")}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className=" w-100"
                src={require("../../assets/about-lifex/1a.png")}
                alt="Second slide"
              />
            </Carousel.Item>
            {/* <Carousel.Item>
              <img
                className=" w-100"
                src={require("../../assets/StartFreeTrial/3Life.png")}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className=" w-100"
                src={require("../../assets/StartFreeTrial/4Skills.png")}
                alt="Second slide"
              />
            </Carousel.Item> */}
          </Carousel>
        </div>
        <button className="skip-btn" onClick={props.handleStart}>
          SKIP <ArrowRight />
        </button>
      </Container>
    </>
  );
};

export default AboutLifexSlide;
