import React ,{useEffect} from "react";
import { useNavigate } from "react-router";
import AboutLifexSlide from "../../components/AboutLifex/AboutLifexSlide";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

const AboutLifexScreen = () => {
  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // });
  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_path: window.location.pathname + window.location.search,
    });
  }, [window.location.pathname, window.location.search]);
  const navigate = useNavigate();

  const handleStart = () => {
    navigate("/home");
  };
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Helmet bodyAttributes={{ style: "background-color : #00A8FF" }} />
      <AboutLifexSlide handleStart={handleStart} />
    </div>
  );
};

export default AboutLifexScreen;
