import React, { useState } from "react";
import { Container } from "react-bootstrap";
import "./StartFreeStyle.css";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ArrowRight } from "react-bootstrap-icons";

const StartFreeTrial = (props) => {
  const [key, setKey] = useState("Myanmar");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Container className="free-trial-main-container">
        <div>
          <Button className="term-condition-btn" onClick={handleShow}>
            Terms & Conditions
            <ArrowRight size={18} style={{ marginLeft: "10px " }} />
          </Button>
          <div className="modal-main-container">
            <Modal
              show={show}
              onHide={handleClose}
              size="lg"
              className="term-condition-modal"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton className="modal-header-2">
                <Modal.Title style={{ fontSize: "14px" }}>
                  Terms & Conditions
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="term-modal-body">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="language-tap-container"
                  variant="pills"
                >
                  <Tab
                    eventKey="Myanmar"
                    title="Myanmar"
                    className="language-tap"
                  >
                    <ul className="terms-condition-container">
                      ဝန်ဆောင်မှုဆိုင်ရာအဖိုးအခကောက်ခံခြင်းဆိုင်ရာစည်းကမ်းချက်များ
                      <li>
                        စာရင်းပေးသုံးစွဲ မှုရယူထားသော
                        ဝန်ဆောင်မှု၏တရားဝင်အသုံးပြုနိုင်သည့်ကာလမှာ ၃၆၅
                        ရက်ဖြစ်ပြီး အပတ်စဉ် အရစ်ကျငွေပေးချေမှုစနစ်ဖြင့်
                        အသုံးပြုနိုင်မှာဖြစ်ပါတယ်။
                      </li>
                      <li>
                        အသုံးပြုမှုအတွက်အဖိုးအခကို ကောက်ခံသည့်အကြိမ်ရေမှာ
                        အပတ်စဉ်အဆက်လိုက်ဖြစ်ပါသည်။
                      </li>
                      <li>
                        အကယ်၍ အက်တမ်၏ မိတ်ဖက်ကုမ္မဏီများမှ ဝန်ဆောင်မှုတွင်
                        အခမဲ့စမ်းသပ်အသုံးပြုကာလပါရှိပါက
                        အသုံးပြုသူသည်သတ်မှတ်ထားသည့် အခမဲ့စမ်းသပ်အသုံးပြုကာလ
                        အတွက်သာဝန်ဆောင်မှုကိုအခမဲ့ အသုံးပြုနိုင်မည်ဖြစ်သည်။
                        ထိုအခမဲ့စမ်းသပ်ကာလပြီးဆုံးပါက သင်၏
                        မိုဘိုင်းလက်ကျန်ငွေထဲမှဝန်ဆောင်မှုအတွက် နေ့စဉ်/ အပတ်စဉ်/
                        လစဉ်ကျသင့်ငွေကို ကောက်ခံမည်ဖြစ်သည်။
                      </li>
                      <li>
                        သုံးစွဲသူဘက်မှ ရယူထားသည့်ဝန်ဆောင်မှုအသုံးပြုနေခြင်းကို
                        ပယ်ဖျက်နိုင်ပါတယ်။ ထိုသို့စာရင်းပယ်ဖျက်ရန်
                        အကြောင်းကြားမှုလက်ခံရရှိပြီးနောက် ကျွန်ုပ်တို့
                        အက်တမ်အနေဖြင့် ဝန်ဆောင်မှုအသုံးပြုခကို
                        ကောက်ခံတော့မည်မဟုတ်ပါ။
                      </li>
                      <li>
                        ဝန်ဆောင်မှုကိုရယူသည်ဟု အတည်ပြုမှုသည် ဤဝန်ဆောင်မှုဆိုင်ရာ
                        အဖိုးအခကောက်ခံခြင်း စည်းကမ်းချက်များကို
                        ကိုယ်တိုင်အသိအမှတ်ပြု လက်ခံကြောင်းအတည်ပြုပါသည်။
                        ဝန်ဆောင်မှုကိုလက်ခံပြီးသည်နှင့်တစ်ပြိုင်နက်စည်းမျဉ်းစည်းကမ်းချက်များကိုလက်ခံပြီး
                        ဖြစ်ပါသည်။
                      </li>
                    </ul>
                  </Tab>
                  <Tab
                    eventKey="English"
                    title="English"
                    className="language-tap"
                  >
                    <ul className="terms-condition-container">
                      Terms & Conditions
                      <li>
                        The service will be eligible to use for 365 days with
                        daily/ weekly/ monthly installment fees based on the
                        service typed you choose.
                      </li>
                      <li>
                        The ATOM collected fees for the use of service usage
                        frequency for a time (for example, non renewal or daily,
                        and there is no charge) or installment billing method
                        (eg weekly /monthly charge based on 365 days of the
                        service may be).
                      </li>
                      <li>
                        If the ATOM’s partner (third party) would provide/ serve
                        the trial period for their service, customer can enjoy
                        the trial period till the expiry date. Then, we may
                        charge based on your chosen service even after the trial
                        period is expired. And you will get charged from your
                        mobile balance for the daily/ weekly/ monthly service
                        fees after the trial period is end.
                      </li>
                      <li>
                        Confirm that access the service due to these Terms of
                        Service fees apply for recognition and acceptance
                        confirmation.
                      </li>
                      <li>
                        By clicking subscription you are accepting the Terms and
                        Condition of the Service used.
                      </li>
                    </ul>
                  </Tab>
                </Tabs>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </Container>
    </>
  );
};

export default StartFreeTrial;
