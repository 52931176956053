import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useState } from "react";
import { ArrowLeft } from "react-bootstrap-icons";
import { useDispatch } from "react-redux";
import { submitFeedback } from "../../../redux/Actions/postAction";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import "./settingStyle.css";
import { useNavigate } from "react-router";

const Feedback = (props) => {
  const navigate = useNavigate();
  const [feedback, setFeedback] = useState({
    name: "",
    email: "",
    phone: "",
    message_subject: "",
    message: "",
  });
  const { submitFeedback, feedbackResponse } = props;
  let phone = localStorage.getItem("phone");
  const feedbackSubmitHandler = (e) => {
    e.currentTarget.disabled = true;
    feedback.phone = phone;
    const feedback_data = feedback;
    submitFeedback(feedback_data);
    toast.success("Successfully Submit !", {
      position: toast.POSITION.TOP_CENTER,
      draggablePercent: 70,
      onClose: () => navigate("/profile/settings/thankyoufeedback"),
    });
  };

  return (
    <div className="setting-main-container" style={{ overflow: "hidden" }}>
      <div className="setting-container">
        <input
          type="hidden"
          className="phone-number"
          name="phone"
          value={phone}
        />
        <Row style={{ marginTop: "30px", fontFamily: "Montserrat" }}>
          <Col className="col-4">
            <a onClick={()=>{navigate("/profile/settings")}}>
              <ArrowLeft
                style={{ marginLeft: "10px", fontSize: "22px", color: "#757575" }}
              />
            </a>
          </Col>
          <Col className="col-8" style={{ fontFamily: "Montserrat" }}>
            <h4>Feedback</h4>
          </Col>
        </Row>
        <form>
          <Row className="mx-2">
            <Col className="col-12 mt-5">
              <input
                type="text"
                className="form-control py-2 feedback-input"
                placeholder="Name"
                onChange={(e) =>
                  setFeedback({ ...feedback, name: e.target.value })
                }
                required
              />
            </Col>

            <Col className="col-12 my-3">
              <input
                type="text"
                className="form-control py-2 feedback-input"
                placeholder="Email"
                onChange={(e) =>
                  setFeedback({ ...feedback, email: e.target.value })
                }
                required
              />
            </Col>
            <Col className="col-12">
              <input
                className="form-control py-2 feedback-input"
                placeholder="Message Subject"
                onChange={(e) =>
                  setFeedback({ ...feedback, message_subject: e.target.value })
                }
                required
              />
            </Col>
            <Col className="col-12 my-3">
              <textarea
                className="form-control feedback-input"
                id="exampleFormControlTextarea1"
                rows="3"
                placeholder="Messages"
                onChange={(e) =>
                  setFeedback({ ...feedback, message: e.target.value })
                }
                required
              ></textarea>
            </Col>
            <Col className="col-12 mt-2 justify-content-center align-itmes-center">
              <button onClick={(e) => feedbackSubmitHandler(e)}
                className="btn px-3 py-2 text-white fw-bold"
                style={{
                  borderRadius: "30px",
                  backgroundColor: "#00a8ff",
                  margin: "auto",
                  fontFamily: "Montserrat",
                  fontSize: "12px",
                  display: "block",
                  width: "100%",
                }}>
                  Submit now
              </button>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    feedbackResponse: state.post.feedBack,
  };
};

export default connect(mapStateToProps, {
  submitFeedback,
})(Feedback);
