import React, { useContext, useEffect, useState} from "react";
import Tab from "../../components/nav/Tab";
import AllBook from "../../components/Home/HomeBook";
import { IMAGE_BASE_URL, BASE_URL } from "../../Common/constant";
import Loading from "../../components/Loading/Loading";
import ScrollLoading from "../../components/Loading/ScrollLoading";
import { useNavigate } from "react-router";
import { UserContext } from "../../useContext/useContext";
import ReactGA from 'react-ga';
import Swal from 'sweetalert2';
import '../../App.css'
import axios from "axios";
import { Fade } from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css';

import BookIcon from "../../assets/about-lifex/book.png";
import VideoIcon from "../../assets/about-lifex/video.png";
import AudioIcon from "../../assets/about-lifex/audio.png";

import BlueBookIcon from "../../assets/about-lifex/blue_article.png";
import BlueVideoIcon from "../../assets/about-lifex/blue_video.png";
import BlueAudioIcon from "../../assets/about-lifex/same_blue_audio.png";

//image slider
import Banner1 from "../../assets/banner/1 mobile.jpg";
import Banner2 from "../../assets/banner/2 mobile.jpg";
import Banner3 from "../../assets/banner/3 mobile.jpg";
import Banner4 from "../../assets/banner/4 mobile.jpg";


//redux import
import { connect } from "react-redux";
import { limitedPosts, getImageSlider,increaseCount, searchPosts } from "../../redux/Actions/postAction";
import { getAuthorizationCode } from "../../redux/Actions/userAction";
import { useDispatch } from "react-redux";
import { RESET_HOME_POSTS } from "../../redux/Actions/postAction";

const Home = (props) => {
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch({
      type: RESET_HOME_POSTS,
      payload: [],
    });
  })

  const { BookData, limitedPosts, getAuthorizationCode, authorization_code, ImageSlider, getImageSlider } = props;
  const { subscribe } = useContext(UserContext);
  // limited posts
  const [posts, setPosts] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [page, setPage] = useState(0);
  const [type,setType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  let navigate = useNavigate();
  let ph = localStorage.getItem('phone');
  const phone = ph.substring(2);
  const size = BookData.size;
  const data = BookData.records;
  const images = ImageSlider.data;
  let auth_code = localStorage.getItem('authorization_code');
  let image_list = [Banner1];
  const [bookSrc, setBookSrc] = useState(BookIcon);
  const [videoSrc, setVideoSrc] = useState(VideoIcon);
  const [audioSrc, setAudioSrc] = useState(AudioIcon);
  
  useEffect(() => {
    // console.log(auth_code);
    // if(!auth_code || auth_code == null) {
    //   return navigate('/startfree');
    // }
    getAuthorizationCode(ph,'fromUser');
    localStorage.setItem('authorization_code',authorization_code);
    getImageSlider();

    //Google Analytic
    // ReactGA.pageview(window.location.pathname + window.location.search);
      window.gtag('event', 'page_view', {
        page_path: window.location.pathname + window.location.search,
      });
    // Google Analytic
    if(localStorage.getItem('count') === '1'){
      axios.get(`${BASE_URL}/get_image`,{
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization':'Bearer '+localStorage.getItem("token")
      }
      }).then((response) => {
        if(response.data.data.length > 0 && response.data.data[0].ads_image && response.data.data[0].status === 'published'){
          setTimeout(()=>{
            Swal.fire({
              imageUrl: IMAGE_BASE_URL+response.data.data[0].ads_image,
              imageWidth: '100%',
              imageHeight: '100%',
              showConfirmButton: false, 
              timer: 10000,
              timerProgressBar: true,
              showCloseButton: true,
            });
            localStorage.removeItem('count');
          },1000);
        };
      });
    }

    limitedPosts(page,auth_code,type);

  },[]);
  
  function getPost(type) {
    if(auth_code !== null){
      limitedPosts(page,auth_code,type);
      setIsFetching(false);
    } else {
      navigate('/');
    }
  }

  function getMorePosts() {
    setTimeout(() => {
      setPage(page + 5);
      getPost(type);
    }, 4000);
  }

  function handleScroll() {
    if (window.innerHeight !== document.documentElement.offsetHeight) {
      setIsFetching(true);
    } else {
      setIsFetching(false);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if(auth_code !== null){
      if(type === null){
        getPost(null);
        setType(null);
        setPage(5);
      }else{
        getPost(type);
        setPage(5);
      }
    }
  }, [type]);

  useEffect(() => {
    if (!isFetching) {
      return;
    } else {
        if (page <= size) {
          getMorePosts();
        } else {
          setIsFetching(false);
        }
    }
  }, [isFetching]);

  useEffect(() => {
    if (data !== undefined) {
      if (data.length > 0 && data !== undefined) {
        setPosts([...posts, ...data]);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  }, [data]);

  const handlePostTypeButton = (e) => {
    setPosts([]);
    setIsLoading(true);
    setPage(0);
  
    const clickedButtonType = e.target.value;
    
    if (clickedButtonType === type) {
      // Double-clicked on the same button, remove active class and reset type
      e.target.classList.remove('active');
      setType('');
      setBookSrc(BookIcon);
      setVideoSrc(VideoIcon);
      setAudioSrc(AudioIcon);

    } else {
      setType(clickedButtonType);
      
      // Add active class to the clicked button and remove it from other buttons
      e.target.classList.add('active');
      Array.from(document.querySelectorAll('.type-btn')).forEach((el) => {
        if (e.target !== el) {
          el.classList.remove('active');
        }
      });
  
      // Update the icon sources based on the clicked button type
      if (clickedButtonType === 'book') {
        setBookSrc(BlueBookIcon);
        setVideoSrc(VideoIcon);
        setAudioSrc(AudioIcon);
      } else if (clickedButtonType === 'video') {
        setBookSrc(BookIcon);
        setVideoSrc(BlueVideoIcon);
        setAudioSrc(AudioIcon);
      } else {
        setBookSrc(BookIcon);
        setVideoSrc(VideoIcon);
        setAudioSrc(BlueAudioIcon);
      }
    }
  };
  

  // let primaryColor = ["#00704f", "#f96105", "#0395b2", "#d32ffc"];
  // let secondaryColor = ["#f96105", "#0395b2", "#d32ffc",'#00704f'];

  // let primaryColor = ["#019267", "#fc7321", "#00b4d8", "#fa4b46"];
  // let secondaryColor = ["#fc7321", "#00b4d8", "#fa4b46",'#019267'];

  // let primaryColor = ["#00b4d8", "#fa4b46","#019267", "#fc7321", ];
  // let secondaryColor = ["#fa4b46", "#019267", "#fc7321",'#00b4d8'];

  let primaryColor = ["#5cafe1", "#4973a5","#5a5387", "#534465", ];
  let secondaryColor = ["#4973a5", "#5a5387", "#534465",'#5cafe1'];

  // let primaryColor = ["#05c78d", "#fd8842", "#20d7fb", "#ed6663"];
  // let secondaryColor = ["#fd8842", "#20d7fb", "#ed6663",'#05c78d'];
  

  return (
    <>
      <Tab background="#00A8FF" />
      <div className="home_container">
        <div
          className="home_main_container"
        >
          <div className="slide-container">
            <Fade
              data-interval="false"
              autoplay={true}
              interval={false}
              infinite={true}
              indicators={false}
              showNavs={false}
              arrows={false}
              className="img-slider-container"
            >
              { images &&  images.map((fadeImage, index) => (
                <div className="each-fade" key={index}>
                  <div className="image-container">
                    <img src={IMAGE_BASE_URL + fadeImage.name} className="img_slider"/>
                  </div>
                </div>
              ))}
              {/* { image_list.map((fadeImage, index) => (
                <div className="each-fade" key={index}>
                  <div className="image-container">
                    <img src={fadeImage} className="img_slider"/>
                  </div>
                </div>
              ))} */}
            </Fade>
            <div 
                  style={{
                    backgroundColor: "#fff",
                    width: "100%",
                    height: "50px"
                  }}
            >
              <center>
                <div className="btn-post-type" style={{marginTop: "20px"}}>
                    <button type="button" onClick={(e) => handlePostTypeButton(e)} value='book' className="btn type-btn btn-book"><img src={bookSrc} className="icon"/>Article</button>
                    <button type="button" onClick={(e) => handlePostTypeButton(e)} value='video' className="btn type-btn btn-video"><img src={videoSrc} className="icon"/>Video</button>
                    <button type="button" onClick={(e) => handlePostTypeButton(e)} value='audio' className="btn type-btn btn-audio"><img src={audioSrc} className="icon audio-icon"/>Audio</button>
                </div>
              </center>
            </div>
          </div>
          {/* <button className="btn btn-info" onClick={() => navigate('/audio')}> Go to audio </button> */}
          {isLoading ? (
            <div
              style={{
                marginTop: "130px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <Loading color="#00A8FF" />
            </div>
          ) : (
            posts.map((book, index) => (
              
              <AllBook
                key={index}
                bookID={book.id}
                count={book.count}
                date={book.published_date}
                rate={book.rates.length > 0 ? book.rates[0].rate : 1}
                title={book.post_name}
                img={IMAGE_BASE_URL + book.images}
                subTitle={book.subcategory.sub_category_name}
                primaryColor={primaryColor[index % 4]}
                secondaryColor={secondaryColor[index % 4]}
                postType={book.post_type}
                postSave={
                  book.post_save && book.post_save.phone == phone ? "save" : ""
                }
              />
            ))
          )}
          {isFetching && <ScrollLoading color="#00A8FF" />}
        </div>
      </div>

    </>
  );
};

const mapStateToProps = (state) => {
  return {
    filterData: state.post.posts,
    BookData: state.post.limited_posts,
    ImageSlider: state.post.image_slider,
    authorization_code: state.phone.authorization_code,
  };
};

export default connect(mapStateToProps, {
  limitedPosts,
  getImageSlider,
  getAuthorizationCode,
  increaseCount,
  searchPosts,
})(Home);
