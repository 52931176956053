import React from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ArrowLeft, Pencil } from "react-bootstrap-icons";
import "./style.css";

import { fetchUser, editUser } from "../../../redux/Actions/postAction";
import { IMAGE_BASE_URL } from "../../../Common/constant";
import { connect, useDispatch } from "react-redux";

//image import
import ProfileImage from "../../../assets/Avatar/camera-avator.png";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ToastContainer, Slide } from "react-toastify";
import moment from "moment/moment";
import "react-toastify/dist/ReactToastify.css";

const EditProfile = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { userData } = props;
  const [user, setUser] = useState(state.user[0]);

  const [year, setYear] = useState(
    user.date_of_birth && user.date_of_birth.toLocaleString().split("-")[0]
  );
  const [month, setMonth] = useState(
    user.date_of_birth && user.date_of_birth.toLocaleString().split("-")[1]
  );
  const [day, setDay] = useState(
    user.date_of_birth && user.date_of_birth.toLocaleString().split("-")[2]
  );
  const [picture, setPicture] = useState(`${IMAGE_BASE_URL}${user.photo && user.photo}`);
  const [image, setImage] = useState("");

  const onChangeFile = (event) => {
    setImage(event.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = (event) => {
      setPicture(reader.result);
    };
  };

  const UpdateHandler = (e) => {
    let date = year + "-" + month + "-" + day;
    const dateFormat = 'YYYY-MM-DD';
    const toDateFormat = moment(new Date(date)).format(dateFormat);
 
    user.date_of_birth = date;
    user.photo = image;
    let user_data = user;
    let phone = localStorage.getItem('phone');
    if(!year && !month && !day ){

      dispatch(editUser(user_data, phone));

      toast.success("Successfully Submit !", {
        position: toast.POSITION.TOP_CENTER,
        draggablePercent: 70,
        onClose: () => navigate('/profile')
      });
      
    }else if(moment(toDateFormat, dateFormat, true).isValid()){
      dispatch(editUser(user_data, phone));
      e.currentTarget.disabled = true;

      toast.success("Successfully Submit !", {
        position: toast.POSITION.TOP_CENTER,
        draggablePercent: 70,
        onClose: () => navigate('/profile')
      });
      
    } else
    {
      toast.error("Please Enter Valid Date!", {
        position: toast.POSITION.TOP_CENTER,
        draggablePercent: 70,
      });
    }
  };
  return (
    <>
    <div className="edit_main_container">
      <div className="edit_container">
        <input
          type="hidden"
          className="phone-number"
          name="phone"
          value={user.phone}
        />
        <div className="edit-container">
          <ToastContainer transition={Slide} autoClose={1000} />
          <div className="col-4 d-flex justify-content-start">
            <a onClick={() => {navigate("/profile")}}>
              <ArrowLeft
                color="#757575"
                style={{ fontSize: "22px", marginLeft: "10px" }}
              />
            </a>
          </div>

          <div className="col-8">
            <h4 style={{ fontSize: "18px", color: "#000000" }}>Edit Profile</h4>
          </div>
        </div>
        <div className="img-container mb-5">
          <img
            src={picture}
            alt="profile"
            className="profile"
            onError={(e) => {
              if (e.target.src !== "image_path_here") {
                e.target.onerror = null;
                e.target.src = ProfileImage;
              }
            }}
          />
          <input
            type="file"
            id="file"
            name="photo"
            style={{ display: "none" }}
            onChange={(e) => onChangeFile(e)}
          />
          <label htmlFor="file">
            <Pencil className="edit-icon" />
          </label>
        </div>

        <Row className="form-container">
          <Col className="input-container col-10">
            <label htmlFor="name" style={{ color: "#222" }}>
              အမည် (optional)
            </label>
            <input
              name="name"
              type="text"
              value={user.name}
              onChange={(e) => setUser({ ...user, name: e.target.value })}
              className="edit-input"
            />
          </Col>

          <Col className="input-container col-10">
            <label htmlFor="name" style={{ color: "#222" }}>
              အီးမေးလ် (optional)
            </label>
            <input
              name="email"
              type="email"
              value={user.email}
              onChange={(e) => setUser({ ...user, email: e.target.value })}
              className="edit-input"
            />
          </Col>

          <Col className="input-container col-10">
            <label htmlFor="name" style={{ color: "#222" }}>
              အလုပ်အကိုင် (optional)
            </label>
            <input
              name="job"
              value={user.job}
              onChange={(e) => setUser({ ...user, job: e.target.value })}
              className="edit-input"
            />
          </Col>
          <Col className="input-container col-10">
            <label htmlFor="name" style={{ color: "#222" }}>
              မွေးသက္ကရာဇ် (optional)
            </label>
            <Row>
              <Col className="col-3">
                <input
                  name="year"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  className="edit-input"
                  placeholder="0000"
                />
              </Col>
              <Col className="col-6">
                <input
                  name="month"
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                  className="edit-input"
                  placeholder="00"
                />
              </Col>
              <Col className="col-3">
                <input
                  name="day"
                  value={day}
                  onChange={(e) => setDay(e.target.value)}
                  className="edit-input"
                  placeholder="00"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="img-container">
          <button onClick={UpdateHandler} className="update-btn">Update</button>
        </div>
      </div>
    </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.phone.userData,
  };
};

export default connect(mapStateToProps, {
  fetchUser,
})(EditProfile);
