import React from "react";
import Footer from "../../components/Footer/footer";
import { useLocation, useNavigate } from "react-router";

const FooterScreen = () => {
  const location = useLocation();
  const path = location.pathname;
  const navigate = useNavigate();

  var r = /\d+/;

  return (
    <>
      {path.match(r) ||
      path === "/startfree" ||
      path === "/explore" ||
      path === "/" ||
      path === "/startfree/about_lifex" ||
      path === "/renewscreen" ||
      path === "/profile/settings/feedback" ||
      path === "/user/phone" ||
      path === "/profile/edit_profile" ? null : (
        <Footer />
      )}
    </>
  );
};

export default FooterScreen;
