import React, { useState , useContext } from "react";
import MultipleChoice from "../../components/MultipleChoice/MultipleChoice";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { QuestContext } from "../../useContext/useContext";

const MultipleChoiceScreen = (props) => {
  
  const navigate = useNavigate();
  const [multiInput, setMultiInput] = useState("input-show");
  const [answerCheck, setAnswerCheck] = useState("right-wrong-hide");
  const [done, setDone] = useState("done-show");
  const value = useContext(QuestContext);
  const multiChoiceQuestion = value.filter(quest => quest.question_type === "1" );
  const handleCheckbox = () => {
    setMultiInput("input-hide");
    setAnswerCheck("right-wrong-show");
    setDone("done-hide");
  };
  return (
    <div>
      <Helmet bodyAttributes={{ style: "background-color : #00b4d8" }} />
      <MultipleChoice
        handleCheckbox={handleCheckbox}
        multiInput={multiInput}
        answerCheck={answerCheck}
        setPoint={props.setPoint}
        point={props.point}
        done={done}
        handleNext={props.next}
        multiChoiceQuestion={multiChoiceQuestion}
        slideCount={props.slideCount}
        index={props.index}
      />
    </div>
  );
};

export default MultipleChoiceScreen;
