import React from "react";
import { ChevronLeft, X } from "react-bootstrap-icons";
import "./VideoSliderStyle.css";
import { Row, Col } from "react-bootstrap";
import VideoPlayer from "react-video-js-player";

import { VIDEO_BASE_URL, IMAGE_BASE_URL } from "../../Common/constant";
import { Markup } from "interweave";

const VideoSlider = (props) => {
  return (
    <>
      {props.SliderData &&
        props.SliderData.map((videoData, index) => (
          <div className="video-main-container" key={index}>
            <div className="videoContainer">
              <div className="back-container">
                <ChevronLeft className="x-icon" onClick={props.goBack} />
              </div>
              <div className="video_card">
                <div className="video_card_header">
                  <h5 className="fw-bold m-3">{videoData.subcategory.maincategory.category.category_name} : {videoData.subcategory.sub_category_name}</h5>
                  <div className="video-container">
                    <VideoPlayer
                      className="video"
                      src={`${VIDEO_BASE_URL}${videoData.video_name}`}
                      poster={`${IMAGE_BASE_URL}${videoData.book_cover}`}
                      color="red"
                    />
                  </div>
                </div>
                <div className="card-body p-3">
                  <h6 className="text-center fw-bold mb-2" style={{ lineHeight: 1.5}}>{videoData.post_name}</h6>
                  <p className="video-text">
                    <Markup content={props.word[0]} />
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};
export default VideoSlider;
