import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './CourseStyle.css';
import {ArrowLeft} from "react-bootstrap-icons";

import { useNavigate } from "react-router";
const Terms = () => {
    const navigate = useNavigate();
 
    return (
        <>
          <Row className="my-3">
            <Col className="col-2">
              <a onClick={() => {navigate("/course")}}>
                <ArrowLeft
                  style={{ marginLeft: "10px", fontSize: "22px", color: "#757575" }}
                />
              </a>
            </Col>
            <Col className="col-9 text-center">
              <h4>life X Course Deals</h4>
            </Col>
          </Row>
          <div className='course_main_container'>
                <div className='p-4'>
                    {/* <h4 className='mt-1 text-center'>Points to Deal</h4> */}
                    {/* <p className='align-self-start des_title'  style={{marginBottom: 5}}>Description</p>
                    <p style={{fontSize: 14, textAlign:'justify'}}>lifeX Portal ဝန်ဆောင်မှုကို အသုံးပြုပြီး quiz ကဏ္ဍမှ ရရှိလာသော ရမှတ်များကို မိတ်ဖက် သင်ကြားရေး အဖွဲ့အစည်းများ၏ သင်တန်းများတွင် လျှော့စျေးဖြင့် တက်ရောက် သင်ယူနိုင်မည့် အစီအစဥ် ဖြစ်ပါသည်။ အစီအစဥ်ကို ရယူမည် ဖြစ်ကြောင်း သဘောတူညီမှု ရပြီးသည်နှင့် တစ်ပြိုင်နက် ကုဒ်နံပါတ် ခြောက်လုံး ရရှိလာမည် ဖြစ်ပါသည်။ အဆိုပါ ကုဒ်နံပါတ် ခြောက်လုံးကို ပေးပို့ပြီး သုံးရက်အတွင်း သက်ဆိုင်ရာ ‌သင်တန်းကျောင်းသို့ သွားရောက်  ပြသကာ သတ်မှတ်ထားသော လျှော့စျေး ပမဏဖြင့် သင်တန်း အပ်နှံ နိုင်ပါသည်။ </p>
                    <hr></hr> */}
                    <p className='des_title text-center'>Terms & Conditions</p>
                    <ul className='terms'>
                        <li className='mb-3'>ပေး‌ပို့ထားသော ကုဒ်နံပါတ် တစ်ခုကို တစ်ကြိမ်သာ အသုံးပြုခွင့် ရရှိမည် ဖြစ်ပြီး တစ်ကြိမ်လျှင် သင်တန်း တစ်ခုစာ အတွက်သာ ရယူနိုင်ပါသည်။  </li>
                        <li className='mb-3'>ကုဒ်နံပါတ် ရယူပြီး သတ်မှတ်ရက် အတွင်းသာ သက်ဆိုင်ရာ ‌သင်တန်းကျောင်းသို့ သွားရောက်ကာ သင်တန်း အပ်နှံ နိုင်ပါသည်။ သတ်မှတ်ရက် ကျော်လွန်သွားပါက အစီအစဥ်သည် အကြုံးမဝင်ပါ။ </li>
                        <li className='mb-3'>သင်တန်း အစီအစဥ် တစ်ခုချင်းစီသည် သတ်မှတ်ထားသည့် အရင်ဆုံး လာရောက် အပ်နှံသည့် အရေအတွက် ကိုသာ အခွင့်အရေး ပေးသွားမည် ဖြစ်ပါသည်။ </li>
                        <li className='mb-3'> သင်တန်းကျောင်းမှ လျှော့စျေး စီအစဥ် ရယူပြီးကြောင်း အတည်ပြုပြီးမှသာ လဲလှယ်ရမည့် point များကို နှုတ်ယူသွားမည် ဖြစ်ပါသည်။ </li>
                        <li className='mb-3'> အစီအစဥ်နှင့် သက်ဆိုင်သော မည်သည့်အရာကိုမျှ လွှဲပြောင်း ပေးအပ်ခြင်း၊ လဲလှယ်ခြင်း၊ တန်ဖိုးငွေ ပြန်အမ်းခြင်း၊ ငွေသား (သို့) အခြားအကျိုးခံစားခွင့်များနှင့် အစားထိုးလဲလှယ်ခြင်းများ မပြုလုပ်ရပါ။  </li>
                        <li className='mb-3'>ကုဒ်နံပါတ် ပေးပို့လုပ်ဆောင် ပြီးနောက် အသုံးပြုသူကြောင့် ဖြစ်ပေါ်လာသော နောက်ဆက်တွဲ ကိစ္စရပ်များ နှင့် ပတ်သတ်ပြီး lifeX Portal ဝန်ဆောင်မှု (Parabyte Media Limited) မှတာဝန်ယူဖြေရှင်းပေးမည် မဟုတ်ပါ။ </li>
                        <li className='mb-3'> အစီအစဉ်တွင် ရယူထားသော လူကြီးမင်းတို့၏ ဖုန်းနံပါတ်များကို lifeX Portal ဝန်ဆောင်မှု (Parabyte Media Limited) အတွက်သာ ရယူထားခြင်းဖြစ်ပါသည်။ အစီအစဉ်နှင့် တိုက်ရိုက် ပတ်သက်ဆက်နွယ်နေသော Service Provider များအား လိုအပ်လာပါက သုံးစွဲသူတို့၏ ဖုန်းနံပါတ်များအား ပေးပို့မည်ဖြစ်ပြီး မည်သည့် အကြောင်းကြောင့်မှ အဆိုပါဖုန်းနံပါတ် များကို အခြားသော ကုမ္ပဏီများအား ပေးပို့ခြင်း ပြုလုပ်သွားမည် မဟုတ်ပါ။ </li>
                        <li className='mb-3'> အစီအစဉ်နှင့် ပတ်သက်၍ အငြင်းပွားမှု တစ်စုံတစ်ရာ ပေါ်ပေါက်လာပါက lifeX Portal ဝန်ဆောင်မှု (Parabyte Media Limited) ၏ ဆုံးဖြတ်ချက်သာလျှင် အတည်ဖြစ်ပါသည်။ </li>
                        <li className='mb-3'> အစီအစဉ်တွင် ပါဝင်သူများ အားလုံးသည် အစီအစဉ်နှင့်ပတ်သက်သည့် စည်းမျဉ်းစည်းကမ်းများနှင့် lifeX Portal ဝန်ဆောင်မှု (Parabyte Media Limited) ၏ ဆုံးဖြတ်ချက်များကို လက်ခံသဘောတူပြီးဖြစ်ပါသည်။</li>
                    </ul>
                </div>
          </div>
        </>
    )
}

export default Terms