import React,{useState, useEffect} from "react";
import {
  ArrowLeft,
  Search,
  BookHalf,
  PlayBtnFill,
} from "react-bootstrap-icons";
import "./ExploreStyle.css";
import { useNavigate } from "react-router";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
//redux import
import { connect } from "react-redux";
import { increaseCount } from "../../redux/Actions/postAction";
import swal from "sweetalert";
import { BookFill, CameraVideoFill, OpticalAudioFill } from "react-bootstrap-icons";
import BookIcon from "../../assets/about-lifex/book.png";
import VideoIcon from "../../assets/about-lifex/video.png";
import AudioIcon from "../../assets/about-lifex/audio.png";
import Loading from "../../components/Loading/Loading";

import { useDispatch } from "react-redux";
import { RESET_EXPLORE_POSTS } from "../../redux/Actions/postAction";


const Explore = (props) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [posts, setPosts] = useState(null);

  const [type,setType] = useState(null);
  const [name,setName] = useState(null);

  const data = props.filterData && props.filterData;

  useEffect(() => {
    if(data !== undefined){
      if (data.length > 0 && data !== undefined) {
       setPosts([...posts, ...data]);
       setIsLoading(false);
     } else {
       setIsLoading(true);
     }}
   }, [data]);


  const { user } = props;
  const navigate = useNavigate();

  const backToHome = () => {
    navigate("/home");
  };

  useEffect(()=>{
    dispatch({
      type: RESET_EXPLORE_POSTS,
      payload: [],
    });
  })

  const countHandler = (mylink, bookID) => {
    props.increaseCount(bookID);
    navigate(mylink);
  };

  const handleRedirectByUserSearch = id => {
    if(user && user.user[0].lifecycle === 'G'){
      swal({
        text: "၀န်ဆောင်မှုကို အပြည့်အ၀ သုံးစွဲရန် လက်ကျန်ငွေ မလုံလောက်ပါသဖြင့် ပြန်လည်ဖြည့်တင်းပြီးမှသာ အသေးစိတ် ၀င်ရောက်ဖတ်ရှုနိုင်ပါမည်။",

        button: "OK",
      })  
    }else{
      countHandler(`/home/book/` + id, id)
    }
  }

  const handlePostTypeButton = (e) => {
    setPosts([]);
    setIsLoading(true);
    setType(e.target.value);

    e.target.classList.add('active');
    Array.from(document.querySelectorAll(`.type-btn`)).forEach(function(el) { 
      if(e.target != el){
        el.classList.remove('active');
      }
    })
    props.setSearchTerm({name:name, post_type: e.target.value});
  }

  const handleSearhPosts = (e) => {
    setPosts([]);
    setIsLoading(true);
    if(e.target.value == null ){
      setName(null);
    }else{
      setName(e.target.value);
    }

    props.setSearchTerm({name:e.target.value, post_type: type});

  }

  let primaryColor = [
    "#5cafe1",
    "#5a5387",
    "#019267",
    "#ed8141",

  ];

  return (
    <div className="explore-container">
      <ArrowLeft className="back-to-home" onClick={backToHome} />
      <div className="search-container">
        <input
          type="text"
          placeholder="Search"
          className="search-input"
          onChange={(e) => handleSearhPosts(e)}
        />
        <button className="search-btn">
          <Search />
        </button>
      </div>
      <center>
        <div className="btn-post-type">
            <button type="button" onClick={(e) => handlePostTypeButton(e)} value='book' className="btn type-btn btn-book"><img src={BookIcon} className="icon"/>Article</button>
            <button type="button" onClick={(e) => handlePostTypeButton(e)} value='video' className="btn type-btn btn-video"><img src={VideoIcon} className="icon"/>Video</button>
            <button type="button" onClick={(e) => handlePostTypeButton(e)} value='audio' className="btn type-btn btn-audio"><img src={AudioIcon} className="icon"/>Audio</button>
        </div>
      </center>
      {
        isLoading ? (
          <div
            style={{
              marginTop: "130px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <Loading color="#00A8FF" />
          </div>
        ) : (
            posts && posts
              .filter((val) => {
                if (props.searchTerm == "") {
                  return null;
                } else {
                  return val;
                }
              })
              .map((book, index) => (
                <div className="search-result-container" key={index}>
                  <div className="card-container">
                    <a onClick={() => {handleRedirectByUserSearch(book.id)}} style={{ textDecoration: "none", color: "black" }} >
                      <Row
                        style={{
                          width: "20rem",
                          height: "10rem",
                          backgroundColor: primaryColor[index % 4],
                        }}
                        className="book-card"
                      >
                        <Col className="col-12 filter-book-details ">
                          <div className="sub-container">
                            <p className="filter-book-date p-0 m-0">
                              {moment(book.published_date).startOf("hour").fromNow()}
                            </p>
                            {book.post_type === "book" ? (
                              <BookHalf
                                className="mx-2 text-white"
                                size={18}
                              />
                            ) : (
                              <PlayBtnFill
                                className="mx-2 text-white"
                                size={18}
                              />
                            )}
                          </div>
                          <h6 className="filter-book-title">{book.post_name}</h6>
                          <p className="filter-book-date">
                            {book.subcategory.sub_category_name}
                          </p>
                        </Col>
                        {/* <Col className="col-5  filter-img-container">
                          <img
                            src={IMAGE_BASE_URL + book.images}
                            className="filter-book-img"
                            alt="book-cover"
                          />
                        </Col> */}
                      </Row>
                    </a>
                  </div>
                </div>
              ))
          
        )
      }

    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    BookData: state.post.posts,
    user: state.phone.userData,
  };
};

export default connect(mapStateToProps, {
  increaseCount,
})(Explore);
