import axios from "axios";
import { BASE_URL } from "../../Common/constant";


export const FETCH_POSTS = "FETCH_POSTS";
export const FETCH_TRENDING_POSTS = "FETCH_TRENDING_POSTS";
export const FETCH_DETAIL_POST = "FETCH_DETAIL_POST";
export const FETCH_USER = "FETCH_USER";
export const EDIT_USER = "EDIT_USER";
export const FETCH_SAVED_POSTS = "FETCH_SAVED_POSTS";
export const GET_SAVED_POSTS = "GET_SAVED_POSTS";
export const SUBMIT_FEEDBACK = "SUBMIT_FEEDBACK"; 
export const LIMITED_POSTS = "LIMITED_POSTS";
export const UNSUBSCRIBE_PLAN = "UNSUBSCRIBE_PLAN";
export const FETCH_LIVE_POSTS = "FETCH_LIVE_POSTS";
export const FETCH_LEARN_POSTS = "FETCH_LEARN_POSTS";
export const FETCH_EARN_POSTS = "FETCH_EARN_POSTS";
export const FETCH_SPECIAL_POSTS = "FETCH_SPECIAL_POSTS";
export const CHECK_QUIZ = "CHECK_QUIZ";
export const LEADERBOARD_LIST = "LEADERBOARD_LIST";
export const USER_POSITION = "USER_POSITION";
export const USER_START_DATE = "USER_START_DATE";
export const GET_IMAGE_SLIDER = "GET_IMAGE_SLIDER";
export const RESET_POSTS = "RESET_POSTS";
export const RESET_SPECIAL_POSTS = "RESET_SPECIAL_POSTS";
export const RESET_LIVE_POSTS = "RESET_LIVE_POSTS";
export const RESET_LEARN_POSTS = "RESET_LEARN_POSTS";
export const RESET_EARN_POSTS = "RESET_EARN_POSTS";
export const RESET_HOME_POSTS = "RESET_HOME_POSTS";
export const RESET_EXPLORE_POSTS = "RESET_EXPLORE_POSTS";

export const LIVE_SUB_CATEOGORIES = "LIVE_SUB_CATEOGORIES";
export const LEARN_SUB_CATEOGORIES = "LEARN_SUB_CATEOGORIES";
export const EARN_SUB_CATEOGORIES = "EARN_SUB_CATEOGORIES";
export const SPECIAL_SUB_CATEOGORIES = "SPECIAL_SUB_CATEOGORIES";

export const LIVE_SUB_POSTS = "LIVE_SUB_POSTS";
export const GET_TERMS = "GET_TERMS";

//School & Courses
export const FETCH_COURSES = "FETCH_COURSES";
export const STORE_CODE = "STORE_CODE";
export const CHECK_CODE = "CHECK_CODE";
export const GET_CODES = "GET_CODES";
export const CANCEL_CODE = "CANCEL_CODE";

export function fetchSavedPost(postId, phone) {
  const data = {
    post_id: postId,
    phone: phone,
    action: "save",
  };
  return (dispatch) => {
    axios.post(`${BASE_URL}/savePost`, data, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token':'Bearer '+localStorage.getItem("token"),
        'auth': localStorage.getItem("authorization_code")
      }
    }).then((response) => {
      dispatch({
        type: FETCH_SAVED_POSTS,
        payload: response.data,
      });
    });
  };
}

export function getSavedPosts(page) {
  let phone = localStorage.getItem('phone');
  return (dispatch) => {
    axios.get(`${BASE_URL}/saved_posts/${phone}?pagesize=5&start=${page}`,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token':'Bearer '+localStorage.getItem("token"),
        'auth': localStorage.getItem("authorization_code")
      }
    }).then((response) => {
      dispatch({
        type: GET_SAVED_POSTS,
        payload: response.data,
      });
    });
  };
}

export function fetchPosts(category,subCategory,page,from,authorization_code) {
  return (dispatch) => {
    axios
      .get(`${BASE_URL}/post_with_sub_category/${subCategory}/${category}?pagesize=5&start=${page}`,{
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token':'Bearer '+localStorage.getItem("token"),
          'auth': localStorage.getItem("authorization_code")
        }
      })
      .then((response) => {
        if(from === 'live'){
          dispatch({
            type: FETCH_LIVE_POSTS,
            payload: response.data,
          });
        }
        else if(from === 'learn'){
          dispatch({
            type: FETCH_LEARN_POSTS,
            payload: response.data,
          });
        }
        else if(from === 'earn'){
          dispatch({
            type: FETCH_EARN_POSTS,
            payload: response.data,
          });
        }
        else if(from === 'specials'){
          dispatch({
            type: FETCH_SPECIAL_POSTS,
            payload: response.data,
          });
        }
      });
  };
}

export function fetchAllPosts(callback = () => {}) {
  return (dispatch) => {
    axios.get(`${BASE_URL}/posts`,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token':'Bearer '+localStorage.getItem("token"),
        'auth': localStorage.getItem("authorization_code")
      }
    }).then((response) => {
      dispatch({
        type: FETCH_POSTS,
        payload: response.data,
      });
      callback();
    });
  };
}

export function searchPosts(searchTerm) {
  return (dispatch) => {
    axios.get(`${BASE_URL}/search-by-post-type/${searchTerm.post_type}/${searchTerm.name}`,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token':'Bearer '+localStorage.getItem("token"),
        'auth': localStorage.getItem("authorization_code")
      }
    }).then((response) => {
      dispatch({
        type: FETCH_POSTS,
        payload: response.data,
      });
    });
  };
}

// export function trendingPosts(page,authorization_code) {
//   return (dispatch) => {
//     axios.get(`${BASE_URL}/trending_posts?pagesize=5&start=${page}`,{
//       headers: {
//         'Accept': 'application/json',
//         'Content-Type': 'application/json',
//         'token':'Bearer '+localStorage.getItem("token"),
//         'authorization_code':authorization_code,
//       }
//     }).then((response) => {
//       dispatch({
//         type: FETCH_TRENDING_POSTS,
//         payload: response.data,
//       });
//     });
//   };
// }

export function increaseCount(id) {
  return (dispatch) => {
    axios.get(`${BASE_URL}/increaseCount/${id}`,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token':'Bearer '+localStorage.getItem("token"),
        'auth': localStorage.getItem("authorization_code")
      }
    }).then((response) => {
      dispatch({
        type: FETCH_POSTS,
        payload: response.data,
      });
    });
  };
}

export function fetchDetailPost(id) {
  return (dispatch) => {
    axios.get(`${BASE_URL}/posts/${id}`,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token':'Bearer '+localStorage.getItem("token"),
        'auth': localStorage.getItem("authorization_code")
      }
    }).then((response) => {
      dispatch({
        type: FETCH_DETAIL_POST,
        payload: response.data,
      });
    });
  };
}

export function fetchUser(phone, from) {
  return (dispatch) => {
    axios.get(`${BASE_URL}/atom_user/${phone}/${from}`,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token':'Bearer '+localStorage.getItem("token"),
        'auth': localStorage.getItem("authorization_code")
      }
    }).then((response) => {
      dispatch({
        type: FETCH_USER,
        payload: response.data,
      });
    });
  };
}

export function editUser(user, phone) {
  const data = new FormData();
  data.append("date_of_birth", user.date_of_birth);
  data.append("photo", user.photo);
  data.append("phone", user.phone);
  data.append("email", user.email);
  data.append("name", user.name);
  data.append("job", user.job);
  data.append("point", user.point);
  data.append("status", user.status);

  return (dispatch) => {
    axios
      .post(`${BASE_URL}/atom_user/${phone}?_method=PUT`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token':'Bearer '+localStorage.getItem("token"),
          'auth': localStorage.getItem("authorization_code")
        },
      })
      .then((response) => {
        dispatch({
          type: EDIT_USER,
          payload: response.data,
        });
      });
  };
}



export function submitFeedback(feedback) {
  const data = new FormData();
  data.append("name", feedback.name);
  data.append("email", feedback.email);
  data.append("phone", feedback.phone);
  data.append("message_subject", feedback.message_subject);
  data.append("message", feedback.message);

  return (dispatch) => {
    axios
      .post(`${BASE_URL}/feedback`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token':'Bearer '+localStorage.getItem("token"),
          'auth': localStorage.getItem("authorization_code")
        },
      })
      .then((response) => {
        dispatch({
          type: SUBMIT_FEEDBACK,
          payload: response.data,
        });
      });
  };
}
//fetch limited post on scroll
export function limitedPosts(page,authorization_code, post_type) {
  return (dispatch) => {
    axios
      .get(`${BASE_URL}/filter_post?pagesize=5&start=${page}&post_type=${post_type}`,{
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token':'Bearer '+localStorage.getItem("token"),
          'auth': localStorage.getItem("authorization_code")
        }
      })
      .then((response) => {
        dispatch({
          type: LIMITED_POSTS,
          payload: response.data,
        });
      })
      .catch(err => {
        console.log(err)
      });
  };
}

export function unsubscribePlan(phone) {
  const data = new FormData();
  data.append("phone", phone);
  return (dispatch) => {
    axios
      .post(`${BASE_URL}/atom_user`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token':'Bearer '+localStorage.getItem("token"),
          'auth': localStorage.getItem("authorization_code")
        },
      })
      .then((response) => {
        // console.log(response);
        // localStorage.removeItem('authorization_code');
        // localStorage.clear();
        dispatch({
          type: UNSUBSCRIBE_PLAN,
          payload: response.data,
        });
      });
  };
}

export function checkQuiz(phone,post_id) {
  return (dispatch) => {
    axios
      .get(`${BASE_URL}/checkQuiz/${phone}/${post_id}`,{
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token':'Bearer '+localStorage.getItem("token"),
          'auth': localStorage.getItem("authorization_code")
        }
      })
      .then((response) => {
        dispatch({
          type: CHECK_QUIZ,
          payload: response.data,
        });
      });
  };
}

export function leaderboardlistfun(count,authorization_code) {
  return (dispatch) => {
    axios
      .get(`${BASE_URL}/leaderboard_list?pagesize=5&start=${count}`,{
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token':'Bearer '+localStorage.getItem("token"),
          'auth': localStorage.getItem('authorization_code')
        }
      })
      .then((response) => {
        dispatch({
          type: LEADERBOARD_LIST,
          payload: response.data,
        });
      });
  };
}

export function get_user_position(phone,authorization_code) {
  return (dispatch) => {
    axios
      .get(`${BASE_URL}/get_user_position/${phone}`,{
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token':'Bearer '+localStorage.getItem("token"),
          'auth': localStorage.getItem("authorization_code")
        }
      })
      .then((response) => {
        dispatch({
          type: USER_POSITION,
          payload: response.data,
        });
      });
  };
}

export function getStartDate(phone) {
  return (dispatch) => {
    axios
      .get(`${BASE_URL}/get_start_date1/${phone}`,{
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token':'Bearer '+localStorage.getItem("token"),
          'auth': localStorage.getItem("authorization_code")
        }
      })
      .then((response) => {
        dispatch({
          type: USER_START_DATE,
          payload: response.data,
        });
      });
  };
}

export function getImageSlider() {
  return (dispatch) => {
    axios
      .get(`${BASE_URL}/get_image_slider`,{
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token':'Bearer '+localStorage.getItem("token"),
          'auth': localStorage.getItem("authorization_code")
        }
      })
      .then((response) => {
        dispatch({
          type: GET_IMAGE_SLIDER,
          payload: response.data,
        });
      });
  };
}

export function getSubCategories(category_code,from){
  return (dispatch) => {
    axios.
      get(`${BASE_URL}/get_sub_category/${category_code}`,{
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token':'Bearer '+localStorage.getItem("token"),
          'auth': localStorage.getItem("authorization_code")
        }
      })
      .then((response) => {
        if(from === 'live'){
          dispatch({
            type: LIVE_SUB_CATEOGORIES,
            payload: response.data,
          });
        }else if(from == 'learn'){
          dispatch({
            type: LEARN_SUB_CATEOGORIES,
            payload: response.data,
          });
        }else if(from == 'earn'){
          dispatch({
            type: EARN_SUB_CATEOGORIES,
            payload: response.data,
          });
        }else if(from == 'specials'){
          dispatch({
            type: SPECIAL_SUB_CATEOGORIES,
            payload: response.data,
          });
        }
      });
  }
}

export function fetchPostsWithSubCategory(category,page,from,authorization_code,sub_category) {
  return (dispatch) => {
    axios
      .get(`${BASE_URL}/filter_with_sub_category/${category}/${sub_category}?pagesize=5&start=${page}`,{
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token':'Bearer '+localStorage.getItem("token"),
          'auth': localStorage.getItem("authorization_code")
        }
      })
      .then((response) => {
        if(from === 'live'){
          dispatch({
            type: LIVE_SUB_POSTS,
            payload: response.data,
          });
        }
      });
  };
}

export function fetchCourses(){
  return (dispatch) => {
    axios.get(`${BASE_URL}/course`,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token':'Bearer '+localStorage.getItem("token"),
        'auth': localStorage.getItem("authorization_code")
      }
    }).then((response) => {
      dispatch({
        type: FETCH_COURSES,
        payload: response.data
      })
    })
  }
}


export function storeCode(code , callback1,callback2) {

  const data = new FormData();
  data.append("phone", code.phone);
  data.append("point", code.point);
  data.append("course_id", code.course_id);

  return (dispatch) => {
    axios
      .post(`${BASE_URL}/code-store`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token':'Bearer '+localStorage.getItem("token"),
          'auth': localStorage.getItem("authorization_code")
        },
      })
      .then((response) => {
        dispatch({
          type: STORE_CODE,
          payload: response.data,
        });
        callback1(); 
        callback2(); 
      });
  };
}

export function checkCode(phone,course_id) {

  return (dispatch) => {
    axios
      .get(`${BASE_URL}/check-code/${phone}/${course_id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token':'Bearer '+localStorage.getItem("token"),
          'auth': localStorage.getItem("authorization_code")
        },
      })
      .then((response) => {
        dispatch({
          type: CHECK_CODE,
          payload: response.data,
        });
      });
  };
}

export function getSavedCodes(phone) {

  return (dispatch) => {
    axios
      .get(`${BASE_URL}/codes/${phone}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token':'Bearer '+localStorage.getItem("token"),
          'auth': localStorage.getItem("authorization_code")
        },
      })
      .then((response) => {
        dispatch({
          type: GET_CODES,
          payload: response.data,
        });
      });
  };
}

export function cancelCode(code, phone, callback) {
  const data = new FormData();
  data.append("code", code);
  data.append("phone", phone);

  return (dispatch) => {
    axios
      .post(`${BASE_URL}/cancel-code`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token':'Bearer '+localStorage.getItem("token"),
          'auth': localStorage.getItem("authorization_code")
        },
      })
      .then((response) => {
        dispatch({
          type: CANCEL_CODE,
          payload: response.data,
        });
        callback();
      });
  };
}

export function getTerms() {
  return (dispatch) => {
    axios
      .get(`${BASE_URL}/get-terms`, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token':'Bearer '+localStorage.getItem("token"),
          'auth': localStorage.getItem("authorization_code")
        },
      })
      .then((response) => {
        dispatch({
          type: GET_TERMS,
          payload: response.data,
        });
      });
  };
}
