import React from "react";
import "./notificationStyle.css";
// import {Row,Col} from 'react-bootstrap'
import { ArrowLeft } from "react-bootstrap-icons";
import NotificationComponent from "../../../components/Notification/NotificationComponent";
import { useNavigate } from "react-router";

const Notification = () => {
  const navigate = useNavigate();
  const NotificationData = [
    {
      id: 1,
      img: require("../../../assets/Notification-img/img-1.png"),
      NotificationText:
        "EveryDay English-French-Spanish:Conservation and Fun - Joe!",
      time: "9hours",
      primaryColor: "#00a8ff",
      secondaryColor: "#00a8ff",
      textColor: "#fff",
    },
    {
      id: 2,
      img: require("../../../assets/Notification-img/img-2.png"),
      NotificationText: "Assemblea Amici di Beppe Grillo Salerno",
      time: "11hours",
      primaryColor: "#00a8ff",
      secondaryColor: "#fff",
      textColor: "#fff",
    },

    {
      id: 3,
      img: require("../../../assets/Notification-img/img-3.png"),
      NotificationText: "Assemblea Amici di Beppe Grillo Salerno",
      time: "12hours",
      primaryColor: "#fff",
      secondaryColor: "#fff",
      textColor: "#000",
    },
    {
      id: 4,
      img: require("../../../assets/Notification-img/img-4.png"),
      NotificationText:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      time: "20hours",
      primaryColor: "#fff",
      secondaryColor: "#fff",
      textColor: "#000",
    },
  ];

  return (
    <>
      <div className="notification-header-container">
        <div className="notification-header-container">
          <div className="notification-header-inner-container">
            <a onClick={()=>{navigate("/profile")}}>
              <ArrowLeft
                style={{
                  fontSize: "20px",
                  color: "#757575",
                  marginLeft: "-10px",
                  marginTop: "-20px",
                }}
              />
            </a>
            <h2>Notification</h2>
          </div>
        </div>
      </div>
      <div style={{ marginBottom: "100px" }}>
        {NotificationData.map((data) => (
          <NotificationComponent
            key={data.id}
            text={data.NotificationText}
            time={data.time}
            img={data.img}
            primaryColor={data.primaryColor}
            secondaryColor={data.secondaryColor}
            textColor={data.textColor}
          />
        ))}
      </div>
    </>
  );
};

export default Notification;
