import React, { useState, useEffect } from "react";
import SaveBook from "../../components/SavedBook/SavedBook";
import "./SavedBookScreenStyle.css";
import { Row, Col } from "react-bootstrap";
import Loading from "../../components/Loading/Loading";
//Redux Action
import { connect } from "react-redux";
import { getSavedPosts } from "../../redux/Actions/postAction";
import { IMAGE_BASE_URL } from "../../Common/constant";
import UserImage from "../../assets/book-images/book6.png";
import ScrollLoading from "../../components/Loading/ScrollLoading";



const SavedBook = (props) => {
  const [isLoading, setIsLoading] = useState(true);

  // limited posts
  const [posts, setPosts] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [page, setPage] = useState(0);

  const { BookData, getSavedPosts } = props;

  const size = BookData.size;
  const data  = BookData.records;

  function getPost(){
    getSavedPosts(page);
    setIsFetching(false);
  }

  function getMorePosts() {
    setTimeout(() => {
      setPage(page + 5);
      getPost();
    }, 4000);
  }

  function handleScroll() {
    if (window.innerHeight !== document.documentElement.offsetHeight) {
      setIsFetching(true);
    }else{
      setIsFetching(false);
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    setPage(page + 5);
    getPost();
  }, []);

  useEffect(() => {
    if (!isFetching) {
      return;
    }else{
      if(page <= size){
        getMorePosts();
      }else{
        setIsFetching(false);
      }
    }
  }, [isFetching]);

  useEffect(() => {
    if(data !== undefined){
      if (data.length > 0 && data !== undefined) {
       setPosts([...posts, ...data]);
       setIsLoading(false);
     } else {
       setIsLoading(false);
     }}
  }, [data]);

  let primaryColor = ["#9770E6", "#8A56AC", "#352641"];
  let secondaryColor = ["#8A56AC", "#352641", "#9770E6"];

  return (
    <>
    <div className="saved_book_container">
      <div className="saved-book-header-container">
          <Row>
            <Col className="col-12 saved-book-inner-container">
              <h4>Saved</h4>
            </Col>
          </Row>
      </div>
      <div className="saved-book-list-container">
        {isLoading ? (
          <div
            style={{
              marginTop: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading color="#9770E6" />
          </div>
        ) : (
            posts.length > 0 ? (
              posts.map((book, index) => (
                <SaveBook
                  key={index}
                  bookID={book.post_id}
                  date={book.published_date}
                  title={book.post_name}
                  img={IMAGE_BASE_URL + book.images}
                  subTitle={book.subcategory.sub_category_name}
                  primaryColor={primaryColor[index % 3]}
                  secondaryColor={secondaryColor[index % 3]}
                  status={book.status}
                />
            ))
          ) : (
            <p className="m-2 h6">There is no saved item yet.</p>
          )
        )} 

        {isFetching && <ScrollLoading color="#00A8FF" />}
      </div>
    </div>

    </>
  );
};

const mapStateToProps = (state) => {
  return {
    BookData: state.post.saved_posts,
  };
};

export default connect(mapStateToProps, {
  getSavedPosts,
})(SavedBook);
