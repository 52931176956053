import React, { useState, useEffect } from "react";
import { ArrowLeft } from "react-bootstrap-icons";
import { useLocation, useNavigate } from "react-router";
import Moment from 'moment';

//redux
import { connect } from "react-redux";
import { getStartDate } from "../../../redux/Actions/postAction";

import CancelPlanModal from "../../../components/CancelPlanModal/CancelPlanModal";
import "./manageStyle.css";

const ManagePlan = (props, {route}) => {
    const [modalShow, setModalShow] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();


    let ph = localStorage.getItem("phone");
    const phone = ph.substring(2);

    const {start_date, getStartDate} = props;

    useEffect(() => {
      getStartDate(phone);
    }, []);
  
   return (
    <div className="manage-container">
      <div className="manage-main-container">
        <CancelPlanModal show={modalShow} onHide={() => setModalShow(false)} />
        <div className="top-container">
          <div className="top-inner-container">
            <a onClick={()=>{navigate("/profile")}}>
              <ArrowLeft
                style={{
                  fontSize: "25px",
                  color: "#757575",
                  marginLeft: "-10px",
                  marginTop: "-20px",
                }}
              />
            </a>
            <h2>Manage Plan</h2>
            <p style={{ fontSize: "12px", color: "#ccc" }}>
              Sometimes you just need to call it quits. We get it.
            </p>
          </div>
        </div>
        <div className="manage-bottom-container">
          <div className="bottom-inner-container">
            <h6 className="title">Your Plan</h6>
            <ul>
              <li>MMK 499 per week</li>
              <li>Suscribed since {start_date}</li>
            </ul>
          </div>

          <button className="cancel-btn" onClick={() => setModalShow(true)}>
            Cancel your plan
          </button>
        </div>
      </div>
    </div>

  );
};


const mapStateToProps = (state) => {
  return {
    start_date: state.post.start_date,
  };
};

export default connect(mapStateToProps, {
  getStartDate
})(ManagePlan);
