import React, { useEffect, useState, useRef } from "react";
import Tab from "../../components/nav/Tab";
import SpecialsBook from "../../components/SpecialsBook/SpecialsBook";
import Loading from "../../components/Loading/Loading";
import { connect } from "react-redux";
import { fetchPosts, getSubCategories } from "../../redux/Actions/postAction";
import { IMAGE_BASE_URL } from "../../Common/constant";
import ScrollLoading from "../../components/Loading/ScrollLoading";
import ReactGA from 'react-ga';
import { useDispatch } from "react-redux";
import { RESET_SPECIAL_POSTS } from "../../redux/Actions/postAction";

//filter 
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import '../../components/SpecialsBook/style.css';

const Specials = (props) => {
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch({
      type: RESET_SPECIAL_POSTS,
      payload: [],
    });
    // ReactGA.pageview(window.location.pathname + window.location.search);
  },[])
  
  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_path: window.location.pathname + window.location.search,
    });
  }, [window.location.pathname, window.location.search]);


  const ref = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isActive,setIsActive] = useState(false);

   // limited posts
   const [posts, setPosts] = useState([]);
   const [isFetching, setIsFetching] = useState(false);
   const [page, setPage] = useState(0); 
   const [subCategoryCode, setSubCategoryCode] = useState(null);


   const { BookData, fetchPosts ,user,SpecialSub, getSubCategories } = props;
   const size = BookData.size;
   const data  = BookData.records;

   const phone = user &&  user.user[0].phone;

   const auth_code = localStorage.getItem('authorization_code');

   function getPost(subCategory) {
    if(auth_code !== null){
      getSubCategories('C-0004','specials');
      fetchPosts("C-0004",subCategory,page,'specials',auth_code);
      setIsFetching(false);
    }
   }
 
   function getMorePosts() {
     setTimeout(() => {
       setPage(page + 5);
       getPost(subCategoryCode);
     }, 4000);
   }
 
   function handleScroll() {
    if (window.innerHeight !== document.documentElement.offsetHeight) {
      setIsFetching(true);
    }else{
      setIsFetching(false);
    }
   }

  //  When Scroll 
   useEffect(() => {
     window.addEventListener("scroll", handleScroll);
     return () => window.removeEventListener("scroll", handleScroll);
   }, []);
  //  When Scroll 

   // Initial Fetch
   useEffect(() => {
    if(auth_code !== null){
      if(subCategoryCode === null){
        getPost('all');
        setSubCategoryCode('all');
        setPage(5);
      }else{
        getPost(subCategoryCode);
        setPage(5);
      }
    }
  }, [subCategoryCode]);
   // Initial Fetch
 
   useEffect(() => {
     if (!isFetching) {
       return;
     } else {
       if(page <= size){
        getMorePosts();
       }else{
        setIsFetching(false);
       }
     }
   }, [isFetching]);
 
   useEffect(() => {
    if(data !== undefined){
      if (data.length > 0 && data !== undefined) {
       setPosts([...posts, ...data]);
       setIsLoading(false);
     } else {
       setIsLoading(false);
     }}
   }, [data]);

   useEffect(() => {
    if(SpecialSub !== undefined){
      if (SpecialSub.length > 0 && SpecialSub !== undefined) {
        setIsActive(false);
     } else {
       setIsActive(true);
     }}
   }, [SpecialSub]);

   const handleClick = (value,e) =>{ //when click sub category
    setPosts([]);
    setPage(0);
    setIsLoading(true);
    setSubCategoryCode(value);
    Array.from(document.querySelectorAll('.btn-slick')).forEach(function(el) { 
      el.classList.remove('active');
    });
    e.currentTarget.classList.toggle('active');
  }

  let primaryColor = ["#8861b4", "#5a5387",'#534465'];
  let secondaryColor = ["#5a5387", "#534465",'#8861b4'];

  //comment for furthur decision
  // let primaryColor = ["#bf03ee", "#d32ffc",'#df61fe','#e383fb','#eba0fe'];
  // let secondaryColor = ["#d32ffc",'#df61fe','#e383fb','#eba0fe',"#bf03ee"];

  const settings = {
    focusOnSelect: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    swipeToSlide: true,
    arrows : false,
    accessibility: false,
  };
  return (
    <>
      <Tab active="2px solid #5A5387" background="#5A5387" />

      <div className="specials_container">
        <div className="specials_main_container">
        {isActive ? (
            <ScrollLoading color="#00A8FF" />
          ) : (
          <div className="filter-container">
            <p className="text-center slick_title">life X Portal  ရဲ့ မိတ်ဖက် အဖွဲ့အစည်းများနှင့် စာရေးသူများ ရေးသားသော အထူး ဆောင်းပါးတိုများ၊ သုံးသပ်ချက်များ၊ ဗီဒီယိုများ၊ စာအုပ်အညွှန်းများ၊ စာအုပ် အနှစ်ချုပ်များကို Special ကဏ္ဍ တွင် ဖတ်ရှုနိုင်ပါမည်။ </p>
            <Slider className="slick-container" {...settings}>
              <div className="slick-item">
                <a className='btn btn-slick special-btn px-4 mx-1 active' ref={ref} onClick={(e) => handleClick('all',e)}>All</a>
              </div>
              {
                SpecialSub && SpecialSub.map(function(sub, index){
                  return (
                    <div className="slick-item" key={index}>
                      <a className='btn btn-slick special-btn px-4 mx-1'  onClick={(e) => handleClick(sub.sub_category_code,e)}>{sub.sub_category_name}</a>
                    </div>
                  );
                })
              }
            </Slider>
          </div>
          )}
          {isLoading ? (
            <div
              style={{
                marginTop: "130px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loading color="#00A8FF" />
            </div>
          ) : (
            <div>
              {posts.map((book, index) => (

                <SpecialsBook
                  key={index}
                  bookID={book.id}
                  date={book.published_date}
                  rate={book.rates.length > 0 ? book.rates[0].rate : 1}
                  title={book.post_name}
                  img={IMAGE_BASE_URL + book.images}
                  subTitle={book.subcategory.sub_category_name}
                  primaryColor={primaryColor[index % 3]}
                  secondaryColor={secondaryColor[index % 3]}
                  postType={book.post_type}
                  postSave={
                    book.post_save &&
                    book.post_save.phone == phone
                      ? "save"
                      : ""
                  }
                />
              )

              )}
              {isFetching && <ScrollLoading color="#00A8FF" />}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    BookData: state.post.special_posts,
    user: state.phone.userData,
    SpecialSub : state.post.special_sub,
  };
};

export default connect(mapStateToProps, {
  fetchPosts,
  getSubCategories
})(Specials);
