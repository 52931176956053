import React from "react";
import {
  X,
  ChevronRight,
  XCircleFill,
  CheckCircleFill,
} from "react-bootstrap-icons";
import { Container, Row, Col } from "react-bootstrap";
import "./MultipleChoiceStyle.css";
import { useNavigate } from "react-router-dom";
const MultipleChoice = (props) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="multiple-choice-main-container">
      <Container className="multiple-choice-container">
        <Row className="d-flex align-items-center">
          <Col className="col-12">
            <X className="multiple-choice-cancel-btn" onClick={goBack} />
          </Col>
          <Col className="col-12">
            <h3 className="multiple-choice-title">Quiz</h3>
          </Col>
        </Row>
      </Container>
      {/* /* {multiple choice section} */}

      <div className="checkbox-container">
        <Row>
          <Col className="col-12 d-flex justify-content-center align-items-center">
            <h5 className="multiple-choice-sub-title">
              {props.multiChoiceQuestion[0].question}
            </h5>
          </Col>
        </Row>
      </div>
      <div className="input-box-container">
        {props.multiChoiceQuestion[0].answers.map((answer) => (
          <Row className="d-flex align-items-center pt-2" key={answer.id}>
            <Col className="col-2 d-flex justify-content-end">
              <input
                type="checkbox"
                name={answer.name}
                className={props.multiInput}
                value={props.point}
                onChange={(e) => {
                    e.target.checked ? (
                      Array.from(document.querySelectorAll(`.${props.multiInput}`)).forEach(function(el) { 
                        if(e.target != el){
                          el.classList.add('disabled-click');
                        }
                      })
                    ) : (
                      Array.from(document.querySelectorAll(`.${props.multiInput}`)).forEach(function(el) { 
                        if(e.target != el){
                          el.classList.remove('disabled-click');
                        }
                      })
                    );
                    props.setPoint((point) =>
                      e.target.checked
                        ? point + answer.point
                        : point - answer.point
                    )
                  }

                }
              />
              <div className={props.answerCheck}>
                {answer.isCorrect === 0 ? (
                  <CheckCircleFill color="green" size={20} />
                ) : (
                  <XCircleFill color="red" size={20} />
                )}
              </div>
            </Col>
            <Col className="col-10">
              <label htmlFor="answer-1" className="checkbox-text">
                {answer.name}
              </label>
            </Col>
          </Row>
        ))}
      </div>
      <div className={props.done}>
        <button className="done-btn" onClick={props.handleCheckbox}>
          Submit
        </button>
      </div>

      <div className="d-flex justify-content-center align-items-center mt-3">
        <h5 className="quiz-sub-title m-0 px-1" onClick={props.handleNext}>
          {props.slideCount === props.index + 1
            ? "Point ရယူရန်"
            : "ဤမေးခွန်းအားကျော်ရန်"}
        </h5>
        <ChevronRight className="next-multiple-choice-icon" />
      </div>
    </div>
  );
};

export default MultipleChoice;
