import axios from "axios";
import { BASE_URL } from "../../Common/constant";
import { toast } from 'react-toastify';

export const SEND_PHONE = "SEND_PHONE";
export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_PHOTO = "FETCH_USER_PHOTO";
export const GET_AUTH_CODE = "GET_AUTH_CODE";
export const SAVE_POINT = "SAVE_POINT";
export const CHANNEL_LOG = "CHANNEL_LOG";
export const FETCH_USER_RENEW = "FETCH_USER_RENEW";
export const GET_TOKEN = "GET_TOKEN";
export const AuthorizationCode = "AuthorizationCode";

// Store User Phone 
export function sendPhone(data,callback = () => {}){
    return (dispatch) => {
        axios
        .post(`${BASE_URL}/atom_user_test`,data,{
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'token':'Bearer '+localStorage.getItem("token"),
            'auth': localStorage.getItem('authorization_code')
          }
        })
        .then((response) => {
          // return console.log(response);
            dispatch({
              type: SEND_PHONE,
              payload: response.data,
            });
            setTimeout(() => {
              callback();
            },3000);
          });
    }
}
// Store User Phone 


// Fetch User with Phone number 
export function fetchUser(phone,from,authorization_code) {
  return (dispatch) => {
    axios.get(`${BASE_URL}/atom_user/${phone}/${from}`,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token':'Bearer '+localStorage.getItem("token"),
        'auth': localStorage.getItem("authorization_code")
      }
    }).then( (response) => {
      dispatch({
        type: FETCH_USER,
        payload : response.data
      });
    });
  }
}
// Fetch User with Phone number

// Fetch User with HE Phone number 
export function fetchUserWithHE(HECode,phone,from,callback = () => {}) {
  var data = new FormData();
  data.append('HECode',HECode);
  return (dispatch) => {
    axios.post(`${BASE_URL}/atom_user/${phone}/${from}`,data,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token':'Bearer '+localStorage.getItem("token"),
        'auth': localStorage.getItem('authorization_code')
      }
    }).then( (response) => {
      dispatch({
        type: FETCH_USER,
        payload : response.data
      });
    });
    callback();
  }
}
// Fetch User with Phone number

// Fetch User with Phone number for ReNew
export function fetchUserRenew(phone,from) {
  return (dispatch) => {
    axios.get(`${BASE_URL}/atom_user/${phone}/${from}`,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token':'Bearer '+localStorage.getItem("token"),
        'auth': localStorage.getItem("authorization_code")
      }
    }).then( (response) => {
      dispatch({
        type: FETCH_USER_RENEW,
        payload : response.data
      });
    });
  }
}
// Fetch User with Phone number For Renew

//fetch user photo
export function fetchUserPhoto(phone, from) {
  return (dispatch) => {
    axios.get(`${BASE_URL}/atom_user/photo/${phone}/${from}`,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token':'Bearer '+localStorage.getItem("token"),
        'auth': localStorage.getItem("authorization_code")
      }
    }).then((response) => {
      dispatch({
        type: FETCH_USER_PHOTO,
        payload: response.data,
      });
    });
  };
}
//fetch user photo



// Save Points
export function savePoints(data) {
  return (dispatch) => {
    axios.post(`${BASE_URL}/savePoint`,data,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token':'Bearer '+localStorage.getItem("token"),
        'auth': localStorage.getItem("authorization_code")
      }
    }).then((response) => {
      dispatch({
        type: SAVE_POINT,
        payload: response.data,
      });
    });
  };
}
// Save Points

// Channel Log
export function channelLog(url_type) {
  var data = new FormData();
  data.append("url_type", url_type);
  return (dispatch) => {
    axios.post(`${BASE_URL}/channel_log`, data, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token':'Bearer '+localStorage.getItem("token"),
        'auth': localStorage.getItem("authorization_code")
      }
    }).then((response) => {
      dispatch({
        type: CHANNEL_LOG,
        payload: response.data,
      });
    });
  };
}
// Channel Log

// Get Token
export function getToken(authorization_code,from, callback = () => {}) {
  var data = new FormData();
  data.append('authorization_code',authorization_code);
  data.append('from',from);
  return (dispatch) => {
    axios.post(`${BASE_URL}/generateLifeXToken`,data).then((response) => {
      localStorage.setItem('token',response.data.token);
      callback();
    });
  };
}
// Get Token

// Get Authorization
export function getAuthorizationCode(phone,from) {
  var data = new FormData();
  data.append('phone',phone);
  data.append('from',from);
  data.append('data','l!feXp@r@byt3');
  return (dispatch) => {
    axios.post(`${BASE_URL}/authorization_code`,data).then((response) => {
      localStorage.setItem('authorization_code',response.data.authorization_code);
      dispatch({
        type: AuthorizationCode,
        payload: response.data.authorization_code,
      });
    });
  };
}
// Get Authorization

