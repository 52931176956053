import React, { useContext, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import "./style.css";
import SpecialImage from "../../assets/about-lifex/special.png";

// //tab icon svg import

import { Award, Book, Heart, Star } from "react-bootstrap-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../useContext/useContext";
import { IMAGE_BASE_URL } from "../../Common/constant";
import UserImage from "../../assets/Avatar/person.png";

//redux
import { fetchUserPhoto } from "../../redux/Actions/userAction";
import { connect } from "react-redux";

const Tab = (props) => {
  let location = useLocation();
  let path = location.pathname;
  const navigate = useNavigate();
  let ph_no = localStorage.getItem("phone");

  useEffect(() => {
    fetchUserPhoto(ph_no, "fromUser");
  }, []);

  const { user, fetchUserPhoto } = props;

  function handleScroll() {
    var tabContainer = document.getElementById("scroll-container");
    if (path === "/live") {
      tabContainer.scrollLeft += 100;
    } else if (path === "/learn") {
      tabContainer.scrollLeft += 150;
    } else if (path === "/earn") {
      tabContainer.scrollLeft += 200;
    }
  }

  useEffect(() => {
    handleScroll();
  }, []);

  return (
    <div
      className="nav-container"
      // style={{ backgroundColor: props.background }}
    >
      <span className="bgcolor" 
            // style={{
            //   backgroundColor: props.background,
            // }}
      ></span>
      <Row className="pt-3 tab-container" id="scroll-container">
        {/* Profile tab    */}
        <Col className="d-flex justify-content-center align-items-center flex-column col-3 m-2 profile_tab">
          <a
            onClick={() => {
              navigate("/profile");
            }}
          >
            <img
              // src={`${IMAGE_BASE_URL}${user && user.user[0].photo}`}
              src={UserImage}
              alt="profile"
              className="profile-img"
              onError={(e) => {
                if (e.target.src !== "image_path_here") {
                  e.target.onerror = null;
                  e.target.src = UserImage;
                }
              }}
            />
          </a>

          <p className="profile-name icon-text">You</p>
        </Col>

        {/* live tab    */}
        <Col className="d-flex justify-content-center align-items-center flex-column col-3 m-2">
          {/* <a onClick={() => { navigate('/live')}}> */}
          <a
            onClick={() => {
              navigate("/live");
            }}
          >
            <Heart
              className="tab-icon"
              style={
                path === "/live"
                  ? { border: props.active, color: "#019267" }
                  : { color: "#707070" }
              }
            />
          </a>
          <p
            className="profile-name icon-text"
            style={path === "/live" ? { fontWeight: 600 } : null}
          >
            Live
          </p>
        </Col>

        {/* learn tab    */}
        <Col className="d-flex justify-content-center align-items-center flex-column col-3 m-2">
          {/* <a onClick={() => { navigate('/learn')}}> */}
          <a
            onClick={() => {
              navigate("/learn");
            }}
          >
            <Book
              className="tab-icon"
              style={
                path === "/learn"
                  ? { border: props.active, color: "#fc7321" }
                  : { color: "#707070" }
              }
            />
          </a>
          <p
            className="profile-name icon-text"
            style={path === "/learn" ? { fontWeight: 600 } : null}
          >
            Learn
          </p>
        </Col>

        {/* earn tab    */}
        <Col className="d-flex justify-content-center align-items-center flex-column col-3 m-2">
          {/* <a onClick={() => { navigate('/earn')}}> */}
          <a
            onClick={() => {
              navigate("/earn");
            }}
          >
            <Award
              className="tab-icon"
              style={
                path === "/earn"
                  ? { border: props.active, color: "#00b4d8" }
                  : { color: "#707070" }
              }
            />
          </a>
          <p
            className="profile-name icon-text"
            style={path === "/earn" ? { fontWeight: 600 } : null}
          >
            earn
          </p>
        </Col>

        {/* special tab    */}
        <Col className="d-flex justify-content-center align-items-center flex-column col-3 m-2">
          {/* <a onClick={() => { navigate('/trending')}}> */}
          <a
            onClick={() => {
              navigate("/specials");
            }}
          >
            <Star
              className="tab-icon"
              style={
                path === "/specials"
                  ? { border: props.active, color: "#5A5387" }
                  : { color: "#5A5387" }
              }
            />
          </a>
          <p
            className="profile-name icon-text"
            style={path === "/specials" ? { fontWeight: 600 } : null}
          >
            Specials
          </p>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.phone.userPhoto,
  };
};

export default connect(mapStateToProps, {
  fetchUserPhoto,
})(Tab);
