import React from "react";
import Modal from "react-bootstrap/Modal";
import { connect, useDispatch } from "react-redux";
import "./CancelPlanStyle.css";
import { unsubscribePlan } from "../../redux/Actions/postAction";

import { toast } from 'react-toastify';
import { ToastContainer , Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from "react-router";

const CancelPlanModal = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  const UnsubscribeHandler = () => {
    let phone = localStorage.getItem('phone');
    // phone = phone.substring(2);
    // console.log(phone);
    dispatch(unsubscribePlan(phone));    
    toast.error("Unsubscribed Successfully!", {
      position: toast.POSITION.TOP_CENTER,
      draggablePercent: 70
    });
    props.onHide(true);
    setTimeout(() => {
      navigate('/',{state:"unsubscribe"});
    }, 1000);
    // navigate('/',{state:"unsubscribe"});
    // console.log(location.state);
  }
  return (
    <div className="cancel-modal-main-container">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="cancel-modal"
      >
        <Modal.Header className="cancel-modal-header">
          <Modal.Title className="cancel-modal-title">
            Unsubscribed!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="cancel-modal-body bg-white">
          <p>If you Unsubscribe, you will stop receiving our services.</p>
        </Modal.Body>
        <Modal.Footer className="cancel-modal-footer">
          <button onClick={props.onHide} className="cancel-modal-btn">
            Cancel
          </button>
          <button onClick={() => UnsubscribeHandler()} className="unsubscribe-modal-btn">
            Unsubscribe
          </button>
        </Modal.Footer>
      </Modal>
      <ToastContainer  transition={Slide} autoClose={1000} />
    </div>
  );
};


export default CancelPlanModal
