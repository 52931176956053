import React, { useState, useEffect } from "react";
import "./sliderStyle.css";
import Carousel from "react-bootstrap/Carousel";
import { Save, X } from "react-bootstrap-icons";
import { Markup } from "interweave";
import { IMAGE_BASE_URL } from "../../Common/constant";
import ProgressBar from "react-bootstrap/esm/ProgressBar";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import {checkQuiz} from '../../redux/Actions/postAction';
import Swal from 'sweetalert2';

const Slider = (props) => {
  const navigate = useNavigate();
  var bookimage = props.book.length > 0 && props.book[0].book_cover;

  let ph = localStorage.getItem("phone");
  const phone = ph.substring(2);

  const id = props.book[0].id;
  useEffect(() => {
    checkQuiz(phone,id);
  }, []);

  const { quizSave, checkQuiz } = props;

  const quiz_data = quizSave ? quizSave.data : null; 
  const is_quiz_answered = quiz_data ? quiz_data.length != 0 ?? true  : false;

  const handleYes = () => {
    if(is_quiz_answered){
      Swal.fire({
        icon: 'error',
        confirmButtonColor: "#888",
        title: '<h5>You have already answered the quiz</h5>',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        },
      }).then((confirm) => {
        navigate(-1);
      });
    }else{
      navigate("/quest/" + props.book[0].id);
    }

  };

  const [index, setIndex] = useState(0);

  var quiz = props.book[0].quizzes[0].question;

  var step = index;
  var totalSteps = props.word.length;
  var percent = (step / totalSteps) * 100;
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <div className="slider-main-container">
        <div className="progress-bar-container">
          <X className="slider-x-icon" onClick={props.goBack} />
          <ProgressBar now={percent} variant="info" className="progress-bar" />
        </div>
        <div className="slider-container">
          <div className="slider-wrapper">
            <Carousel
              slide={true}
              interval={null}
              data-wrap={null}
              wrap={null}
              activeIndex={index}
              onSelect={handleSelect}
            >
              {props.book[0].book_cover_type === "half" ? (
                <Carousel.Item>
                  <div className="my-card-cover">
                    <div className="my-card-cover-img">
                      <img
                        alt="book-cover"
                        className="book-cover-half"
                        src={`${IMAGE_BASE_URL}${bookimage}`}
                      />
                    </div>
                    <div className="book-cover-details">
                      <h4>{props.book[0].post_name}</h4>
                      <p>{props.book[0].subcategory.sub_category_name}</p>
                    </div>
                  </div>
                </Carousel.Item>
              ) : (
                <Carousel.Item>
                  <div className="my-card-cover">
                    <div className="my-card-cover-img-full">
                      <img
                        alt="book-cover"
                        className="book-cover-full"
                        src={`${IMAGE_BASE_URL}${bookimage}`}
                      />
                    </div>
                  </div>
                </Carousel.Item>
              )}
              {props.word &&
                props.word.map((book, i) => (
                  <Carousel.Item key={i}>
                    <div
                      className="my-card"
                      style={{
                        color: "#000",
                      }}
                    >
                      <div
                        className="book-text"
                        style={{
                          fontWeight: "400",
                        }}
                      >
                        <Markup content={book} />
                      </div>
                    </div>
                  </Carousel.Item>
                ))}
              {quiz && quiz !== null ? (
                <Carousel.Item>
                  <div className="my-card">
                    <div className="quiz-container">
                      <h6 className="quiz-yes-no-title text-center">
                        လေ့လာခဲ့တာတွေကို ဘယ်‌လောက်မှတ်မိလဲဆိုတာ Quiz
                        လေးတွေဖြေပြီး စမ်းကြည့်ရအောင်
                      </h6>
                      <div className="yes-no-btn-container">
                        <button
                          className="goto-quiz-btn mpx-2 bg-info "
                          onClick={handleYes}
                          style={{ zIndex: 10 }}
                        >
                          Yes
                        </button>
                        <button
                          className="goto-quiz-btn mx-2 bg-danger"
                          onClick={props.goBack}
                          style={{ zIndex: 10 }}
                        >
                          No
                        </button>
                      </div>
                      </div>
                      </div>
                    </Carousel.Item>
                  ) : (
                    null
                  )
                }
              
            </Carousel>
          </div>
        </div>
      </div>
      
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    quizSave : state.post.quiz_save,
  };
};

export default connect(mapStateToProps, {
  checkQuiz,
})(Slider);
