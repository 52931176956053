import React , {useEffect, useState} from "react";
import "./UserPointStyle.css";
import { useNavigate,useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { savePoints } from "../../redux/Actions/userAction";
import { toast } from "react-toastify";

const UserPoint = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [ navigateAfterSave , setNavigateAfterSave ] = useState(false);
  const {savePoints , pointResponse} = props;
  const points = state.multipoint + state.yesNoPoint + state.imagePoint
  const phone = localStorage.getItem('phone');
  const handleSavePoint = (e) => {
    e.currentTarget.disabled = true;
    var data = new FormData();
    data.append('point' , points);
    data.append('userPhone' , phone);
    data.append('post_id' , state.postData[0].id);
    savePoints(data);
    setNavigateAfterSave(true);
  };  
  useEffect(() => {
    if(pointResponse !== null && navigateAfterSave === true){
      if(pointResponse.status === 'success'){
        toast.success("Successfully Submit !", {
          position: toast.POSITION.TOP_CENTER,
          draggablePercent: 100,
          onClose: () => navigate('/rating')
        });
      }else if(pointResponse.status === 'error'  && navigateAfterSave === true){
        toast.error("Point Save Fail", {
          position: toast.POSITION.TOP_CENTER,
          draggablePercent: 70,
          onClose: () => navigate('/live/book/points')
        });
      }
    }
  }, [pointResponse]);
  return (
    <div className="user-point-container">
      <h6>Great Job</h6>
      <div className="point-container">
        <img
          src={require("../../assets/about-lifex/lifexbadge.png")}
          alt="lifex-award"
          className="lifex-award-img"
        />
        {
          points == 0 ? (
            <p>
              Whoops! Sorry!
              You are giving incorrect answer. You have won lifex 0 point.
            </p>
          ) : 
          ( <p>
              Congratulation! You complete a lesson and you have won lifeX{" "}
              <span>{points}</span> points
            </p>)
        }

        <button className="point-btn" onClick={handleSavePoint}>
          သိမ်းဆည်းမည်
        </button>
      </div>
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    pointResponse : state.phone.points
  };
};

export default connect(mapStateToProps, {
  savePoints,
})(UserPoint);
