import React, { useEffect, useState, useRef } from "react";
import LiveBook from "../../components/LiveBook/LiveBook";
import Tab from "../../components/nav/Tab";
import { IMAGE_BASE_URL } from "../../Common/constant";
import Loading from "../../components/Loading/Loading";
import ScrollLoading from "../../components/Loading/ScrollLoading";

//filter
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

//Redux Action
import { connect } from "react-redux";
import { fetchPosts, getSubCategories } from "../../redux/Actions/postAction";
import { useDispatch } from "react-redux";
import { getAuthorizationCode } from "../../redux/Actions/userAction";
import '../../components/LiveBook/livestyle.css';
import { RESET_LIVE_POSTS } from "../../redux/Actions/postAction";
import ReactGA from 'react-ga';

const Live = (props) => {
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch({
      type: RESET_LIVE_POSTS,
      payload: [],
    });
    // ReactGA.pageview(window.location.pathname + window.location.search);
  })

  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_path: window.location.pathname + window.location.search,
    });
  }, [window.location.pathname, window.location.search]);
  
  const ref = useRef(null);

  let primaryColor = ["#05c68f", "#019267", "#01704f"];
  let secondaryColor = ["#019267", "#01704f", "#05c68f"];

  const [isLoading, setIsLoading] = useState(false);
  const [isActive,setIsActive] = useState(false);
  
  // limited posts
  const [liveposts, setLivePosts] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [page, setPage] = useState(0);
  const [subCategoryCode,setSubCategoryCode] = useState(null);

  const { BookData,  fetchPosts, LiveSub , getSubCategories} = props;

  const size = BookData.size;
  const data  = BookData.records;

  let ph = localStorage.getItem('phone');
  const phone = ph.substring(2);

  const auth_code = localStorage.getItem('authorization_code');

  function getPost(subCategory) {
    if(auth_code !== null){
      getSubCategories("C-0001", 'live');
      fetchPosts("C-0001",subCategory,page,'live',auth_code);
      setIsFetching(false);
    }
  }

  function getMorePosts() {
    setTimeout(() => {
      setPage(page + 5);
      getPost(subCategoryCode);
    }, 4000);
  }

  function handleScroll() {
    if (window.innerHeight !== document.documentElement.offsetHeight) {
      setIsFetching(true);
    }else{
      setIsFetching(false);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);


  useEffect(() => {
    if(auth_code !== null){
      if(subCategoryCode === null){
        getPost('all');
        setSubCategoryCode('all');
        setPage(5);
      }else{
        getPost(subCategoryCode);
        setPage(5);
      }
    }
  }, [subCategoryCode]);

  useEffect(() => {
    if (!isFetching) {
      return;
    }else{
      if(page <= size){
        getMorePosts();
      }else{
        setIsFetching(false);
      }
    }
  }, [isFetching]);

  useEffect(() => {
    if(data !== undefined){
      if (data.length > 0 && data !== undefined) {
       setLivePosts([...liveposts, ...data]);
       setIsLoading(false);
     } else {
       setIsLoading(false);
     }}
   }, [data]);

   useEffect(() => {
    if(LiveSub !== undefined){
      if (LiveSub.length > 0 && LiveSub !== undefined) {
        setIsActive(false);
     } else {
       setIsActive(true);
     }}
   }, [LiveSub]);


   const settings = {
      focusOnSelect: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      variableWidth: true,
      swipeToSlide: true,
      arrows : false,
      accessibility: false,
  };

  const handleClick = (value,e) =>{ //when click sub category
    setLivePosts([]);
    setPage(0);
    setIsLoading(true);
    setSubCategoryCode(value);
    Array.from(document.querySelectorAll('.btn-slick')).forEach(function(el) { 
      el.classList.remove('active');
    });
    e.currentTarget.classList.toggle('active');
  }

  return (
    <>
      <Tab active="2px solid #05c78d" background="#05c78d" />
      <div className="live_container">
        <div className="live_main_container">
          {isActive ? (
            <ScrollLoading color="#00A8FF" />
          ) : (
            <div className="filter-container">
              <p className="text-center slick_title">ကာယ၊ စိတ္တသုခ ဖြင့် ပြည့်စုံသည့် လူနေမှု ဘဝကို ရရှိနိုင်ဖို့ သင့်ရဲ့ စားသောက် နေထိုင်မှု ပုံစံကို စနစ်တကျ ဖြစ်အောင် ပြုပြင်ပေးခြင်းဖြင့် ကိုယ်တိုင် ဖန်တီး ရယူနိုင်ပါသည်။ ဤအတွက် ကျင့်သုံးလိုက်နာဖွယ် နည်းလမ်းများကို Live ကဏ္ဍတွင် ဖတ်ရှုနိုင်ပါသည်။</p>
              <Slider className="slick-container" {...settings}>
                <div className="slick-item">
                  <a className='btn btn-slick live-btn px-4 mx-1 active' ref={ref} onClick={(e) => handleClick('all',e)}>All</a>
                </div>
                {
                  LiveSub && LiveSub.map(function(sub, index){
                    return (
                      <div className="slick-item" key={index}>
                        <a className='btn btn-slick live-btn px-4 mx-1'  onClick={(e) => handleClick(sub.sub_category_code,e)}>{sub.sub_category_name}</a>
                      </div>
                    );
                  })
                }
              </Slider>
            </div>
          ) }

          {isLoading ? (
            <div
              style={{
                marginTop: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loading color="#E6D153" />
            </div>
          ) : (
            liveposts.map((book, index) => (
              <LiveBook
                key={index}
                bookID={book.id}
                count={book.count}
                date={book.published_date}
                rate={book.rates.length > 0 ? book.rates[0].rate : 1}
                title={book.post_name}
                img={IMAGE_BASE_URL + book.images}
                book_cover={IMAGE_BASE_URL + book.book_cover}
                subTitle={book.subcategory.sub_category_name}
                primaryColor={primaryColor[index % 3]}
                secondaryColor={secondaryColor[index % 3]}
                postType={book.post_type}
                postSave={
                  book.post_save &&
                  book.post_save.phone == phone
                    ? "save"
                    : ""
                }
              />
            ))
          )}
          {isFetching && <ScrollLoading color="#00A8FF" />}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    BookData: state.post.live_posts,
    LiveSub : state.post.live_sub,
  };
};

export default connect(mapStateToProps, {
  fetchPosts,
  getAuthorizationCode,
  getSubCategories,
})(Live);
