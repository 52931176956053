import Home from "../views/HomeScreen/Home";
import ExploreScreen from "../views/ExploreScreen/ExploreScreen";
import SavedBookList from "../views/Saved/SavedBookScreen";
import Learn from "../views/LearnScreen/Learn";
import Earn from "../views/EarnScreen/Earn";
import Live from "../views/LiveScreen/Live";
import Profile from "../views/Profile/Profile";
import EditProfile from "../views/Profile/EditProfileScreen/EditProfile";
import ManagePlan from "../views/Profile/ManagePlan/ManagePlan";
import Setting from "../views/Profile/Settings/Setting";
import Help from "../views/Profile/Help/Help";
import Notification from "../views/Profile/Notification/Notification";
import BookSlider from "../views/BookSlider/BookSlider";
import ImageQuizScreen from "../views/ImageQuizScreen/ImageQuizScreen";
import MultipleChoiceScreen from "../views/MultipleChoiceScreen/MultipleChoiceScreen";
import StartFreeTrialScreen from "../views/StartFreeTrial/StartFreeTrialScreen";
import AboutLifexScreen from "../views/AboutLifexScreen/AboutLifexScreen";
import YesNoQuizScreen from "../views/YesNoQuizScreen/YesNoQuizScreen";
import UserPointScreen from "../views/UserPointScreen/UserPointScreen";
import PostQuestion from "../views/Question/question";
import GetPhoneScreen from "../components/UserPhone/GetPhoneScreen";
import Index from "../components/Index";
import RenewScreen from "../views/RenewScreen/RenewScreen";
import Feedback from "../views/Profile/Settings/Feedback";
import ThankyouFeedback from "../views/Profile/Settings/ThankyouFeedback";
import AboutLifeX from "../views/Profile/Settings/AboutLifeX/AboutLifeX";
import FAQ from "../views/Profile/Help/FAQ/FAQ";
import CSupport from "../views/Profile/Help/CSupport/CSupport";
import ConfirmScreen from "../components/Confirm/ConfirmScreen";
import Specials from "../views/SpecialScreen/Specials";
// import maintainance from "../views/Maintainance";
import AudioScreen from "../components/AudioScreen/AudioScreen";
import RatingScreen from "../components/Rating/RatingScreen";
import CourseScreen from "../components/CourseScreen/CourseScreen";
import CourseDetail from "../components/Course/CourseDetail";
import Terms from "../components/Course/Terms";
import GetPhoneScreenTest from "../components/UserPhone/GetPhoneScreenTest";
import CGCallback from "../components/CGCallback/CGCallback";

const allRoutes = [
  // {path:'/', component : maintainance},
  { path: "/renewscreen", component: RenewScreen },
  { path: "/startfree/about_lifex", component: AboutLifexScreen },
  { path: "/startfree", component: StartFreeTrialScreen },
  { path: "/", component: Index },
  { path: "/home", component: Home },
  { path: "/explore", component: ExploreScreen },
  { path: "/saved_booklist", component: SavedBookList },
  { path: "/profile", component: Profile },
  { path: "/specials", component: Specials },
  { path: "/live", component: Live },
  { path: "/learn", component: Learn },
  { path: "/earn", component: Earn },
  { path: "/quest/:id", component: PostQuestion },
  { path: "/profile/edit_profile", component: EditProfile },
  { path: "/profile/manage_plan", component: ManagePlan },
  { path: "/profile/settings", component: Setting },
  { path: "/profile/settings/feedback", component: Feedback },
  { path: "/profile/settings/aboutlifex", component: AboutLifeX },
  { path: "/profile/settings/thankyoufeedback", component: ThankyouFeedback },
  { path: "/profile/help", component: Help },
  { path: "/profile/help/faq", component: FAQ },
  { path: "/profile/help/c_support", component: CSupport },
  { path: "/profile/notification", component: Notification },
  { path: "/home/book/:id", component: BookSlider },
  { path: "/live/book/:id", component: BookSlider },
  { path: "/learn/book/:id", component: BookSlider },
  { path: "/earn/book/:id", component: BookSlider },
  { path: "/specials/book/:id", component: BookSlider },
  { path: "/live/book/image_quiz", component: ImageQuizScreen },
  { path: "/live/book/multiple_choice", component: MultipleChoiceScreen },
  { path: "/live/book/yesno_quiz", component: YesNoQuizScreen },
  { path: "/live/book/points", component: UserPointScreen },
  { path: "/user/phone", component: GetPhoneScreen },
  { path: "/user/phone/test", component: GetPhoneScreenTest },
  { path: "/confirm", component: ConfirmScreen },
  { path: "/rating", component: RatingScreen },
  { path: "/audio", component: AudioScreen },
  { path: "/course", component: CourseScreen },
  { path: "/course/detail", component: CourseDetail },
  { path: "/course/detail/T&C", component: Terms },
  { path: "/sdp_redirect_url", component: CGCallback},
];

export default allRoutes;
