import React from "react";
import {
  ArrowLeft,
  EnvelopeCheckFill,
  PhoneVibrateFill,
} from "react-bootstrap-icons";
import "./CSupportStyle.css";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router";

const CSupport = () => {
  const navigate = useNavigate();
  return (
    <div className="support-container" style={{ overflow: "hidden" }}>
      <div className="support-main-container">
        <div className="mt-3 d-flex  align-items-center">
          <a
            onClick={() => {navigate("/profile/help")}}
            // href="/profile/help"
            style={{ textDecoration: "none", color: "#000" }}
          >
            <ArrowLeft size={24} className="mx-3" />
          </a>
          <h6
            className="my-0 p-0"
            style={{
              fontFamily: "Montserrat",
              marginLeft: "15%",
              fontSize: "14px",
              textAlign: "left",
            }}
          >
            Customer Support
          </h6>
        </div>
        <div className="support-image-container">
          <img
            src={require("../../../../assets/Notification-img/support1.png")}
            alt="support-icon"
          />
        </div>
        <div className="support-card-container">
          <Card
            style={{
              width: "15rem",
              padding: "10px 0px",
              backgroundColor: "#1a3d50",
            }}
            className="support-card"
          >
            <EnvelopeCheckFill
              style={{ margin: "auto" }}
              size={25}
              color="#00a8ff"
            />
            <Card.Body>
              <Card.Title
                style={{
                  fontSize: "14px",
                  margin: "auto",
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                Send Us Mail
              </Card.Title>
              <Card.Text
                style={{
                  fontSize: "12px",
                  margin: "auto",
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                support@parabyte.media
              </Card.Text>
            </Card.Body>
          </Card>
          <Card
            style={{
              width: "15rem",
              padding: "10px 0px",
              backgroundColor: "#1a3d50",
            }}
            className="support-card"
          >
            <PhoneVibrateFill
              style={{ margin: "auto" }}
              size={25}
              color="#00a8ff"
            />
            <Card.Body>
              <Card.Title
                style={{
                  fontSize: "14px",
                  margin: "auto",
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                Call Us
              </Card.Title>
              <Card.Text
                style={{
                  fontSize: "12px",
                  margin: "auto",
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                09 769 028 157
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CSupport;
