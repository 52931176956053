import React from "react";
import "./imageQuizStyle.css";
import {
  X,
  ChevronRight,
  CheckCircleFill,
  XCircleFill,
} from "react-bootstrap-icons/dist";
import { Container, Row, Col } from "react-bootstrap";
import { queryAllByAttribute } from "@testing-library/react";
import { IMAGE_BASE_URL } from "../../Common/constant";
import { useNavigate } from "react-router-dom";
const ImageQuiz = (props) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const { isCorrect } = props;
  return (
    <div className="image-quiz-main-container">
      <Container className="image-quiz-container">
        <Row className="d-flex align-items-center mb-5">
          <Col className="col-3">
            <X className="quiz-cancel-btn" onClick={goBack} />
          </Col>
          <Col className="col-9 ml-3">
            <h6 className="quiz-title">Muliple choice</h6>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col className="col-12">
            <h5 className="quiz-sub-title">{props.imageQuest[0].question}</h5>
          </Col>
        </Row>

        {/* /* {image section} */}

        <Row className="mb-5 d-flex justify-content-center">
          {props.imageQuest[0].answers.map((q, i) => (
            <Col className="col-5 my-3" key={i}>
              <div className="quiz-image-container">
                <div className={isCorrect["m" + (i + 1)]}>
                  {q.isCorrect === 0 ? (
                    <CheckCircleFill
                      color="green"
                      className="true-false-icon"
                    />
                  ) : (
                    <XCircleFill color="red" className="true-false-icon" />
                  )}
                </div>
                <img
                  id={"m" + (i + 1)}
                  src={IMAGE_BASE_URL + q.images}
                  alt="quiz"
                  data-id={q.point}
                  onClick={(event) =>
                    props.isCorrectHandler(event, q.isCorrect, q.point)
                  }
                  iscorrect={q.isCorrect}
                />
              </div>
            </Col>
            
          ))}
        </Row>
      </Container>
      <div className="d-flex justify-content-center align-items-center mt-5">
        <h5 className="quiz-sub-title m-0 px-1" onClick={props.nextQuizHandler}>
          {props.slideCount === props.index + 1
            ? "Point ရယူရန်"
            : "ဤမေးခွန်းအားကျော်ရန်"}
        </h5>
        <ChevronRight className="next-quiz-icon" />
      </div>
    </div>
  );
};

export default ImageQuiz;
