import React, { useState, useEffect } from "react";
///  importing routes
import {
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import AllRoutes from "./router/allRoute";

//css
import "./App.css";

//useContext
import { UserContext, UserPhoneContext } from "./useContext/useContext";

//Redux
import store from "./redux/Store/configureStore";
import { Provider } from "react-redux";

//Toastify
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//google analytic
import ReactGA from "react-ga";

//Components
import Index from "./components/Index";
import GetPhoneScreen from "./components/UserPhone/GetPhoneScreen";
import StartFreeTrialScreen from "./views/StartFreeTrial/StartFreeTrialScreen";
import ErrorPage from "./components/GuardRoute/ErrorPage";
import FooterScreen from "./views/FooterScreen/FooterScreen";
import GetPhoneScreenTest from "./components/UserPhone/GetPhoneScreenTest";
import Maintenance from "./components/Maintenance/Maintenance";

const App = () => {
  const [subscribe, setSubscribe] = useState(false);
  const [userPhoto, setUserPhoto] = useState(null);
  const [userPhone, setUserPhone] = useState();
  const phone = localStorage.getItem('phone');

  useEffect(() => {
    const script = document.createElement("script");
    // script.src = `https://www.googletagmanager.com/gtag/js?id=AW-11428737165`;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-7FD1QJW0GE`;

    script.async = true;
    document.body.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-7FD1QJW0GE");

    ReactGA.initialize('G-7FD1QJW0GE');
    ReactGA.pageview(window.location.pathname + window.location.search);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <UserContext.Provider
          value={{ subscribe, setSubscribe, userPhoto, setUserPhoto }}
        >
          <UserPhoneContext.Provider value={{ userPhone, setUserPhone }}>
            <Routes>
              {localStorage.getItem('phone') && AllRoutes.map((route, index) => (
                <Route
                  key={index}
                  exact
                  path={route.path}
                  element={
                      <route.component />
                    }
                />
              ))}
              <Route exact path="/user/phone" element={<GetPhoneScreen />} />
              {/* <Route exact path="/user/phone/test" element={<GetPhoneScreenTest />} /> */}
              <Route exact path="/" element={<Index />} />
              {/* <Route exact path="/" element={<Maintenance />} /> */}
              <Route exact path="/startfree" element={<StartFreeTrialScreen />} />
              <Route exact path="/error" element={<ErrorPage />} />
            </Routes>
            <ErrorPage />
            
            <ToastContainer transition={Slide} autoClose={1000} />
          </UserPhoneContext.Provider>
        </UserContext.Provider>
        <FooterScreen />
      </Router>
    </Provider>
  );
};

export default App;
