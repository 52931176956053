import React from 'react'
import './style.css'






const NotificationComponent = (props) => {
  return (



    <div className='notification-top-container' style={{ backgroundColor:props.secondaryColor }}>
    <div className='notification-top-inner-container' style={{ backgroundColor:props.primaryColor }}>
       <div className='notification-image-container'>
        <img  src={props.img} width="100%" alt='noti-avatar'/> 
        </div>
        <div className='notification-text-container'>
        <h6  style={{ color:props.textColor,fontSize:'14px' }}>{props.text}</h6>
        <p style={{ color:'#E7E4E9',fontSize:'12px' }}>{props.time}</p>
        </div>
    </div>
    </div>


  )
  
}

export default NotificationComponent