import { React, useState, useContext, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { connect } from "react-redux";
import "./GetPhoneScreenstyle.css";
import { sendPhone } from "../../redux/Actions/userAction";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router";
import { fetchUser, getToken, getAuthorizationCode } from "../../redux/Actions/userAction";
import { UserPhoneContext, UserContext } from "../../useContext/useContext";
import swal from "sweetalert";
import '../../App.css';
import axios from "axios";
import { Lock , Unlock } from "react-bootstrap-icons";
import ReactInputVerificationCode from "react-input-verification-code";
import { BASE_URL } from "../../Common/constant";
import Loading from "../Loading/Loading";
import jwtDecode from 'jwt-decode';
import forge from 'node-forge';

const GetPhoneScreen = (props) => {

  const navigate = useNavigate();
  const { state } = useLocation();
  const [phoneNumber, setPhoneNumber] = useState();
  const [redirectPage, setredirectPage] = useState(false);
  const { setUserPhone } = useContext(UserPhoneContext);
  const { setSubscribe } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [showHide, setShowHide ] = useState('none');
  const [lock, setLock ] = useState('block');
  const [isActive, setIsActive ] = useState(false);
  const [OTPValidate, setOTPValidate ] = useState(true);
  const [unlock, setUnlock ] = useState('none');
  const [OTP_Value, setOTP_Value ] = useState('');
  const [disable, setDisable ] = useState(true);
  const [sendAgainDisable, setSendAgainDisable ] = useState(true);
  const [animate , setAnimate ] = useState('bounceInUp');
  const { userPhone } = useContext(UserPhoneContext);

  const [requestOtp, setRequestOtp] = useState(false);
  const [seconds, setSeconds] = useState(180);

  const { phone, userData, fetchUser, getToken ,token , getAuthorizationCode } = props;

  const handleSubmit = () => {
    let num = phoneNumber;
    if(num !== undefined){
      if (num.substring(0, 4) !== "9597") {
        swal(
          "ယခု ဝန်ဆောင်မှုကို ATOM Myanmar Simcard သုံးစွဲသူများသာ ရယူနိုင်ပါသည်။",
          "The Service is Only available to ATOM Myanmar Subscribers",
          "error"
        );
      } else if (num.length > 12 || num.length < 12) {
        swal(
          "Phone Number မှားနေပါသည်။",
          "၀န်ဆောင်မှုကို ရယူရန် ATOM Myanmar ဖုန်းနံပတ်ကို အသုံးပြုပါရန်။",
          "info"
        );
      } else {
        // setRequestOtp(true);
        // setredirectPage(true);
        setUserPhone(phoneNumber);
        localStorage.setItem("phone", phoneNumber);
        localStorage.setItem("view_count", 1);
        // setShowHide('block');

        var data = new FormData();
        data.append('phone',localStorage.getItem('phone'));
        data.append('option', localStorage.getItem('option'));
        // return console.log(localStorage.getItem('phone'));
        axios.post(`${BASE_URL}/test_cg`,data).then(response => {
          // console.log(response.data);
          if(response.data.subscription == "subscribe") {
            return window.location.href = response.data.data.status;
          } else {
            if((response.data.message == "Subscription already exists")) {
              
            var phoneNumber = localStorage.getItem("phone");
            var data = new FormData();
            data.append('phone', phoneNumber);

            axios.post(`${BASE_URL}/req-token`,data).then(response => {
                // console.log(response);
                if(response.data.token){
                    getAuthorizationCode(localStorage.getItem('phone'),"fromUser");
                    localStorage.setItem('token', response.data.token);
                    var data = new FormData();
                    data.append("phone", localStorage.getItem("phone"));
                    data.append("status", "subscribe");
                    data.append("sender", "fromUser");
                    props.sendPhone(data, () => {
                        fetchUser(localStorage.getItem("phone"), "fromUser");
                    });  
                    setIsLoading(false);
                    navigate('/home');
                  }
            })

          }
        }
          if(response.data.status == 'Limit'){
            // setShowHide('none');
            // setRequestOtp(false);
            // setredirectPage(false);
            // swal(
            //   "",
            //   "OTP တောင်းဆိုမှု ၅ ကြိမ်ပြည့်သွားပါပြီ",
            //   "info"
            // );
          }else{
            getAuthorizationCode(localStorage.getItem('phone'),"fromUser");
          }
        });

        // axios.post(`${BASE_URL}/otp_token_req`,data).then(response => {
        //   if(response.data.status == 'Limit'){
        //     setShowHide('none');
        //     setRequestOtp(false);
        //     setredirectPage(false);
        //     swal(
        //       "",
        //       "OTP တောင်းဆိုမှု ၅ ကြိမ်ပြည့်သွားပါပြီ",
        //       "info"
        //     );
        //   }else{
        //     getAuthorizationCode(localStorage.getItem('phone'),"fromUser");
        //   }
        // });


        return;
      }
    }
  };

  useEffect(() => {
    if (requestOtp && seconds > 0) {
      const timer = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1); // Use functional update to ensure latest state
      }, 1000);
  
      return () => clearInterval(timer);
    } else if (seconds === 0) {
      setRequestOtp(false);
      setSendAgainDisable(false);
    }
  }, [requestOtp, seconds]);

  const handleSendAgain = async () => {
    setSeconds(180);
    setRequestOtp(true);
    setSendAgainDisable(true);

    let num =  localStorage.getItem("phone");
    var data = new FormData();
    data.append('phone',num);

    axios.post(`${BASE_URL}/otp_token_req`,data).then(response => {
      if(response.data.status == 'Limit'){
        setShowHide('none');
        setRequestOtp(false);
        setredirectPage(false);
        swal(
          "",
          "OTP တောင်းဆိုမှု ၅ ကြိမ်ပြည့်သွားပါပြီ",
          "info"
        );
      }else{
        getAuthorizationCode(localStorage.getItem('phone'),"fromUser");
      }
    });
  }

  const handleOTPComplete = async () => {
    var data = new FormData();
    data.append('otp_value',OTP_Value);
    data.append('phone', phoneNumber);
    //phone number

    try {
      const response = await axios.post(`${BASE_URL}/verify-otp`,data).then(response => {
        setIsActive(true);
        setRequestOtp(false);
        setLock('none');
        setUnlock('block');
        setTimeout(() => {
          setIsLoading(true);
          if(response.data.token){
            localStorage.setItem('token', response.data.token);
            var data = new FormData();
            data.append("phone", localStorage.getItem("phone"));
            data.append("status", "subscribe");
            data.append("sender", "fromUser");
            props.sendPhone(data, () => {
                fetchUser(localStorage.getItem("phone"), "fromUser");
            });  
          }
        },2000);        
      });
    } catch (error) {
      if (error.response && error.response.status === 422) {
        setOTPValidate(false);
      } 
    }


    // try {
    //   const response = await axios.post(`${BASE_URL}/verify-otp`,data).then(response => {
    //     setIsActive(true);
    //     setLock('none');
    //     setUnlock('block');
    //     setTimeout(() => {
    //       setIsLoading(true);
    //       axios.get(`${BASE_URL}/atom_user/${localStorage.getItem('phone')}/fromUser`).then( res => {
    //         if(res.data.user === 0){
    //           var data = new FormData();
    //           data.append("phone", localStorage.getItem("phone"));
    //           data.append("status", "subscribe");
    //           data.append("sender", "fromUser");
    //           props.sendPhone(data, () => {
    //             getToken(localStorage.getItem("phone"), () => {
    //               fetchUser(localStorage.getItem("phone"), "fromUser");
    //             });
    //           });  
    //         }
    //         else{
    //           getToken(localStorage.getItem("phone"), () => {
    //             fetchUser(localStorage.getItem("phone"), "fromUser");
    //           });
    //       }});
    //     },2000);        
    //   });
    // } catch (error) {
    //   if (error.response && error.response.status === 422) {
    //     setOTPValidate(false);
    //   } 
    // }
  }

  const handleOnchange = (val) => {
    setOTP_Value(val);
    setOTPValidate(true);
  }

  useEffect(() => {
    if(OTP_Value.length === 6){
      setDisable(false);
    }
    else{
      setDisable(true);
    }
  },[OTP_Value]);

  // Redirect
  useEffect(() => {
    if (redirectPage === true) {
      if (
        userData &&
        userData.status === "success" &&
        userData.user === 0 &&
        userData.from === "fromUser"
      ) {
        setTimeout(() => {
          
        }, 1000);
      } else if (userData && userData.status === "renew") {
        setTimeout(() => {
          navigate("/renewscreen");
        }, 1000);
      } else if (userData && userData.status === "success") {
        setTimeout(() => {
          navigate("/home");
        }, 1000);
      }
    }
  }, [userData, navigate, phoneNumber]);
  // Redirect


  return (
    <div>
      {isLoading ? (
        <div
          style={{
            marginTop: "80px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading color="#00A8FF" />
        </div>
      ) : (
      <>
        <div className="adjust-input">
          <div className="text-icon-div">
            <img
              src={require("../../assets/StartFreeTrial/verify.png")}
              alt="..."
            />
            <div className="text-div">
              <h3>Verify Your Number</h3>
              <h6>Please Enter your phone number</h6>
            </div>

            <div className="phone-div">
              <PhoneInput
                country={"mm"}
                onlyCountrie={["mm"]}
                preferredCountries={["mm"]}
                disableDropdown={true}
                countryCodeEditable={false}
                placeholder="Enter phone number"
                containerClass="containerClass"
                inputClass="inputClass"
                dropdownClass="dropdownClass"
                onChange={setPhoneNumber}
              />
              <Button
                variant="light"
                className="shadow text-white"
                style={{ cursor: "pointer" }}
                type="submit"
                onClick={handleSubmit}
              >
                {" "}
                Submit{" "}
              </Button>
            </div>
          </div>
        </div>
        {/* OTP Modal */}
        <div className={`OTP-modal`} style={{ 
            display : showHide,
            animation : animate,
            animationDuration : '2s',
            }}>
            <div className={`OPT-sub-modal ${OTPValidate === false && 'OTP_Validate'}`}>
              <center>
                <Lock className={`lockStyle ${isActive === true &&'animate__animated animate__backOutLeft'}`} style={{ display : lock}}/>
                <Unlock className={`lockStyle ${isActive === true &&'animate__animated animate__backInRight'}`} style={{ display : unlock}}/>
                <h2>Verification Code</h2>
                <p>သင့်ဖုန်းထံသို့ တခါသုံး OTP ကုဒ်အား ပေးပို့ထားပါသည်။ ကုဒ်ကို သုံး၍ ဆက်လက်လုပ်ဆောင်ပါ။</p>
                <p className="mt-3">{seconds} seconds</p>
              </center>
              <ReactInputVerificationCode 
                length={6}
                onChange={handleOnchange}
                value=''
                placeholder=''
                />
                <center>
                  <a className={`send_again_btn ${sendAgainDisable ? 'text-secondary' : 'text-primary'}`} onClick={!sendAgainDisable ? handleSendAgain : undefined}>Send Again</a>
                  <Button className="submitBtn shadow-lg" disabled={disable} onClick={handleOTPComplete}>Submit</Button>
                </center>
            </div>
        </div>
        {/* OTP Modal */}
      </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.phone.userData,
  };
};

export default connect(mapStateToProps, {
  fetchUser,
  getToken,
  sendPhone,
  getAuthorizationCode
})(GetPhoneScreen);
