import React,{useEffect,useState} from "react";
import Modal from "react-bootstrap/Modal";
import "./UserListModalStyle.css";
import { Row, Col } from "react-bootstrap";
import ScrollLoading from "../../components/Loading/ScrollLoading";
import UserImage from "../../assets/Avatar/person.png";
import { IMAGE_BASE_URL } from "../../Common/constant";
import Loading from "../../components/Loading/Loading";
import BlueCrown from "../../assets/about-lifex/blue_crown.png";
import YellowCrown from "../../assets/about-lifex/yellow_crown.png";
import GreenCrown from "../../assets/about-lifex/green_crown.png";

//Redux Action
import { connect } from "react-redux";
import { leaderboardlistfun, get_user_position } from "../../redux/Actions/postAction";
import { getAuthorizationCode } from "../../redux/Actions/userAction";

const UserListModal = (props) => {
  const [list, setList] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);

  let ph = localStorage.getItem("phone");
  const phone = ph.substring(2);
  const auth_code = localStorage.getItem('authorization_code');
  
  const { leaderboardlist , leaderboardlistfun, user_position, get_user_position} = props;
  const data = leaderboardlist.data;
  const user = user_position.data;

  function getPost() {
    if(auth_code !== null){
      leaderboardlistfun(count,auth_code);
      setIsFetching(false);
    }
  }

  function getMorePosts() {
    setTimeout(() => {
      setCount(count + 5);
      getPost();
    }, 4000);
  }

  function handleScroll() {
      setIsFetching(true);
  }

  useEffect(() => {
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  
  useEffect(() => {
    if(auth_code !== null){
      setCount(5);
      getPost();
    }
  }, []);

  useEffect(() => {
    if (!isFetching) {
      return;
    }else{
      if(count <= 15){
        getMorePosts();
      }else{
        setIsFetching(false);
      }
    }
  }, [isFetching]);

  useEffect(() => {
    if(auth_code !== null){
      get_user_position(phone,auth_code);
    }
    if(data !== undefined){
      if (data.length > 0 && data !== undefined) {
       setList([...list, ...data]);
       setIsLoading(false);
     } else {
       setIsLoading(false);
     }}
   }, [data]);

  return (
    <div>
      <Modal
        show={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-bcenter"
        className="user-list-modal"
      >
        <Modal.Header className="modal-header-1">
          <button onClick={props.onHide} className="modal-close-btn"></button>
          <h4>Leaderboard</h4>
        </Modal.Header>
        <Modal.Body className="modal-body-1" onScroll={handleScroll}>
        <div  style={
                  isFetching
                  ? { marginBottom : "30px" }
                  : null
              }>
          {isLoading ? (
            <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loading color="#E6D153" />
              </div>
            ) : (
              list.map((userlist, index) => (
                <Row
                  className="my-2 py-1"
                  key={index}
                  style={
                    userlist.id == props.userId
                      ? { backgroundColor: "rgba(0, 168, 255,0.5)" }
                      : null
                  }
                >
                  <Col className="col-9 user-left-container">
                    <p>{index + 1}</p>
                    <img 
                          // src={`${IMAGE_BASE_URL}${userlist.photo}`}
                          src={UserImage}
                          alt="user" 
                          style={{margin: '0px 8px'}}
                          onError={(e) => {
                            if (e.target.src !== "image_path_here") {
                              e.target.onerror = null;
                              e.target.src = UserImage;
                            }
                          }}/>
                    <h6>{userlist.name != null ? userlist.name : 'Unknown'}</h6>
                    {
                      index == 0 ? (
                        <img 
                          src={YellowCrown}
                          className="crown"
                        />
                      ) : ''
                    }
                    {
                      index == 1 ? (
                        <img 
                          src={GreenCrown}
                          className="crown"
                        />
                      ) : ''
                    }
                    {
                      index == 2 ? (
                        <img 
                          src={BlueCrown}
                          className="crown"
                        />
                      ) : ''
                    }

                  </Col>
                  <Col className="col-3 user-right-container">
                    <h6>{userlist.point} </h6>
                    <p>Points</p>
                  </Col>
                </Row>
              ))
            )}
            {isFetching &&  <ScrollLoading style={{color : 'black'}} />}
          </div>
            <hr></hr>
            <Row
              className="my-2"
              style={
                  { backgroundColor: "rgba(0, 168, 255,0.5)" }                      
              }
            >
              <Col className="col-9 user-left-container">
                <p>{user_position && user_position.position}</p>
                <img 
                      // src={user && `${IMAGE_BASE_URL}${user.photo}`}
                      src={UserImage}
                      alt="user" 
                      onError={(e) => {
                        if (e.target.src !== "image_path_here") {
                          e.target.onerror = null;
                          e.target.src = UserImage;
                        }
                      }}/>
                <h6>{user && user.name !== null ? user.name : 'You' }</h6>
              </Col>
              <Col className="col-3 user-right-container">
                <h6>{user && user.point}</h6>
                <p>Points</p>
              </Col>
            </Row>

        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    leaderboardlist: state.post.leaderboard_list,
    user_position: state.post.user_position,
  };
};

export default connect(mapStateToProps, {
  leaderboardlistfun,
  get_user_position,
  getAuthorizationCode
})(UserListModal);
