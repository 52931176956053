import React from "react";
import Spinner from "react-bootstrap/Spinner";
import "./LoadingStyle.css";

const ScrollLoading = (props) => {
  return (
    <div className="loading-container">
      <Spinner
        animation="border"
        role="status"
        style={{ color: `${props.color}` }}
      ></Spinner>
    </div>
  );
};

export default ScrollLoading;
