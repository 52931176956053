import React, { useEffect } from 'react'
import Modal from "react-bootstrap/Modal";
import './TermsModal.css';
import { X } from "react-bootstrap-icons";
import { connect } from "react-redux";
import { getTerms } from '../../redux/Actions/postAction';
import { Markup } from 'interweave';

const TermsModal = (props) => {
  useEffect(() => {
    getTerms();
  }, []);
  const { getTerms, terms } = props;

  var description = terms.length != 0 ? terms[0].description : null;

  return (
    <>
      <Modal
        show={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="post-modal terms-modal" style={{backgroundColor: '#fff', fontFamily: 'Montserrat, sans-serif'}}>
          <X size={30} style={{color: '#000'}} className='close m-2' onClick={props.onHide}/>
            <Modal.Header className="post-modal-header mb-0">
                <Modal.Title className="post-modal-title border-0">
                    <p>Terms & Conditions</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
              {/* <div className='term_body'> */}
                <div className="term_des">
                  <Markup content={description} className="des mb-2"/>
                </div>
              {/* </div> */}
            </Modal.Body>
        </div>
      </Modal>       
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    terms : state.post.get_terms
  };
};

export default connect(mapStateToProps, {
  getTerms
})(TermsModal);
