import React from "react";
import { Container } from "react-bootstrap";
import { House, Search, Bookmark } from "react-bootstrap-icons";
import "./style.css";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  return (
    <>
      <div className="main-container">
        <Container className="footer-container" fluid>
          <a
            onClick={() => {
              navigate("/home");
            }}
          >
            <div className="footer-home active">
              <House
                size={20}
                style={
                  path === "/home" ? { color: "#00A8FF" } : { color: "#B1AC9F" }
                }
              />

              <p
                className="active footer-name"
                style={
                  path === "/home" ? { color: "#00A8FF" } : { color: "#B1AC9F" }
                }
              >
                Home
              </p>
            </div>
          </a>
          <Link to={"/explore"}>
            <div className="footer-explore">
              <Search
                size={20}
                style={
                  path === "/explore"
                    ? { color: "#00A8FF" }
                    : { color: "#B1AC9F" }
                }
              />

              <p
                className="footer-name"
                style={
                  path === "/explore"
                    ? { color: "#00A8FF" }
                    : { color: "#B1AC9F" }
                }
              >
                Explore
              </p>
            </div>
          </Link>
          <Link to={"/saved_booklist"}>
          <div className="footer-saved">
              <Bookmark
                size={20}
                style={
                  path === "/saved_booklist"
                    ? { color: "#00A8FF" }
                    : { color: "#B1AC9F" }
                }
              />

              <p
                className="footer-name"
                style={
                  path === "/saved_booklist"
                    ? { color: "#00A8FF" }
                    : { color: "#B1AC9F" }
                }
              >
                Saved
              </p>
            </div>
          </Link>
        </Container>
      </div>
    </>
  );
};

export default Footer;
