import React from "react";
import { Row, Col } from "react-bootstrap";
import {
  ArrowLeft,
  ChevronRight,
  QuestionCircleFill,
  TelephoneInboundFill,
  TicketDetailedFill
} from "react-bootstrap-icons";
import { useNavigate } from "react-router";

import "./helpStyle.css";

const Help = () => {
  const navigate = useNavigate();
  return (
    <div className="help_container">
      <div className="help_main_container">
        <Row className="mt-3">
          <Col className="col-5">
            <a onClick={() => {navigate("/profile")}}>
              <ArrowLeft
                style={{ marginLeft: "10px", fontSize: "22px", color: "#757575" }}
              />
            </a>
          </Col>
          <Col className="col-7">
            <h4>Help</h4>
          </Col>
        </Row>
        {/* FAQ */}
        <div className="help-item-container">
          <div className="help-container">
            <QuestionCircleFill className="help-notification-icon" />
            <h5 style={{ fontSize: "16px", margin: "0px", paddingLeft: "7px" }}>
              FAQ
            </h5>
          </div>
          <a onClick={() => {navigate("/profile/help/faq")}}>
            <ChevronRight className="help-arrow-right-icon" />
          </a>
        </div>
        <hr className="help-divider" />

        {/* customer support */}

        <div className="help-item-container">
          <div className="help-container">
            <TelephoneInboundFill className="help-notification-icon" />
            <h5 style={{ fontSize: "16px", margin: "0px", paddingLeft: "7px" }}>
              Customer Support
            </h5>
          </div>
          <a onClick={() => {navigate("/profile/help/c_support")}}>
            <ChevronRight className="help-arrow-right-icon" />
          </a>
        </div>
        <hr className="help-divider" />
      </div>
    </div>

  );
};

export default Help;
