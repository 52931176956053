import React,{useEffect} from 'react'
import { Col, Row } from "react-bootstrap";
import "../Course/CourseStyle.css";
import Course from '../Course/Course.js';
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import { fetchCourses } from "../../redux/Actions/postAction";

const CourseScreen = (props) => {
  const navigate = useNavigate();
  useEffect(()=>{
    props.fetchCourses();
  },[]);
  
  const { CourseData, fetchCourses} = props;

  const data = CourseData !== undefined && CourseData && CourseData.data;
  return (
    <>
      <div className='course_container'>
        <div className='course_main_container'>
          {/* <Row className="my-3">
            <Col className="col-2">
              <a onClick={() => {navigate("/profile")}}>
                <ArrowLeft
                  style={{ marginLeft: "10px", fontSize: "22px", color: "#757575" }}
                />
              </a>
            </Col>
            <Col className="col-9 text-center">
              <h4>lifeX Course Deal Program</h4>
            </Col>
          </Row> */}
          <Course 
            courses={data}
          />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    CourseData : state.post.courses,
  };
};
export default connect(mapStateToProps, {
  fetchCourses
})(CourseScreen);