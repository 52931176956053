import React from "react";
import { Col, Row } from "react-bootstrap";
import { ArrowLeft, FileEarmarkTextFill } from "react-bootstrap-icons";
import { useNavigate } from "react-router";

function ThankyouFeedback() {
  const navigate = useNavigate();
  
  return (
    <div className="setting-main-container" style={{ overflow: "hidden" }}>
      <div className="setting-container">
        <Row style={{ marginTop: "30px" }}>
          <Col className="col-1">
            <a onClick={()=>{navigate("/profile/settings")}}>
              <ArrowLeft
                style={{ marginLeft: "10px", fontSize: "24px", color: "#757575" }}
              />
            </a>
          </Col>
        </Row>
        <div
          className="text-center"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "80vh",
            fontFamily: "Montserrat",
            overflow: 'hidden'
          }}
        >
          <h5
            style={{
              backgroundColor: "#00a8ff",
              borderRadius: "10px",
              padding: "20px 20px",
              color: "#fff",
              fontSize: "16px",
            }}
          >
            Thank you for your feedback!{" "}
          </h5>
          <p style={{ fontSize: "14px", fontFamily: "Montserrat" }}>
            We will get back to you as soon as we can
          </p>
        </div>
      </div>
    </div>
  );
}

export default ThankyouFeedback;
