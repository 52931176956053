import React from "react";
import { Button, Spinner } from "react-bootstrap";
import "./LoadingStyle.css";

const Loading = (props) => {
  return (
    <div className="loading-container">
      <Button variant="dark" disabled>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          style={{ color: `${props.color}` }}
        />
        <span className="loading-lifex">LifeX</span>
      </Button>
    </div>
  );
};

export default Loading;
