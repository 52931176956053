import React, { useEffect, useState, useRef } from "react";
import EarnBook from "../../components/EarnBook/EarnBook";
import Tab from "../../components/nav/Tab";
import { IMAGE_BASE_URL } from "../../Common/constant";
import Loading from "../../components/Loading/Loading";
import ScrollLoading from "../../components/Loading/ScrollLoading";
//Redux Action
import { connect } from "react-redux";
import { fetchPosts, getSubCategories } from "../../redux/Actions/postAction";
import { useDispatch } from "react-redux";
import { RESET_EARN_POSTS } from "../../redux/Actions/postAction";

//filter
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import '../../components/EarnBook/earnstyle.css';

const Earn = (props) => {
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch({
      type: RESET_EARN_POSTS,
      payload: [],
    });
  })
  const ref = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);

  // limited posts
  const [posts, setPosts] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [page, setPage] = useState(0);
  const [subCategoryCode, setSubCategoryCode] = useState(null);

  const { BookData, fetchPosts, EarnSub, getSubCategories } = props;

  const size = BookData.size;
  const data  = BookData.records;
  let ph = localStorage.getItem('phone');
  const phone = ph.substring(2);
  
  const auth_code = localStorage.getItem('authorization_code');

  function getPost(subCategory) {
    if(auth_code !== null){
      getSubCategories("C-0003", 'earn');
      fetchPosts("C-0003",subCategory,page,'earn',auth_code);
      setIsFetching(false);
    }
  }

  function getMorePosts() {
    setTimeout(() => {
      setPage(page + 5);
      getPost(subCategoryCode);
    }, 4000);
  }

  function handleScroll() {
    if (window.innerHeight !== document.documentElement.offsetHeight) {
      setIsFetching(true);
    }else{
      setIsFetching(false);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []); 


  useEffect(() => {
    if(auth_code !== null){
      if(subCategoryCode === null){
        getPost('all');
        setSubCategoryCode('all');
        setPage(5);
      }else{
        getPost(subCategoryCode);
        setPage(5);
      }
    }
  }, [subCategoryCode]);

  useEffect(() => {
    if (!isFetching) {
      return;
    } else {
      if(page <= size){
       getMorePosts();
      }else{
       setIsFetching(false);
      }
    }
  }, [isFetching]);

  useEffect(() => {
   if(data !== undefined){
     if (data.length > 0 && data !== undefined) {
      setPosts([...posts, ...data]);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }}
  }, [data]);

  useEffect(() => {
    if(EarnSub !== undefined){
      if (EarnSub.length > 0 && EarnSub !== undefined) {
       setIsActive(false);
     } else {
       setIsActive(true);
     }}
   }, [EarnSub]);

   let primaryColor = ["#7bb4db", "#5cafe1", "#4973a5"];
   let secondaryColor = ["#5cafe1", "#4973a5", "#7bb4db"];

  const settings = {
    focusOnSelect: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    swipeToSlide: true,
    arrows : false,
  };

  const handleClick = (value,e) =>{ //when click sub category
    setPosts([]);
    setPage(0);
    setIsLoading(true);
    setSubCategoryCode(value);
    Array.from(document.querySelectorAll('.btn-slick')).forEach(function(el) { 
      el.classList.remove('active');
    });
    e.currentTarget.classList.toggle('active');
  }
  
  return (
    <>
      <Tab active="2px solid #00b4d8" background="#00b4d8" />
      <div className="earn_container">
        <div className="earn_main_container">
          <div className="filter-container">
            <p className="text-center slick_title">တိုးတက် ကောင်းမွန်တဲ့ ပရောက်ဖက်ရှင်ဘဝကို တည်ဆောက်နိုင်ဖို့ရာ စီမံခန့်ခွဲမှုဆိုင်ရာ ဥပဒေသများ၊ ခေါင်းဆောင်မှု အတတ်ပညာနှင့် စွန့်ဦးတီထွင်မှု ဆိုင်ရာ နည်းလမ်းကောင်းများကို Earn ကဏ္ဍတွင် ရှာဖွေနိုင်ပါသည်။</p>
            <Slider {...settings}>
              <div className="slick-item">
                <a className="btn btn-slick earn-btn px-4 mx-1 active" ref={ref} onClick={(e) => handleClick('all',e)}>All</a>
              </div>
              {
                EarnSub && EarnSub.map(function(sub, index){
                  return (
                    <div className="slick-item" key={index}>
                      <a className="btn btn-slick earn-btn px-4 mx-1" onClick={(e) => handleClick(sub.sub_category_code,e)}>{sub.sub_category_name}</a>
                    </div>
                  );

                })
              }
            </Slider>
          </div>
          {isLoading ? (
            <div
              style={{
                marginTop: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loading color="#DF719D" />
            </div>
          ) : (
            posts.map((book, index) => (
              <EarnBook
                key={index}
                bookID={book.id}
                count={book.count}
                date={book.published_date}
                rate={book.rates.length > 0 ? book.rates[0].rate : 1}
                title={book.post_name}
                img={IMAGE_BASE_URL + book.images}
                subTitle={book.subcategory.sub_category_name}
                primaryColor={primaryColor[index % 3]}
                secondaryColor={secondaryColor[index % 3]}
                postType={book.post_type}
                postSave={
                  book.post_save &&
                  book.post_save.phone == phone
                    ? "save"
                    : ""
                }
              />
            ))
          )}
          {isFetching && <ScrollLoading color="#00A8FF" />}
        </div>
      </div>

    </>
  );
};

const mapStateToProps = (state) => {
  return {
    BookData: state.post.earn_posts,
    EarnSub : state.post.earn_sub
  };
};

export default connect(mapStateToProps, {
  fetchPosts,
  getSubCategories
})(Earn);
