import React, { useState } from "react";
import './RatingStyle.css';
import lifeXIcon from './lifeXicon.jpg'
import { StarFill } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import axios from "axios";
import { BASE_URL } from "../../Common/constant";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

const RatingScreen = (props) => {
  const navigate = useNavigate();
  const [styles1 , setStyles1] = useState('star-color');
  const [styles2 , setStyles2] = useState('star-color');
  const [styles3 , setStyles3] = useState('star-color');
  const [styles4 , setStyles4] = useState('star-color');
  const [styles5 , setStyles5] = useState('star-color');
  const [starText , setStarText] = useState('Outstanding');
  const [rateMark , setRateMark] = useState(3);

  const handleStar = (mark) => {
    setRateMark(mark);
    if(mark === 1){
      setStyles1('star-color');
      setStyles2('none');
      setStyles3('none');
      setStyles4('none');
      setStyles5('none');
      setStarText('Below Average');
    }
    if(mark === 2){
      setStyles1('star-color');
      setStyles2('star-color');
      setStyles3('none');
      setStyles4('none');
      setStyles5('none');
      setStarText('Average');
    }
    if(mark === 3){
      setStyles1('star-color');
      setStyles2('star-color');
      setStyles3('star-color');
      setStyles4('none');
      setStyles5('none');
      setStarText('Good');
    }
    if(mark === 4){
      setStyles1('star-color');
      setStyles2('star-color');
      setStyles3('star-color');
      setStyles4('star-color');
      setStyles5('none');
      setStarText('Excellent');
    }
    if(mark === 5){
      setStyles1('star-color');
      setStyles2('star-color');
      setStyles3('star-color');
      setStyles4('star-color');
      setStyles5('star-color');
      setStarText('Outstanding');

    }
  }

  const saveRating = (e) => {
    var post_id = localStorage.getItem('post_id');
    var phone = localStorage.getItem('phone');
    var data = new FormData();
    data.append('rating',rateMark);
    data.append('post_id',post_id);
    data.append('phone',phone);
    axios.post(`${BASE_URL}/rating/store`,data).then(res => {
      if(res.data.message === 'success'){
        toast.success("Thank you for your submission", {
          position: toast.POSITION.TOP_CENTER,
          draggablePercent: 70,
          onClose: () => navigate('/home')
        });
      }
    });
    e.currentTarget.disabled = true;
  }

  const cancelRating = () => {
    navigate('/home');
  }
  return (
    <>
      <div className="rating-screen d-flex justify-content-center">
        <div className="lifex-icon">
            <center><img src={lifeXIcon} alt="lifex-icon" /></center>
        </div>
        <center>
          <div className="rating-card card">
            <div className="card-body">
              <h3>Enjoy the Content?</h3>
              <div className="star-container d-flex justify-content-center">
                <StarFill className={`star ${styles1}`} onClick={() => handleStar(1)} />
                <StarFill className={`star ${styles2}`} onClick={() => handleStar(2)}/>
                <StarFill className={`star ${styles3}`} onClick={() => handleStar(3)}/>
                <StarFill className={`star ${styles4}`} onClick={() => handleStar(4)}/>
                <StarFill className={`star ${styles5}`} onClick={() => handleStar(5)}/>
              </div>
              <div className="star-text">
                <center><h4>{starText}</h4></center>
              </div>
            </div>
            <div className="card-footer">
              <div className="button-container d-flex justify-content-around">
                <div className="cancel-button-container">
                <button className="btn" onClick={cancelRating}> Cancel </button>

                </div>
                <div className="submit-button-container">
                <button className="btn" onClick={(e) => saveRating(e)}> Submit </button>
                </div>
              </div>
            </div>
          </div>
        </center>
      </div>
    </>
  );
};

export default RatingScreen;
