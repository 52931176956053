import React, { useEffect } from "react";
import { useNavigate } from "react-router";



const ErrorPage = () => {
    const navigate = useNavigate();
    useEffect(() =>{
        if(localStorage.getItem('phone') === null){
            navigate('/');
        }
    },[]);
};


export default ErrorPage
    