import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import {
  ArrowLeft,
  Bell,
  ChatSquareText,
  ChevronRight,
  FileEarmarkTextFill,
  InfoCircleFill,
} from "react-bootstrap-icons";
import { useNavigate } from "react-router";
import ToggleButton from "../../../components/ToggleButton/ToggleBtn";

import "./settingStyle.css";

const Setting = () => {
  const navigate = useNavigate();
  const [isToggle, setIsToggle] = useState(true);

  const onToggle = () => {
    setIsToggle(() => !isToggle);
  };

  return (
    <div className="setting-main-container">
      <div className="setting-container">
        <Row className="mt-3">
          <Col className="col-4">
            <a onClick={()=>{navigate("/profile")}}>
              <ArrowLeft
                style={{ marginLeft: "10px", fontSize: "22px", color: "#757575" }}
              />
            </a>
          </Col>
          <Col className="col-8">
            <h4>Settings</h4>
          </Col>
        </Row>
        {/* notification */}
        {/* <div className="setting-item-container">
          <div className="item-container">
            <Bell className="notification-icon" />
            <h5 style={{ fontSize: "16px", margin: "0px", paddingLeft: "7px" }}>
              Notifications
            </h5>
          </div>
          <ToggleButton onToggle={onToggle} isToggle={isToggle} />
        </div>
        <hr className="items-divider" /> */}

        {/* Send us feed back */}
        <div className="setting-item-container">
          <div className="item-container">
            <ChatSquareText className="notification-icon" />
            <h5 style={{ fontSize: "16px", margin: "0px", paddingLeft: "7px" }}>
              Send Us Feed Back
            </h5>
          </div>
          <a onClick={()=>{navigate("/profile/settings/feedback")}}>
            <ChevronRight className="settings-arrow-right-icon" />
          </a>
        </div>
        <hr className="items-divider" />

        {/* About lifex */}
        <div className="setting-item-container">
          <div className="item-container">
            <InfoCircleFill className="notification-icon" />
            <h5 style={{ fontSize: "16px", margin: "0px", paddingLeft: "7px" }}>
              About lifeX
            </h5>
          </div>
          <a onClick={()=>{navigate("/profile/settings/aboutlifex")}}>
            <ChevronRight className="settings-arrow-right-icon" />
          </a>
        </div>
        <hr className="items-divider" />
      </div>
    </div>
  );
};

export default Setting;
