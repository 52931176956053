import React, { useState } from "react";
import { ArrowLeft } from "react-bootstrap-icons";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useNavigate } from "react-router";
import "./settingAboutLifexStyle.css";

const AboutLifeX = () => {
  const navigate = useNavigate();
  const [key, setKey] = useState("Myanmar");
  return (
    <div className="setting-main-container" style={{ overflow: "hidden" }}>
      <div className="about-main-container">
        <div className="d-flex  align-items-center m-0 p-0">
          <a
            onClick={()=>{navigate("/profile/settings")}}
            style={{ textDecoration: "none", color: "#000" }}
          >
            <ArrowLeft size={24} className="mx-3" />
          </a>
          <h6
            className="my-0 p-0"
            style={{ fontFamily: "Montserrat", marginLeft: "25%" }}
          >
            About LifeX
          </h6>
        </div>

        <div className="px-3 mt-5">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="about-tap-container"
            variant="pills"
          >
            <Tab eventKey="Myanmar" title="Myanmar" className="about-tap">
              <p className="setting-about-container">
                <span style={{ marginLeft: "40px" }}></span>lifeX သည်
                မြင်ဖူးနေကြ သမားရိုးကျ lifestyle portal မျိုး မဟုတ်ဘဲ အသက် ၂၀
                ကနေ ၃၀ ‌ကျော် အတွင်းရှိ လူငယ် ပရော်ဖက်ရှင်နယ်များအတွက်
                အသက်မွေးဝမ်းကြောင်း အောင်မြင်မှု၊ ကိုယ်စိတ်နှလုံး
                ကျန်းမာကြံ့ခိုင်မှု စတာတွေနဲ့ ပတ်သက်တဲ့ လူမှုဘ၀ကိစ္စများကို
                မီးမောင်းထိုးပြပြီး အကြံပေးနိုင်ဖို့ ရည်ရွယ်ပါတယ်။ <br></br>
                <span style={{ marginLeft: "40px" }}></span>လူငယ် ပရော်ဖက်ရှင်နယ်များအနေနဲ့ အကောင်းမြင်စိတ်၊ ပျော်ရွှင်မှု၊
                ခွန်အား အပြည့်ဖြင့် မိမိရဲ့ အကောင်းဆုံးပုံစံကို
                တည်ဆောက်နိုင်ဖို့ နည်းလမ်းအသစ်များကို lifeX တွင် ရှာဖွေနိုင်မှာ
                ဖြစ်ပါတယ်။ တိုတုတ်ထိမိသည့် လေ့လာစရာများကို portal မှာ နေ့စဥ်
                တင်ပေးသွားမှာဖြစ်လို့ Subscriber များအနေနဲ့ နေ့စဥ်နှင့်အမျှ
                တိုးတက်မှုကို လေ့လာရှာဖွေနိုင်မှာပါ။
              </p>
            </Tab>
            <Tab eventKey="English" title="English" className="about-tap">
              <p className="setting-about-container">
                <span style={{ marginLeft: "40px" }}></span>The lifeX is NOT
                your typical lifestyle portal. We dig deep into issues men and
                women in their 20′s to 30’s face like career success and
                personal wellness.
              </p>
              <p className="setting-about-container">
                <span style={{ marginLeft: "40px" }}></span>At lifeX, we search
                for new ways of bettering ourselves, as well as lead a life on
                the path of happiness full of positivity and energy.<br></br>
                Concise and tailor-made contents will be added daily to the
                portal so that our audience will be able to learn and grow each
                day they are with us.
              </p>
            </Tab>
          </Tabs>
        </div>
        <div className="copyright-text-container">
          <h6 className="copyright-text text-dark">
            Copyright &#169; 2022 Parabyte Media. All rights reserved.
          </h6>
        </div>
      </div>
    </div>
  );
};

export default AboutLifeX;
