import React, { useEffect, useState } from "react";
import Explore from "../../components/Explore/Explore";
import { Helmet } from "react-helmet";

//Redux Action
import { connect } from "react-redux";
import { searchPosts } from "../../redux/Actions/postAction";

const ExploreScreen = (props) => {
  const [searchTerm, setSearchTerm] = useState({name:null, post_type: null});
  useEffect(() => {
    if(searchTerm.name !== null || searchTerm.post_type != null){
      props.searchPosts(searchTerm);
    }
  }, [searchTerm]);

  const { BookData } = props;

  return (
    <div className="explore_container">
      <div className="explore_main_container">
        <Helmet bodyAttributes={{ style: "background-color:rgba(0,0,0,0.6)" }} />

        <Explore
          filterData={BookData.data}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    BookData: state.post.posts,
  };
};

export default connect(mapStateToProps, {
  searchPosts,
})(ExploreScreen);
