import React from 'react';
import Modal from "react-bootstrap/Modal";
import './CodeModalStyle.css';
import {  X } from "react-bootstrap-icons";
import { useNavigate } from 'react-router';

import swal from "sweetalert";
import { connect } from "react-redux";
import { cancelCode } from '../../redux/Actions/postAction';

const CodeModal = (props) => {
  const navigate = useNavigate();

  let data = localStorage.getItem("phone");
  const phone = data.substring(2);

  const { cancelCode, c_code } = props;

  const onClickCancelCode = (code) => {
    swal({
      title: `အစီအစဥ် ရယူခြင်းကို ပယ်ဖျက်ရန် သေချာပါပြီလား?`,
      icon: 'info',
      buttons: {
          confirm: {
              text: 'Confirm', 
              value: true, 
              visible: true, 
              className: "btn btn-primary confirm-btn fw-bold", 
              closeModal: true, 
          },
          cancel: {
              text: 'Cancel', 
              value: false, 
              visible: true, 
              className: "btn btn-secondary cancel-btn fw-bold mt-1", 
              closeModal: true, 
          }, 

      }
    }).then((result) => {
      if (result) {
        swal(
          {
            icon: 'success',
            title: 'Delete Successfully!',
            timer: 1800
          }
        ).then(() => {
          cancelCode(code,phone,() => {props.changeCode()});
          props.onHide();
        })

      } 
    });
  }

  return (
    <>
      <div>
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered

        >
          <div className="post-modal">
              <X size={30} className='close' onClick={props.onHide}/>
              <div className='close_design'></div>
              <Modal.Header className="post-modal-header">
                  <Modal.Title className="post-modal-title border-0">
                    <p>Promotion Code</p>
                  </Modal.Title>
              </Modal.Header>
              <Modal.Body className="border-0">
                <div className='post-modal-body d-flex justify-content-center border-0'>
                  <input type="text" className='form-control code p-3 mb-3' value={props.code}  readOnly/>
                </div>
                <div className='post-modal-body d-flex justify-content-center border-0'>
                  <a className='btn btn-secondary cancel-code  my-1' onClick={() => onClickCancelCode(props.code)}>Cancel</a>
                </div>

              </Modal.Body>
              <Modal.Footer className="post-modal-footer py-3">
                <p>You agree to</p><a className='term' onClick={() => navigate('/course/detail/T&C')}>Terms & Conditions</a>
              </Modal.Footer>
          </div>

        </Modal>
      </div>    
    </>

  )
}


const mapStateToProps = (state) => {
  return {
    c_code : state.post.cancel_code
  };
};

export default connect(mapStateToProps, {
  cancelCode
})(CodeModal);