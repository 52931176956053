import {
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
} from "redux";
import thunk from "redux-thunk";

//reducers
import postReducer from "../Reducers/postReducer";
import userReducer from "../Reducers/userReducer";

const rootReducer = combineReducers({
  post: postReducer,
  phone: userReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
